@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$gamepass-url: '../../assets/gamepass/';
$icons-url: '../../assets/icons/';
$referral-url: '../../assets/referral/icons/';

%input-box {
  @include font(14px);
  width: calc(100% - 75px);
  border-top-left-radius: $button-border-radius;
  border-bottom-left-radius: $button-border-radius;
  background-color: $light-purple-translucent;
}

.referral-screen {
  &-section {
    padding-top: 0;
    border: none;
  }

  &-banner {
    &-desktop {
      @include width-height(100%, auto);
      border-radius: $border-radius-mobile;
    }

    &-mobile {
      @include width-height(calc(100% + 40px), auto);
      @include margin-two-var(0, -20px);
      border-radius: 0;
    }

    &-container {
      @include margin(30px);
      @include padding-two-var(20px);
      border-top: $dark-blue-border;

      &-title {
        @include font-semi(18px);
        margin-bottom: 10px;
      }
    }
  }

  &-form {
    @include margin-two-var(20px, auto);
    width: 85%;
    max-width: 600px;

    @media (max-width: $max-mobile-width-sm) {
      width: 100%;
      max-width: none;
    }
  }

  &-progress {
    @include flex(center, center, column);

    &-button {
      @include margin(4px);
      @include purple-button();
      width: 100%;
    }

    &-wrapper {
      @include flex(center, flex-start, row);
      @include margin(14px, 0, 24px);
      gap: 40px;
      position: relative;

      @media (max-width: $max-mobile-width-xs) {
        gap: 20px;
      }
    }

    &-dotted {
      @include position-float-top-left(16px, 50%);
      transform: translateX(-50%);
      border-top: 2px dashed $light-purple;
      background-image: linear-gradient(to right, $light-purple 33%, transparent 0%);
      background-position: 0 0;
      background-size: 6px 2px;
      background-repeat: repeat-x;
      width: 70%;
    }

    &-box {
      @include flex(center, center, column);
      gap: 6px;
      z-index: 2;

      &-number {
        @include width-height-same(36px);
        @include flex(center, center, row);
        @include font-semi(14px);
        background-color: $dark-purple-7;
        border-radius: 36px;

        &-fill {
          background-color: $purple-6;
        }
      }

      &-title {
        @include font-semi();
        text-align: center;
        max-width: 140px;
      }
    }

    &-title {
      @include font-semi(16px);
    }
  }

  &-free-spin {
    @include padding(16px, 20px, 12px, 20px);
    border-top-left-radius: $border-radius-mobile;
    border-top-right-radius: $border-radius-mobile;
    background: $free-spin-gradient;

    &-icon {
      @include width-height-same(24px);
      @include icon-image('free-spin', true, $referral-url);
      @include background-position-size(center, contain);
    }

    &-count {
      @include font-semi(24px);
    }

    &-wrapper {
      @include flex(space-between, center, row);
    }

    &-title {
      @include font-semi();
      letter-spacing: 0.58px;
    }

    &-button {
      @include orange-button();
      @include padding-one-var(10px);
      width: 116px;

      &-disabled {
        @include grey-gradient-button();
        @include padding-one-var(10px);
        pointer-events: none;
        width: 116px;
      }
    }
  }

  &-gold-coins {
    @include padding-two-var(20px);
    @include flex(space-between, center, row);
    @include margin-two-var(20px);
    background-image: $refer-a-friend-background-gradient;
    width: 100%;
    border-radius: 8px;

    &-inner {
      @include flex(center, center, column);
      width: 50%;

      &:first-child {
        border-right: $white-border;
      }
    }

    &-label {
      @include flex(center, center, row);

      &-text {
        @include font-semi(12px);
      }

      &-info {
        @include width-height-same(14px);
        @include margin-one-var(5px);
        @include icon-image('info', true, $icons-url);
        @include background-position-size();
        position: relative;
      }
    }

    &-value {
      @include flex(center, center, row);

      &-text {
        @include font-semi(24px);
        text-align: right;
      }

      &-icon {
        @include width-height-same(22px);
        @include icon-image('coin', true, $gamepass-url);
        @include background-position-size();
        margin-right: 5px;
        position: relative;
      }

      &-info {
        @include width-height-same(14px);
        @include margin-one-var(5px);
        @include icon-image('info', true, $icons-url);
        @include background-position-size();
        position: relative;
        cursor: pointer;
      }
    }
  }

  &-referral-link {
    @include padding(18px, 20px, 20px, 20px);
    background-color: $purple-7;
    border-radius: $border-radius-mobile;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &-label {
      @include font-semi(14px);
      text-align: center;
      margin-bottom: 15px;
    }

    &-wrapper {
      @include flex(center, center, row);
      width: 100%;
    }

    &-copy {
      @include padding-two-var(10px, 20px);
      @include flex(space-between, center, row);
      @extend %input-box;

      &-url {
        @extend %text-ellipsis;
        width: calc(100% - 45px);
        position: relative;
      }

      &-button {
        @include width-height(21px, 25px);
        @include padding-one-var();
        background-color: transparent;
        border: none;
      }

      &-icon {
        @include width-height(21px, 25px);
        @include icon-image('copy', true, $icons-url);
        @include background-position-size(center, 20px);
      }
    }

    &-button {
      @include purple-button();
      @include padding-two-var(13.5px);
      width: 75px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: $purple-9;
    }

    &-or {
      @include flex(center, center, row);
      @include margin-two-var(15px);

      &-horizontal-line {
        width: calc(100% - 60px);

        hr {
          background-color: $light-purple;
          opacity: 1;
        }
      }

      &-text {
        @include font-semi(14px);
        color: $light-purple;
        width: 50px;
        text-align: center;
      }
    }

    &-mobile-number {
      @include flex(center, center, row);
      width: 100%;

      &-input {
        @include padding-two-var(12px, 20px);
        @include flex(flex-start, center, row);
        @extend %input-box;
        border: none;
        color: $white;

        &:focus {
          outline: none;
        }

        &::-webkit-input-placeholder {
          color: $light-purple;
        }

        &:-ms-input-placeholder {
          color: $light-purple;
        }

        &::placeholder {
          color: $light-purple;
        }

        .phone {
          &-input {
            width: 100%;

            &::placeholder {
              color: $light-purple;
            }
          }

          &-dropdown{
            &-toggle {
              @include font(14px);
              padding-right: 0;
            }
            
            &-menu {
              max-width: 540px;

              @media (max-width: $max-tablet-width) {
                max-width: calc(100vw - 90px);
              }
            }
          }
        }
      }

      &-error {
        outline: $pink-border;
        border-radius: $button-border-radius;
      }
    }

    &-error {
      @include font-medium(12px);
      margin-top: 15px;
      color: $pink-1;
      text-align: center;
    }
  }

  &-view-full-mechanics {
    text-align: center;

    &-link {
      @include link($white, $purple-6, underline);
      @include font(14px);
      display: block;
    }
  }

  &-history {
    @include margin(30px);
    border-top: $referral-history-border-top;

    @media (max-width: $max-mobile-width-sm) {
      @include margin(30px, -20px, 0, -20px);
      padding-left: 20px;
      padding-right: 20px;
    }

    &-empty {
      @include padding-two-var(50px);
      @include font-light(14px);
      text-align: center;
      border-radius: $border-radius-mobile;
      background-color: $light-purple-translucent;
    }

    &-list {
      &-item {
        @include padding-two-var(15px);
        @include flex(center, center, row);

        &:last-child {
          border-bottom: none;
        }

        &-details {
          width: calc(100% - 110px);
          
          &-name {
            @include font-semi(14px);
            color: $white;
            margin-bottom: 0;
          }

          &-date {
            @include font(14px);
            color: $light-purple;
            margin-bottom: 0;
          }
        }

        &-status {
          width: 110px;
          text-align: right;

          &-text {
            @include font-bold(14px);
            @include padding-two-var(10px);
            text-align: center;
            width: 100%;
            background-color: $light-purple-translucent;
            border-radius: $button-border-radius;
            text-transform: uppercase;
            color: $purple-6;

            &-invited {
              color: $orange-3;
            }

            &-success {
              color: $green-2;
            }

            &-failed {
              color: $red-2;
            }
          }
        }
      }
    }
  }
}