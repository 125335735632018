@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.tournaments {
  position: relative;
  overflow-x: hidden;

  &-section {
    padding-top: 0 !important;
    border-top: none !important;
  }

  &-grid {
    @include grid();

    &-item {
      &-info {
        text-align: left;
      }

      &-link {
        &:hover {
          .tournaments-grid-item {
            &-name {
              color: $purple-6;
            }
          }
        }
      }

      &-stats {
        @include padding-two-var(5px, 10px);
        @include font-semi(13px);
        @include position-float-top-right(0, 0);
        color: $white;
        background-color: $purple-translucent;
        border-top-right-radius: $border-radius-mobile;
        border-bottom-left-radius: $border-radius-mobile;

        @media (max-width: $max-mobile-width-sm) {
          @include padding-one-var(5px);
          @include font-semi(11px);
        }

        span {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }

        &-participants, &-duration {
          &-icon {
            @include margin(-3px, 5px);
            width: 15px;
          }
        }
      }
    }

    &-loading {
      @include font-medium(14px);
      text-align: center;
      animation: blink 2s linear infinite;
    }
  }
}

@keyframes blink {
  0%    { opacity: 0.25; }
  50%   { opacity: 0.75; }
  100%  { opacity: 0.25; }
}