@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.confirm {
  color: $white;
  background-color: $dark-purple-2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &-container {
    &.fade .modal-dialog {
      @include position-float-bottom-left(0px,0px);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-cancel-button {
    @include grey-button();
    @include margin(0, 10px);
    width: calc(50% - 10px);
  }

  &-accept-button {
    @include pink-button();
    @include margin(0, 0, 0, 10px);
    width: calc(50% - 10px);
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: left;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include padding-two-var(20px, 30px);
      @include font(14px);
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      border-top: none;
    }
  }
}