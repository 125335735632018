@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.featured {
  &-section {
    @include margin(-20px, -30px, 30px, -30px);
    position: relative;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin(-20px, 0, 30px, 0);
    }

    &-fluid {
      @media (max-width: $max-mobile-width-sm) {
        @include margin(-20px, -20px, 30px, -20px);
      }
    }
  }
}