@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.mechanics {
 
  &-text {
    @media (min-width: $min-tablet-width) {
      display: none;
    }
  }

  &-wrapper {
    @include padding-two-var(10px, 20px);
    padding-bottom: 30px;
    width: 100%;
    z-index: 4;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    max-width: 1024px;

    @media (max-width: $max-mobile-width-sm) {
      display: none;
    }
  }

  &-box {
    @include padding-two-var(20px, 20px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $dark-purple-3;
    color: $white;
    border: $purple-border;
    border-radius: 5px;
    width: 100%;
   

    &-title {
      @include font-bold(16px);
    }

    &-desc {
      @include font(13px);
      color: $grey-6;
    }

    &-button {
      @include purple-button();
      width: 100%;
      max-width: 400px;
    }

    &-wrapper {
      @include flex(center, center, row);
      width: 100%;
    }
  }
}