@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';

.general-dropdown {
  &-container {
    @include flex(center, center, row);
    outline: none;
  }

  &-caret {
    @include font-semi(26px);
  }

  &-menu {
    @include font-semi(14px);
    background-color: $dark-purple-7;
    color: $white !important;

    &-item {
      @include flex(flex-start, center, row);
      width: 100%;
      color: $white;

      &:hover {
        background-color: $cream;
      }
    }
  }

  &-title {
    &-container {
      @include flex(center, center, row);
      @include margin-one-var();
      @include padding-one-var();
      width: 100%;
    }

    &-icon {
      @include width-height-same(20px);

      &-container {
        @include width-height(25px, 20px);
        @include flex(flex-start, center, row);
      }
    }
  }

  &-toggle {
    @include flex(center, center, row);
    @include font-semi(18px);
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;

    &-container {
      @include flex(center, center, row);
    }
  }
}