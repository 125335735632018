@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.phone {
  &-country-code {
    @include margin(0, 10px);
    @include padding-one-var();
    display: inline-block;
    vertical-align: top;
    border-right: $mobile-number-prefix-border;

    &-name {
      @extend %text-ellipsis;
      @include font-semi(16px);
      width: calc(100% - 96px);
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    &-value {
      @include font-semi(16px);
      width: 70px;
      display: inline-block;
      text-align: right;
      vertical-align: middle;
    }
  }

  &-dropdown {
    &-toggle {
      @extend %form-input;
      @include padding(0, 10px);
      background-color: transparent !important;
      text-align: left;

      &-hide {
        pointer-events: none;
        
        &::after {
          display: none;
        }
      }

      &::after {
        margin-left: 0;
      }

      &:focus {
        box-shadow: none !important;
      }

      img {
        margin-top: -3px;
      }

      span {
        @include margin-two-var(0, 10px);
      }
    }

    &-filter {
      @include padding-two-var(10px, 20px);
      width: 100%;
      color: $white;
      background-color: transparent !important;
      border: none;
      border-bottom: $dark-blue-border;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $white;
        opacity: 1;
      }
      
      &:-ms-input-placeholder {
        color: $white;
      }
      
      &::-ms-input-placeholder {
        color: $white;
      }
    }

    &-menu {
      @include margin-two-var(10px, -20px);
      @include padding-one-var();
      width: calc(100vw - 60px);
      color: $white;
      background-color: $dark-purple-2;
      border: none;
      border-radius: 10px;
      z-index: 1;

      @media (max-width: $max-tablet-width) {
        width: calc(100vw - 40px);
      }
    }

    &-list {
      max-height: 137px;
      overflow: auto;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &-item {
      @include padding-two-var(10px, 20px);
      @include font(16px);
      color: $white;
      border-bottom: $dark-blue-border;

      &:last-child {
        border-bottom: none;
      }

      &:focus, &:hover {
        color: $white;
        background-color: $pink-1;
      }

      &.active {
        // color: $pink-1;
        background-color: transparent;

        &:focus, &:hover {
          color: $white;
          background-color: $pink-1;
        }
      }
    }
  }

  &-input {
    @include padding-one-var();
    display: inline-block;
    vertical-align: bottom;
    color: $white;
    background-color: transparent;
    border: none;
    width: calc(100% - 125px);
    max-width: 200px;

    &:focus {
      outline: none;
    }
  }
}