@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';

.add-to-cart {
  color: $white;
  background-color: $dark-purple-7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  &-body {
    @include flex(center, flex-start, column);
    width: 100%;
    max-width: 400px;
  }

  &-content {
    @include flex(center, center, row);
    gap: 10px;

    &-button {
      @include purple-button();
      width: 100%;
    }

    &-wrapper {
      @include flex(center, flex-start, column);
      gap: 5px;
    }

    &-name {
      @include font-semi(14px);
    }

    &-sign {
      color: $light-purple;
      cursor: pointer;
    }

    &-input {
      @include padding-one-var(10px);
      @include font-semi(14px);
      @include width-height(100%, 38px);
      max-width: 112px;
      border-radius: 40px;
      border: none;
      outline: none;
      background-color: $light-gray-translucent;
      color: $white;
      text-align:center
    }

    &-amount {
      @include font(14px);
      @include flex(center, center, row);
      gap: 5px;
    }

    &-coin {
      @include width-height-same(14px);
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size();
    }
  }

  &-image {
    width: 100%;
    max-width: 62px;
  }

  &-container {
    padding-left: 0 !important;
    overflow: hidden;

    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;

    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  .modal {
    &-header {
      @include position-float-top-right(20px, 20px);
      @include font-semi(18px);
      @include padding();
      border-bottom: none;
      justify-content: center;
      
      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include padding-one-var(20px);
      @include font(12px);
      @include flex(center, center, column);
    }
  }
}