@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.tournament-prize {
  position: relative;
  margin-top: 5px;

  &-trophy {
    display: inline-block !important;
    position: relative;
    z-index: 1;
    width: 28px;

    @media (max-width: $max-mobile-width-sm) {
      width: 26px;
    }
  }

  &-text {
    @include padding(1px, 10px, 1px, 15px);
    @include font-medium(13px);
    margin-left: -15px;
    display: inline-block;
    background-image: linear-gradient(90.29deg, $orange-2 6.48%, rgba(250, 217, 97, 0) 140.5%);
    border-radius: 5px $border-radius-desktop $border-radius-desktop 5px;

    @media (max-width: $max-mobile-width-sm) {
      @include font-medium();
    }
  }
}