@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$buy-mgc-url: '../../assets/buy-mgc/';

.buy {
  @include width-height-same(100%);
  @include flex(center, center, column);

  &-layout {
    position: relative;
    overflow-x: hidden;
  }

  &-button {
    @include padding-one-var(20px);
    text-decoration: none;
    width: 100%;
    text-align: center;
    background-color: $dark-purple-1;
    pointer-events: none;
    opacity: 0.5;

    @media (min-width: $max-mobile-width-sm) {
      @include padding(10px);
    }

    &-enable {
      pointer-events: all;
      opacity: 1;
    }
  }

  &-wrapper {
    @include width-height-same(100%);
    max-width: 700px;
  }

  &-container {
    &-channel {
      &:last-child {
        margin-bottom: 80px;

        @media (min-width: $max-mobile-width-sm) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &-continue {
    @include purple-button(13px);
    width: 100%;
    max-width: 400px;
    text-align: center;

    @media (min-width: $max-mobile-width-sm) {
      max-width: 100%;
    }

    &-wrapper {
      @include flex(center, center, row);
      border: 1px solid $dark-purple-1;
      width: 100%;
      position: fixed;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $dark-purple-1;

      @media (min-width: $max-mobile-width-sm) {
        position: relative;
        bottom: 0;
        left: 0;
        transform: none;
        padding: 0;
      }
    }
  }

  &-header {
    @include font-bold(17px);
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: $max-mobile-width-sm) {
      color: $white;
    }

    &-back {
      @include position-float-top-left(0, 0);
      cursor: pointer;

      @media (min-width: $max-mobile-width-sm) {
        display: none !important;
      }

      &-close {
        display: none !important;

        @media (min-width: $max-mobile-width-sm) {
          display: block !important;
          @include position-float-top-left(-5px, -5px);
          cursor: pointer;
        }
      }
    }
  }

  &-card {
    width: 100%;
    background-color: $dark-purple-5;
    position: relative;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    margin-top: 20px;

    @media (min-width: $max-mobile-width-sm) {
      color: $white;
    }

    &-next {
      @include position-float-top-right(15px, 10px);
      pointer-events: none;
    }

    &-method {
      @include width-height-same(35px);
      transform: translateY(-7px);

      &-wrapper {
        margin-left: 1.5rem;

        &-card {
          @include margin(0, -7px, -10px, 31px);

          &-open {
            margin-bottom: 0;
          }
        }
      }
    }

    &-header {
      @include flex(flex-start, flex-start, row);
      @include padding-two-var(20px);
      gap: 10px;

      &-active {
        @include padding(20px, 0, 10px);
        border-bottom: $purple-border-thick;
      }
    }

    &-active {
      border: $purple-border-thick;
    }
  }

  &-text {
    @include font-semi(17px);
  }

  &-icon {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;


    &-tab {
      padding-left: 30px;
      height: 0px;
      transition: 0.3s ease-in-out;
      
      &-active {
        @include padding(20px, 20px, 20px, 30px);
        flex-direction: column;
        height: 100%;
      }

      &-radio {
        height: 100%;
        display: none !important;
        pointer-events: none;
        transition: 0.3s ease-in-out;
      }

      &-radio-active {
        height: 100%;
        display: block !important;
        pointer-events: all;
      }

      &-wrapper {
        @include flex(flex-start, center, row);
        width: 100%;

        &-text {
          @include font-medium(14px);
          white-space: pre;

          @media (max-width: $max-mobile-width-xs) {
            @include font-medium(12px);
          }
        }

        &-hide {
          display: none;
          pointer-events: none;
        }
      }
    }

    &-method {
      @include width-height(auto, 22px);

      @media (min-width: $max-mobile-width-sm) {
        @include width-height(auto, 25px);
      }

      &-hide {
        display: none;
        pointer-events: none;
      }
    }

    &-hide {
      display: none;
    }
  }

}