@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

$tournaments-url: '../../assets/tournaments/';

.gamepass-game {
  background-color: $dark-pink-3;
  background-image: $wen-lambo-list-page-gradient;

  &-section {
    &-container {
      @include flex(space-between, center, row);
      @include padding(0px, 0px, 10px, 0px);
    
      @media (max-width: $max-mobile-width-sm) {
        margin-bottom: 30px;
      }
    }

    &-title {
      @include font-semi(24px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(18px);
      }
    }
      
    &-back {
      &-text {
        @include flex(center, center, row);
        @include font-medium(14px);
        color: $white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          color: $white;
        }
      }
    
      &-icon {
        color: $white;
        margin-right: 5px;
      }
    }
  }

  &-title {
    @include font-semi(18px);
    margin-bottom: 40px;

    @media (max-width: $max-mobile-width-sm) {
      margin-bottom: 20px;
    }
  }

  &-description {
    @include font-light(16px);
    @include margin-one-var();
    text-align: left;
    padding-right: 20px;

    @media (max-width: $max-mobile-width-sm) {
      @include font-light(12px);
    }
  }

  &-details {
    @include flex(flex-start, flex-start, column);
    gap: 5px;

    @media (max-width: $max-mobile-width-sm) {
      @include flex(center, center, column);
      margin-top: -10px;
    }

    &-wrapper {
      @include flex(space-between, center, row);
      padding-right: 20px;
      width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        padding: 0;
      }
    }

    &-genres {
      @include font(14px);
      color: $light-purple;
    }

    &-participants {
      @include font-light(13px);
      @include flex(center, center, row);
      gap: 8px;

      &-icon {
        @include icon-image('participants-icon', true, $tournaments-url);
        @include width-height-same(13px);
        @include background-position-size(center, contain);
      }
    }

    &-title {
      @include font-semi(24px);
      width: 100%;
      text-align: left;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(18px);
      }
    }

    &-description {
      @include font-light(16px);
      @include padding(10px, 20px, 0, 0);
      width: 100%;
      text-align: left;

      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
        @include margin-one-var();
      }
    }
  }

  &-info {
    &-label {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }

    &-value {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      text-align: right;
    }
  }

  &-button {
    @include orange-button(12px);
    width: 100%;
    max-width: 300px;
    margin-top: 15px;

    @media (max-width: $max-mobile-width-sm) {
      max-width: 100%;
    }
  }

  &-how-to-play {
    @include flex(flex-start, flex-start, column);
    border-top: $dark-blue-border;
    margin-top: 25px;

    @media (max-width: $max-mobile-width-sm) {
      margin-top: 20px;
      border-top: none;
    }

    &-title {
      @include font-semi(18px);
      margin-top: 20px;
    }
  }

  &-container {
    @include flex(center, center, column);
  }

  &-gallery {
    border-top: none;
  }

  &-list {
    &-container {
      @include flex(flex-start, flex-start, column);
      @include width-height-same(100%);

      @media (max-width: $max-mobile-width-sm) {
        margin-top: 20px;
      }
    }
  }

  &-games {
    &-container {
      @include flex(flex-start, center, row);
      margin-bottom: 15px;
      background-color: transparent;
      border: none;
      @include padding-one-var();

      &:hover {
        .gamepass-game {
          &-games-image {
            box-shadow: $glow-purple;
          }
    
          &-name {
            color: $purple-6;
          }
        }
      }
    }

    &-image {
      @include width-height-same(150px);
      border-radius: $border-radius-mobile;
      margin-right: 10px;
    }
  }

  &-name {
    @include font-semi(16px);
    text-align: left;
    color: $white;
  }
}