@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.stw-game {
  &-section {
    @include padding-one-var();
    border-top: none;

    &-webgl-unsupported {
      height: 100vh;
    }

    &-header {
      .section-back {
        top: 28px;
        left: 30px;

        @media (max-width: $max-mobile-width-sm) {
          top: 17px;
          left: 20px;
        }
      }
    }
  }

  &-main-container {
    @include padding-one-var();
    overflow: hidden;
    height: 100vh;
    background-color: $dark-purple-1;

    @media (max-width: $min-mobile-width-sm) {
      @include padding-one-var();
    }
  }

  &-iframe {
    @include width-height-same(100%);
    @include padding-one-var();
    @include margin-one-var();
  }
}
