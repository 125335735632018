@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.coupon {
  @include width-height(100%, 165px);
  display: flex;
  text-align: left;
  position: relative;

  &-image {
    @include link($white, $white);
    @include width-height(100px, 100%);
    @include background-position-size(center, cover);
    background-color: $dark-blue-5;
    border-top-left-radius: $border-radius-mobile;
    border-bottom-left-radius: $border-radius-mobile;
  }

  &-design {
    @include width-height-same(16px);
    background-color: $dark-purple-1;
    border-radius: 50%;
    z-index: 1;

    &-top {
      @include position-float-top-left(-8px, 92px);
    }

    &-bottom {
      @include position-float-bottom-left(-8px, 92px);
    }
  }

  &-info {
    @include width-height(calc(100% - 100px), 100%);
    @include padding(15px, 10px, 15px, 20px);
    @include flex(space-between, flex-start, column);
    border-top-right-radius: $border-radius-mobile;
    border-bottom-right-radius: $border-radius-mobile;
    background-color: $dark-blue-5;
  }
}