@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.copy-code {
  @include flex(space-between, center, row);
  @include padding-two-var(10px, 20px);
  @include font(18px);
  width: $max-button-width-mobile;
  border-radius: $button-border-radius;
  background-color: $dark-grey-3;

  @media (max-width: $max-mobile-width-sm) {
    @include font(16px);
    width: 100%;
    max-width: $max-button-width-mobile;
  }

  &-fluid {
    width: 100%;
    max-width: none;
  }

  &-small {
    @include font(16px);
  }

  &-centered {
    @include margin-two-var(0, auto);
  }
  
  &-button {
    @include padding-one-var();
    @include font-semi(14px);
    color: $pink-1;
    background-color: transparent;
    border: none;

    @media (max-width: $max-mobile-width-sm) {
      @include font-semi(12px);
    }

    &-small {
      @include font-semi(12px);
    }
  }
}