@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.rating-platforms {
  width: 100%;
}

.rating {
  display: inline-block;
  width: 50%;
  text-align: left;

  &-only {
    width: 100%;
  }

  &-icon {
    color: $pink-1;
    margin-right: 3px;
  }

  &-rate {
    @include font-medium(14px);
    vertical-align: middle;
  }
}

.platforms {
  display: inline-block;
  width: 50%;
  text-align: right;

  &-only {
    width: 100%;
    text-align: left;
  }

  &-icon {
    @include width-height(auto, 16px);
    margin-left: 3px;

    &:first-child {
      margin-left: 0;
    }
  }
}