@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../../assets/icons/';

%input-box {
  @include font(14px);
  width: calc(100% - 75px);
  border-top-left-radius: $button-border-radius;
  border-bottom-left-radius: $button-border-radius;
  background-color: $light-purple-translucent;
}

.reward-details {
  padding-top: 0;
  border-top: none;

  .featured-section {
    margin-top: -5px;
  }

  &-header {
    @media (max-width: $max-mobile-width-sm) {
      padding-bottom: 20px;
    }
  }

  &-info {
    @include margin(-90px, auto, 0, auto);
    @include padding-one-var(30px);
    position: relative;
    z-index: 1;
    text-align: center;
    background-color: $dark-purple-7;
    border-radius: $border-radius-mobile;
    width: 85%;

    @media (max-width: $max-mobile-width-sm) {
      width: 100%;
    }

    h1 {
      @include font-semi(30px);
      margin-bottom: 15px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(24px);
      }
    }

    &-no-banner {
      margin-top: 0;
    }

    &-status {
      @include margin(0, auto, 15px, auto);
      @include padding-two-var(3px);
      @include font-semi(16px);
      text-transform: uppercase;
      border-radius: $border-radius-desktop;
      width: 150px;
      display: block;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-two-var(3px, 15px);
        @include font-semi(12px);
        width: auto;
        display: inline-block;
      }

      &-active {
        background-color: $green-2;
      }

      &-redeemed {
        background-color: $red-2;
      }
    }

    &-expiry {
      @include font-medium(20px);
      color: $light-purple;

      @media (max-width: $max-mobile-width-sm) {
        @include font-medium(14px);
      }
    }
  }

  &-redeem {
    margin-top: 15px;

    &-button {
      @include purple-button(14px);
      width: $max-button-width-mobile;

      @media (max-width: $max-mobile-width-sm) {
        @include purple-button();
        width: 100%;
      }
    }
  }

  &-mark-as-redeemed-warning {
    @include flex(flex-start, flex-start, row);
    @include margin(20px, auto, 0, auto);
    @include font-light(14px);
    color: $red-2;
    gap: 5px;
    width: 100%;
    max-width: 520px;

    @media (max-width: $max-mobile-width-sm) {
      @include font-light(12px);
      max-width: 450px;
    }

    &-text {
      text-align: left;
    }
  }

  &-code-description {
    @include font(14px);
    margin-top: 17px;

    @media (max-width: $max-mobile-width-sm) {
      @include font();
      margin-top: 15px;
    }
  }

  &-dropdown {
    margin-bottom: 10px;

    &-toggle {
      @include padding-two-var(15px, 20px);
      @include font(14px);
      width: $max-button-width-mobile;
      background-color: $dark-grey-3 !important;
      border: none;
      border-radius: $button-border-radius;
      position: relative;
      text-align: left;

      &:focus {
        box-shadow: none !important;
        outline: $purple-border !important;
      }

      &::after {
        @include position-float-top-right(25px, 20px);
        display: block;
        margin-left: 0;
      }

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;

        &::after {
          @include position-float-top-right(25px, 20px);
          display: block;
          margin-left: 0;
        }
      }
    }

    &-menu {
      width: $max-button-width-mobile;
      background-color: $dark-grey-3;
      border-radius: $border-radius-mobile;

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;
      }

      &-item {
        @include font(14px);
        color: $white;

        &:hover {
          color: $dark-grey-3;
          background-color: $white;
        }

        &.active {
          color: $pink-1;
          background-color: transparent;

          &:hover {
            color: $dark-grey-3;
            background-color: $white;
          }
        }
      }
    }
  }

  &-phone-input {
    @include margin(0, auto, 10px, auto);
    @include padding-two-var(15px, 20px);
    @include font(14px);
    display: flex;
    width: $max-button-width-mobile;
    background-color: $dark-grey-3;
    border-radius: $button-border-radius;

    @media (max-width: $max-mobile-width-sm) {
      width: 100%;
    }

    &-prefix {
      margin-right: 5px;
      padding-right: 5px;
      border-right: $white-border;
    }

    &-text {
      @include padding-one-var();
      border: none;
      width: calc(100% - 33px);
      color: $white;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }

  &-section {
    border-top: none;
    padding-bottom: 0;

    &-content {
      @include font-light(14px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
      }
    }
  }

  &-confirm {
    @include flex(center, center, column);
    width: 100%;

    &-description {
      width: 100%;
    }
  }

  &-form {
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: 400px;

    &-group {
      margin-top: 20px;
      background-color: transparent;
      display: block;
    }

    &-label {
      @extend %form-label;
      text-align: left;
      
      span {
        color: $pink-1;
        margin-left: 5px;
      }
    }

    &-error {
      @include font-semi(12px);
      color: $pink-1;
      margin-left: 5px;
      margin-top: 5px;

      &-message {
        @include font(12px);
        color: $pink-1;
        margin-top: 15px;
      }
    }

    &-input {
      @include padding-two-var(15px, 20px);
      margin-top: 10px;
      width: 100%;
      background-color: $dark-purple-7;
      border: none;
      border-radius: 5px;

      input[type="text"] {
        @include font(14px);
        @include padding-one-var(0);
        width: 100%;
        border: none;
        color: $white;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .img-container {
        @include width-height(100%, 150px);
        background-color: $black;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-error {
        outline: $pink-border;
      }

      &-prefilled {
        pointer-events: none;
      }
    }

    &-text {
      @include padding-two-var(10px, 20px);
      border: none;
      border-radius: $button-border-radius;
      background-color: $dark-blue-5;
    }

    &-textarea {
      margin-top: 10px;

      textarea {
        @include padding-two-var(15px, 20px);
        @include font(14px);
        width: 100%;
        color: $white;
        background-color: $dark-purple-7;
        border: none;
        border-radius: 5px;

        &:focus {
          outline: none;
        }
      }

      &-error {
        textarea {
          outline: $pink-border;
        }
      }
    }

    &-checkbox {
      @include flex(flex-start, center, row);
      @include font(14px);
      gap: 15px;
      margin-bottom: 12px;
      min-height: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }

      // Radio Button
      .radio {
        @include width-height-same(20px);
        @include margin-two-var(0, 2.5px);
        position: relative;
        background-color: transparent;
        border-radius: 50%;
        border: $pink-border-thick;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.radio {
        background-color: $dark-purple-1;
      }

      & input:checked~.radio {
        background-color: $dark-purple-1-translucent;

        &:after {
          display: block;
        }
      }

      & .radio::after {
        @include width-height-same(10px);
        @include position-float-top-left(3px, 3px);
        border-radius: 50%;
        background: $pink-1;
      }

      .checkbox {
        @include width-height-same(25px);
        position: relative;
        background-color: $dark-purple-1-translucent;
        border-radius: 5px;
        border: $light-purple-border-thick;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.checkbox {
        background-color: $grey-2;
      }

      & input:checked~.checkbox {
        border: $pink-border-thick;
        background-color: $pink-1;

        &:after {
          display: block;
        }
      }

      & .checkbox::after {
        @include width-height(8px, 14px);
        left: 6px;
        top: 2px;
        border: $white-border-checkbox;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &-caption {
        width: auto;
        max-width: calc(100% - 40px);
      }

      &-text {
        @include padding-two-var(15px !important, 20px !important);
        background-color: $light-purple-translucent !important;
        border-radius: $button-border-radius;
      }
    }

    &-prefix {
      @extend %mobile-number-prefix;
    }

    &-submit {
      @include purple-button(12px);
      margin-top: 20px;
      width: 100%;
      max-width: 400px;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-voucher-code {
    @include padding-two-var(10px, 20px);
    @include flex(space-between, center, row);
    @include font(14px);
    border-top-left-radius: $button-border-radius;
    border-bottom-left-radius: $button-border-radius;
    background-color: $light-purple-translucent;
    margin-top: 10px;
    width: 100%;
    background-color: $dark-blue-5;
    border: none;
    border-radius: $button-border-radius;

    &-copy {
      @include width-height(21px, 25px);
      @include padding-one-var();
      background-color: transparent;
      border: none;

      &-icon {
        @include width-height(21px, 25px);
        @include icon-image('copy', true, $icons-url);
        @include background-position-size(center, 20px);
      }
    }
  }
}