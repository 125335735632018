@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.camera {
  color: $white;
  background-color: $dark-blue-8;
  border-radius: 18px;
  width: 600px;

  @media (max-width: $min-mobile-width-sm){
    width: calc(100% - 20px);
  }
  

  &-container {
    &.fade .modal-dialog {
      opacity: 0;
    }

    &.show .modal-dialog {
      opacity: 1;
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }
  
  &-close {
    @include position-float-top-right(20px, 20px);
    @include font-medium(15px);
    @media (max-width: $max-mobile-width-sm){
      @include font-medium(13px);
    }
  }

  &-header {
    @include padding-two-var(17px, 30px);
    @include flex(center, center, row);
    color: $white;
    border-bottom: 2px solid $dark-blue-1;
    position: relative;
    cursor: pointer;

    h3 {
      @include font-medium(20px);
      margin: 0;
    }
  }

  &-dialog {
    @include flex(center, center, column);
    @include margin-one-var();
    @include width-height(100%, 100%);

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-web {
    width: 550px;
    @media (max-width: $min-mobile-width-sm){
      width: calc(100% - 20px);
    }
  }

  &-btn {
    @include padding-two-var(20px);
    color: $white;
    background-color: transparent;
    border: none;
  }

  .modal {
    &-body {
      @include flex(center, center, column);
      @include padding(20px);
    }
  }
  
}