@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.gamepass-terms-popup {
  @include margin-two-var(0px, 10px);
  @include padding-one-var();
  color: $white;
  border-radius: 8px;
  border: none;
  height: auto;
  background-color: $purple-8;
  background-image: $gamepass-terms-gradient;

  &-container {
    &.fade .modal-dialog {
      @include padding-two-var(0px, 20px);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-dialog {
    @include flex(center, center, row);
  }

  &-bg {
    background-color: $black;
    z-index: 1055;

    &.show {
      opacity: 0.65;
      height: 101vh;
    }
  }
  
  &-header {
    @include font-medium(12px);
    @include padding-one-var();
    color: $grey-6;
    border-bottom: none;
    cursor: pointer;
  }

  .modal {
    &-body {
      @include padding-one-var();
      @include margin-one-var();
      @include flex(flex-start, center, column);
      border-radius: 8px;
    }

    &-footer {
      @include flex(center, center, row);
      @include padding(0, 20px, 20px, 20px);
      border-top: none;
    }
  }

  &-close {
    color: $purple-1;
    font-size: 20px !important;

    &-container {
      @include flex(center, center, row);
      @include width-height-same(29px);
      @include padding-one-var(10px);
      @include margin(-10px, -10px);
      background-color: $white;
      border: none;
      border-radius: 25px;
      position: absolute;
      right: 0;
    }
  }

  &-content {
    @include flex(space-between, center, column);
    @include padding-one-var(20px);
    @include width-height-same(100%);
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  
    &-text {
      @include flex(center, center, column);
    }
  
    &-title {
      @include font-semi(24px);
      text-align: center;
      width: 100%;
    }
  
    &-description {
      @include font-light(14px);
      text-align: center;
      margin-bottom: 0;

      a {
        @include link($white, $white, underline);
        @include font-semi(14px);
      }
    }
  }

  &-button {
    @include white-button(14px);
    @include link($purple-1, $purple-1);
    @include margin-one-var();
    width: 100%;
  }
}