@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.claim-free {
  &-container {
    max-width: 400px;

    & .blur-modal-body {
      @include padding(40px, 20px, 20px, 20px);
      @include flex(center, center, column);
      gap: 6px;
    }
  }

  &-dialog {
    align-items: center;
  }

  &-image {
    max-width: 120px;
    min-height: 168px;
  }

  &-title {
    @include font-semi(22px);
  }

  &-description {
    @include font-light(14px);
  }

  &-button {
    @include margin(10px);
    @include purple-button();
    width: 100%;
  }
}