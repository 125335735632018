@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

$icons-url: '../../../assets/icons/';

.game-pass-product-amount {
  @include font(12px);

  &-medium {
    @include font-semi(12px);
  }

  &-large {
    @include font-semi(18px);
  }

  &-price {
    letter-spacing: normal;
    margin-right: 5px;
  }

  &-converted {
    color: $light-purple;
  }

  &-icon {
    @include width-height-same(13px);
    @include icon-image('mgo-coin', true, $icons-url);
    @include background-position-size();
    @include margin(2px, 3px);
    display: inline-block;
    vertical-align: top;

    &-large {
      @include width-height-same(22px);
      @include margin(2px, 6px);
    }
  }
}