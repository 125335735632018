@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$float-button-url: '../assets/';

.layout {
  background-color: $dark-purple-1;

  &-outlet {
    height: calc(100vh - 126px);
    height: calc((var(--vh, 1vh) * 100) - 126px);

    @media (max-width: $max-mobile-width-sm) {
      @include margin(65px, 0px, 60px, 0px);
      height: 100%;
    }

    &-auto {
      height: 100%;
    }

    &-no-footer {
      height: calc(100vh - 64px);
      height: calc((var(--vh, 1vh) * 100) - 64px);

      @media (max-width: $max-mobile-width-sm) {
        @include margin(65px, 0px, 60px, 0px);
        height: 100%;
      }
    }

    &-full {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      @media (max-width: $max-mobile-width-sm) {
        @include margin-one-var();
        height: 100%;
      }
    }

    &-bottom {
      height: calc(100vh - 64px);
      height: calc((var(--vh, 1vh) * 100) - 64px);

      @media (max-width: $max-mobile-width-sm){
        @include margin(65px, 0px, 0px, 0px);
        height: 100%;
      }
    }

    &-top {
      @include margin(0px, 0px, 70px, 0px);
      height: 100%;
    }

    &-hidden {
      margin: 0;
      height: 100%;
    }
  }

  &-banner {
    @include width-height-same(100%);
    max-width: $max-button-width-mobile;

    &-close {
      @include icon-image('close', false, $float-button-url);
      @include background-position-size(center, cover);
      @include width-height-same(30px);
      @include position-float-top-right(-12px, 10px);
      cursor: pointer;

      @media (max-width: $max-mobile-width-sm) {
        display: none !important;
      }
    }

    &-remind {
      @include font-medium(14px);
      @include position-float-bottom-left(-30px, 50%);
      text-decoration: underline;
      transform: translateX(-50%);
      cursor: pointer;
      display: none;

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;
        text-align: center;
        display: block;
      }
    }

    &-wrapper {
      @include margin-two-var(0, auto);
      position: relative;
      cursor: pointer;
    }

    &-container {
      @include flex(center, center, column);
      @include padding-one-var(50px);
      @include position-float-top-left(0, 0);
      @include width-height(100%, calc(var(--vh, 1vh) * 100));
      background-color: $loader-overlay-bg;
      z-index: 10;
    }
  }

  &-float {
    @include position-bottom-right(fixed, 80px, 4%);
    z-index: 1;

    @media (min-width: 1560px) {
      right: 16%;
    }
  }

  &-float-wheel {
    @include position-bottom-right(fixed, 140px, 4%);
    z-index: 5;
  }

  &-music {
    @include icon-image('music-fest', false, $float-button-url);
    @include background-position-size(center, cover);
    @include width-height-same(130px);
    cursor: pointer;

    &-close {
      @include icon-image('close', false, $float-button-url);
      @include background-position-size(center, cover);
      @include width-height-same(23px);
      @include position-float-top-right(-5px, 10px);
      cursor: pointer;
    }
  }

  &-wheel {
    @include icon-image('wheel', false, $float-button-url);
    @include background-position-size(center, contain);
    @include width-height-same(150px);
    cursor: pointer;
  }
}