@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.mechanics-carousel {
  @include margin-two-var(0, -30px);
  @include padding-two-var(30px);
  background-color: $stw-mechanics-translucent;

  @media (max-width: $max-mobile-width-sm) {
    @include margin-two-var(0, -20px);
  }

  &-inner {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $min-tablet-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
  }

  &-description {
    @include font-light(14px);
    margin-bottom: 15px;
  }

  &-banners {
    @media (max-width: $max-mobile-width-sm) {
      @include margin-one-var();
    }

    .indicators {
      @include padding-one-var();
      margin-top: 15px;
    }

    .indicator {
      @include margin-two-var(0, 5px);
      width: 20px;

      &-button {
        height: 4px;
      }
    }

    .carousel-arrow {
      &-prev {
        @include position-float-top-left(calc(50% - 37px), 0px);
      }
    
      &-next {
        @include position-float-top-right(calc(50% - 37px), 0px);
      }
    }
  }

  &-banner {
    @include width-height(100%, auto);

    img {
      @include width-height(100%, auto);
    }

    p {
      @include margin(15px);
      @include font(12px);
    }
  }
}