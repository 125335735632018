@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.gamepass-game {
  &-toast {
    @include padding-two-var(20px);
    color: $white;
    background-color: $dark-purple-1;
    width: 100%;
    max-width: 760px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 0px 30px $desktop-toast-box-shadow;
      
    @media (max-width: $max-tablet-width) {
      max-width: 500px;
    }
        
    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var();
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      background-color: $dark-blue-7;
    }
  
    &-title {
      @include font-semi(24px);
      margin-bottom: 10px;
    }
  
    &-text {
      @include padding-one-var();
      @include font-light(16px);
      max-width: 522px;
  
      @media (max-width: $max-tablet-width) {
        max-width: 380px;
      }
      
      @media (max-width: $max-mobile-width-sm) {
        @include flex(center, center, column);
        @include font-light(14px);
        border-top: none;
      }
    }
    
    &-button {
      @include purple-button-border(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 400px;
  
      &-container {
        @include flex(center, center, row);
        width: 100%;
        max-width: 568px;
        border-top: $grey-line-border;
        padding-top: 20px;
  
        @media (max-width: $max-tablet-width) {
          max-width: 380px;
        }
  
        @media (max-width: $max-mobile-width-sm) {
          @include flex(center, center, column);
          @include padding-one-var();
          border-top: none;
        }
      }
    }
  
    &-buy-button {
      @include purple-button(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 400px;
      margin-right: 15px;
    
      @media (max-width: $max-tablet-width) {
        width: 100%;
      }
  
      @media (max-width: $max-mobile-width-sm) {
        @include margin-two-var(10px);
      }
    }
            
    &-container {
      overflow: hidden;
    
      &.fade .modal-dialog {
        @include flex(center, center, column);
        
        @media (max-width: $max-tablet-width) {
          @include position-float-bottom-left(0px, 0px);
          transform: translateY(10vh);
        }
      }
    
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
    
    &-bg {
      background-color: $black;
    
      &.show {
        opacity: 0.5;
      }
    }
    
    &-header {
      @include font-semi(18px);
      @include flex(center, center, row);
      border-bottom: none;
      cursor: pointer;
    
      &-close {
        @include position-float-top-right(15px, 15px);
        @include width-height-same(1.2em !important);
          color: $white;
      }
    }
    
    &-dialog {
      @include margin-one-var();
      width: 100vw;
    
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
    
    .modal {
      &-body {
        @include padding-one-var(20px);
        @include flex(center, center, column);
      }
    }
  }
}