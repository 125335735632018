@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.back-link {
  @include link($white, $white);
  display: inline-block;
  margin-bottom: 15px;
  
  span {
    @include font-medium(14px);
    text-transform: uppercase;
    margin-left: 5px;
  }
}