@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$first-time-completion-rewards-url: '../../../assets/first-time-completion-rewards/';

.first-time-completion {
  &-rewards {
    @include background-image('bg', $first-time-completion-rewards-url, '.png', false);
    @include padding-two-var(0px, 30px);
    @include width-height(400px, 650px);
    border: none;
    color: $white;
    background-color: transparent;
    border-radius: 16px;

    @media (max-width: $max-mobile-width-xs) {
      @include width-height(370px, 600px);
    }

    &-image {
      @include width-height(200px, 100%);

      @media (max-width: $max-mobile-width-sm) {
        @include width-height(150px, 100%);
      }
    }

    &-close {
      @include position-float-top-right(62px, -30px);
      @include width-height-same(26px !important);
      background: $white;
      color: $purple-1;
      border-radius: 36px;
      cursor: pointer;
      font-size: 10px !important;
    
      @media (max-width: $max-mobile-width-xs) {
        @include position-float-top-right(55px, -33px);
      }
    }

    &-content {
      @include flex(center, center, column);
      text-align: center;
      width: 100%;
    }

    &-body {
      @include flex(space-between, center, column);
      @include padding(100px, 0px, 40px);
      @include margin-one-var();
      width: 100%;
      text-align: center;
      max-width: 400px;

      @media (max-width: $max-mobile-width-xs) {
        @include padding(90px, 0px, 40px);
      }
    }

    &-button {
      @include orange-button(12px);
      @include padding-one-var(12px);
      letter-spacing: 1px;
      width: 100%;
      max-width: 300px;
      cursor: pointer;

      @media (max-width: $max-mobile-width-xxs) {
        @include padding-one-var(10px);
      }
    }
    
    &-container {
      overflow: hidden;

      &.fade .modal-dialog {
        @include position-float-bottom-left(0, 0);
        transform: translateY(10vh);
      }

      &.show .modal-dialog {
        transform: translateY(0);
      }
    }

    &-bg {
      &.show {
        opacity: 0.6;
      }
    }

    &-dialog {
      @include margin-one-var();
      @include width-height(100%, 100vh);
      max-width: none !important;
      justify-content: center;
    }

    &-header {
      h1 {
        @include font-semi(18px);
      }

      p {
        @include font-light(12px);
        margin-bottom: 0;
      }
    }

    &-list {
      @include margin(12px, 0, 16px, 0);
      @include width-height(100%, 372px);
      padding-right: 10px;
      transform: translateX(5px);
      overflow-y: auto;

      @media (max-width: $max-mobile-width-xs) {
        @include width-height(100%, 334px);
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $wen-lambo-mechanics-button-bg;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $white;
        border-radius: 20px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: $white;
      }

      &-hide {
        @include width-height(100%, 428px);

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(100%, 390px);
        }
      }
    }

    &-claim-all {
      @include orange-button(14px);
      width: 100%;

      &-disable {
        pointer-events: none;
        cursor: none;
        display: none;
      }
    }
  }

  &-reward {
    @include flex(space-between, center, row);
    @include padding-one-var(10px);
    margin-bottom: 5px;
    background-color: $light-gray-translucent;
    border-radius: 5px;
    gap: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &-gold {
      @include width-height-same(60px);
      position: relative;

      @media (max-width: $max-mobile-width-xs) {
        @include width-height(60px, 54px);
      }

      &-image {
        @include width-height-same(52px);
      }

      &-value {
        @include font-bold();
        @include position-float-bottom-left(0, 50%);
        transform: translateX(-50%);
        background-color: $white;
        border-radius: 12px;
        color: $orange-9;
        width: 100%;
        max-width: 36px;

        @media (max-width: $max-mobile-width-xs) {
          bottom: -5px;
        }
      }
    }

    &-description {
      @include font(14px);
      width: calc(100% - 160px);
      text-align: left;

      @media (max-width: $max-mobile-width-xs) {
        @include font(12px);
        width: calc(100% - 140px);
      }

      span {
        color: $yellow-6;
      }
    }

    &-button {
      @include width-height(80px, 40px);
      cursor: pointer;

      @media (max-width: $max-mobile-width-xs) {
        @include width-height(70px, 35px);
      }

      &-disable {
        pointer-events: none;
        cursor: none;
      }

      &-image {
        &-claimed {
          @include width-height-same(40px);

          @media (max-width: $max-mobile-width-xs) {
            @include width-height-same(35px);
          }
        }

        &-completed,
        &-in-progress {
          @include width-height(80px, auto);

          @media (max-width: $max-mobile-width-xs) {
            @include width-height(70px, auto);
          }
        }
      }
    }
  }

  &-claim-reward {
    @include flex(center, center, column);
    @include width-height-same(110%);
    position: absolute;
    margin-left: -50px;
    margin-top: -20px;

    &-icon {
      @include width-height(200px, auto);
      z-index: 1;
      animation: fadeReward 1.7s;
    }

    &-text {
      @include font-semi(18px);
      color: $white;
      margin-bottom: 5px;
      z-index: 1;
      animation: fadeReward 1.7s;
    }

    &-bg {
      @include width-height(100vw, 100vh);
      background-color: $black;
      position: absolute;
      z-index: 0;
      opacity: 0.6;
    }
  }
}

@keyframes fadeReward {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}