@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../../assets/icons/';

.withdrawal {
  @include width-height(100%, 70vh);
  text-align: center;
  position: relative;
  text-align: start;

  &-container {
    @include padding-two-var(20px, 25px);
    @include margin(0px, -20px, 30px, -20px);
    background-color: $dark-purple-7;
  }

  &-row-container {
    @include flex(space-between, center, row);
  }

  &-title {
    @include font-semi(14px);

    &-amount {
      @include font-semi(24px);
    }

    &-currency {
      @include font(14px);
      margin-left: 5px;
    }
  }

  &-currency {
    @include font-semi(16px);

    &-icon {
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size(center, cover);
      @include width-height-same(30px);
      margin-right: 5px;
    
      @media (max-width: $max-mobile-width-sm) {
        @include width-height-same(20px);
      }
    }
  }

  &-country {
    @include width-height-same(30px);
    margin-right: 5px;
  }

  &-input {
    @include font-semi(24px);
    @include padding-two-var(5px, 15px);
    @include margin-two-var(10px);
    color: $white;
    background-color: $light-purple-translucent;
    border-radius: 20px;
    border: none;
    width: 100%;
    outline: none;
  }

  &-text {
    @include font(14px);
    margin-bottom: 20px;

    &-balance {
      @include font-semi(14px);
      margin-left: 5px;
    }
  }

  &-convert {
    &-all {
      @include font-semi(14px);
      color: $pink-6;
      cursor: pointer;
      margin-bottom: 20px;
    }

    &-icon {
      @include icon-image('convert', true, $icons-url);
      @include background-position-size(center, cover);
      @include width-height-same(100%);

      &-container {
        @include flex(center, center, row);
        @include position-float-bottom-right(auto, 50%);
        @include padding-one-var(13px);
        @include width-height-same(50px);
        background-color: $purple-7;
        border-radius: 50px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @media (max-width: $max-mobile-width-sm) {
          @include position-float-bottom-right(-15, 43%);
        }
      }
    }
  }

  &-button {
    @include white-button();
    @include padding-one-var(10px);
    @include position-float-bottom-right(0, 0);
    width: 100%;
    color: $purple-1;

    &:disabled {
      color: $grey-14;
    }

    &-coming-soon {
      @include position-float-top-right(0, 0);
      @include padding-two-var(3px, 10px);
      @include font-bold(12px);
      color: $white;
      background-color: $pink-6;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  &-conversion {
    &-container {
      @include padding-one-var(15px);
      @include flex(space-between, center, row);
      @include font(14px);
      background-color: $purple-translucent-2;
      border-radius: 5px;
      margin-top: 10px;
    }

    &-title {
      @include font-semi(14px);
    }
  }
}