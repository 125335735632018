@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.game-pass-checkout {
  color: $white;
  background-color: $dark-purple-7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  @media (min-width: $min-tablet-width) {
    border: $purple-border-medium;
    max-width: 530px;
  }

  &-theme {
    &-dark {
      border: 1px solid $purple-1;
      background: $dark-purple-7;

      &::before {
        content: '';
        background: none;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include margin();
        max-width: 100%;
        border-radius: 0;
        border: none;
      }
  
      & .game-pass-checkout-item {
        background: $purple-7;
      }
  
      & .game-pass-checkout-method {
        background: $purple-7;
      }

      &-methods {
        @include width-height(100vw, 100vh);
        @include flex(center, flex-start, row);
        background: $dark-purple-1;

        & .game-pass-checkout-available-methods-mobile {
          @include padding(14px, 16px, 16px, 16px);
        }
      }
    }
  }

  &-full {
    @media (max-width: $max-mobile-width-sm) {
      @include width-height(100vw, 100vh);
      background-color: $dark-purple-1;
    }
  }

  &-close {
    @include position-float-top-right(-12px, -14px);
    @include width-height-same(26px !important);
    background: $white;
    color: $purple-1;
    border-radius: 36px;
    cursor: pointer;
    font-size: 10px !important;
  }

  &-method {
    @include flex(flex-start, center, row);
    @include padding-two-var(0, 16px);
    @include width-height(100%, 60px);
    @include margin(4px);
    background-color: $desktop-toast-box-shadow-3;
    position: relative;
    border: $transparent-border;
    border-radius: $border-radius-mobile;
    cursor: pointer;

    &-dropdown-open {
      border-radius: $border-radius-mobile $border-radius-mobile 0 0;

      @media (max-width: $max-mobile-width-sm) {
        border-radius: 0 0 $border-radius-mobile $border-radius-mobile;
      }
    }

    &-game-over {
      @include margin(10px);
    }

    &-error {
      border: $red-border;
    }

    &-label {
      @include margin(20px);
      @include font-semi(14px);
    }

    &-title {
      @include font-semi(14px);
    }

    &-description {
      @include font();
      margin-right: 30px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    &-arrow {
      @include position-float-top-right(50%, 10px);
      transform: translateY(-50%);
      font-size: 2rem !important;
    }

    &-image {
      max-width: 54px;
      max-height: 42px;

      &-wrapper {
        max-width: 54px;
        text-align: left;
      }

      &-small {
        max-width: 33px;
      }
    }
  }

  &-button {
    @include purple-button();
    @include margin(30px);
    width: 100%;

    &-game-over {
      @include margin(20px);
    }

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-available {
    &-methods {
      @include padding(0 !important);
      border-top: none !important;
      width: 100%;

      &-container {
        @include position-float-top-left(58px, -1px);
        @include padding(10px, 10px, 10px, 20px);
        width: calc(100% + 2px);
        max-height: 146px;
        bottom: auto;
        overflow-y: auto;
        background: $desktop-toast-box-shadow-2;
        border-radius: 0 0 $border-radius-mobile $border-radius-mobile;
        z-index: 5;

        @media (max-width: $max-mobile-width-sm) {
          @include position-float-bottom-left(58px, -1px);
          top: auto;
          border-radius: $border-radius-mobile $border-radius-mobile 0 0;
        }

        &-error {
          top: 60px;
          bottom: auto;

          @media (max-width: $max-mobile-width-sm) {
            top: auto;
            bottom: 60px;
          }
        }
      }

      &-mobile {
        @include padding(0, 16px, 16px, 16px);
        @include margin(4px);
        border-radius: 8px;
        background-color: $dark-purple-7;
      }

      &-text {
        @include font-semi(16px);
        @include margin(40px);
        text-align: left;
      }

      &-wrapper {
        @include flex(space-between, center, row);
        width: 100%;
        cursor: pointer;
      }

      &-title {
        @include font-semi(14px);
        text-align: left
      }

      &-description {
        @include font();
        margin-right: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;

        @media (max-width: $max-mobile-width-xs) {
          max-width: 118px;
        }
      }

      &-image {
        max-width: 54px;
        max-height: 42px;

        &-wrapper {
          width: 54px;
          text-align: left;
        }

        &-small {
          max-width: 33px;
        }
      }
    }
  }

  &-item {
    @include flex(center, center, column);
    @include padding(10px, 10px, 10px, 20px);
    min-height: 98px;
    background: $desktop-toast-box-shadow-3;
    border-radius: 8px;
    gap: 10px;
    width: 100%;

    &-wrapper {
      @include flex(flex-start, center, row);
      width: 100%;
      gap: 3vw;

      @media (min-width: $min-mobile-width-sm) {
        gap: 20px;
      }
  
    }

    &-image {
      max-width: 20vw;
      max-height: 82px;
    }

    &-title {
      @include font-semi(14px);
      text-align: left
    }

    &-amount {
      @include font(14px);
      color: $white;

      &-wrapper {
        display: grid;
        grid-template-columns: 72px 1fr;
        gap: 10px;
      }

      &-mgc {
        @include flex(center, center, row);
        @include font(14px);
        color: $light-purple;
        gap: 4px;

        &-icon {
          @include width-height-same(14px);
          margin-bottom: 1px;
        }
      }
    }

    &-icon {
      max-width: 14px;
    }
  }

  &-email {
    padding-bottom: 16px;
    width: 100%;

    &-input {
      @include font-semi(14px);
      @include padding-two-var(0, 20px);
      @include margin(8px);
      @include flex(flex-start, center, row);
      @include width-height(100%, 44px);
      background-color: $light-gray-translucent;
      border-radius: 40px;
      color: $white;
      border: none;
      outline: none;
    }
  }

  &-number {
    @include padding(20px, 0, 10px);
    width: 100%;

    &-title {
      @include font-semi();
      color: $light-purple;
      text-align: left;
      
      & > span {
        color: $pink-1;
      }
    }

    &-input {
      @include padding(0, 20px, 0, 4px);
      @include font-semi(14px);
      @include margin(8px);
      @include flex(flex-start, center, row);
      @include width-height(100%, 44px);
      background-color: $white;
      border-radius: 40px;
      color: $white;
      border: $transparent-border;
      outline: none;

      &-error {
        border: $red-border;
      }

      &-text {
        &-error {
          @include font-semi();
          @include padding(6px);
          text-align: left;
          color: $red-2;
        }
      }

      & > .dropdown {
        & > .phone-dropdown-toggle {
          @include flex(center, center, row);
          @include font-semi(14px);
          @include padding();
          color: $dark-purple-7;
        }
      }
  
      & > .phone-input {
        @include font-semi(14px);
        color: $dark-purple-7;
      }
  
      &-dropdown {
        max-width: 400px;
      }

      &-dark {
        @include padding-two-var(0, 20px);
        background-color: $light-gray-translucent;

        & > .dropdown {
          & > .phone-dropdown-toggle {
            @include flex(center, center, row);
            @include font-semi(14px);
            @include padding();
            color: $white;
          }
        }

        & > .phone-input {
          @include font-semi(14px);
          color: $white;
        }
      }
    }
  }

  &-container {
    overflow: hidden;
    padding-left: 0 !important;

    @media (max-width: $min-mobile-width-sm) {
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
    
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.7;
    }
  }

  &-dialog {
    @include margin-one-var();
    min-height: 100%;
    justify-content: center;

    @media (max-width: $max-mobile-width-sm) {
      align-items: flex-end;
    }

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-body {
    &-methods {
      @media (max-width: $max-mobile-width-sm) {
        display: block !important;
      }
    }
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: center;
    }

    &-body {
      @include padding-one-var(20px);
      @include font(16px);
      @include flex(center, center, column);
      width: 100%;
      position: relative;

      &-methods {
        @include padding-one-var(30px);
      }
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      justify-content: center;
      border-top: none;
    }
  }

  &-contents {
    width: 100%;
  }

  &-checkbox {
    @include font-light(12px);
    color: $white;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    max-width: 483px;
    margin-top: 20px;
  
    input {
      @include width-height-same(0);
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  
    &-label {
      display: inline-block;
      margin-left: 30px;
      text-align: left;
  
      a {
        @include font-bold(12px);
        @include padding-one-var();
        @include link($pink-1, $pink-1);
        background-color: transparent;
        border: none;
  
        &:focus {
          outline: none;
        }
      }
    }
  
    .checkbox {
      @include width-height-same(20px);
      @include position-float-top-left(0, 0);
      background-color: $dark-purple-2;
      border: $white-border-checkbox;
      border-radius: 5px;
      cursor: pointer;

      &-error {
        background-color: transparent;
        border: $red-border-thick;
      }
  
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  
    &:hover input ~ .checkbox {
      background-color: $grey-2;
    }
  
    & input:checked ~ .checkbox {
      border: $pink-border-checkbox;
      background-color: $pink-1;
  
      &:after {
        display: block;
      }
    }
  
    & .checkbox::after {
      @include width-height(4px, 9px);
      left: 5px;
      top: 2px;
      border: $white-border-thick;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}