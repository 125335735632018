@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';
 
$gamepass-url: '../../assets/gamepass/';
$tournaments-url: '../../assets/tournaments/';
 
.gamepass-games {
  overflow-x: hidden;
  background-color: $dark-pink-3;
  background-image: $wen-lambo-list-page-gradient;
  
  &-title {
    @include font-semi(18px);
    margin-top: 5px;
  }

  &-section {
    padding-top: 0;
    border-top: none;

    &-container {
      @include flex(space-between, center, row);
      @include padding(0px, 0px, 10px, 0px);
    }

    &-title {
      @include font-semi(24px);
      margin: 0px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(18px);
      }
    }
      
    &-back {
      &-text {
        @include flex(center, center, row);
        @include font-medium(14px);
        color: $white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover{
          color: $white;
        }
      }
    
      &-icon {
        color: $white;
        margin-right: 5px;
      }
    }
  }
    
  &-coin-container {
    @include position-float-top-right(-4px, 0);
  }
 
  &-banner {
    @include width-height(100%, auto);
    border-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-xs) {
      @include width-height(calc(100% + 40px), auto);
      @include margin-two-var(0, -20px);
      border-radius: 0;
    }
  }

  &-container {
    @include padding(0px, 0px, 10px);
    @include margin(20px, -10px, -10px, -10px);
    width: calc(100% + 20px);
  }
  
  &-passes {
    background-color: $light-purple-translucent;
  }
 
  &-game {
    @include padding-one-var(10px);
    position: relative;

    &-thumbnail {
      display: block;
      border-radius: $border-radius-mobile;

      &::before {
        @include wen-lambo-thumbnail-border(9px);
        z-index: 0;
      }

      &:hover {
        box-shadow: $glow-yellow;
      }
    }

    &-wrapper {
      @include flex(flex-start, flex-start, column);
      @include margin-one-var();
      @include padding-two-var(15px, 10px);
      background-color: $dark-purple-10;
      text-align: left;
      width: 100%;
      gap: 15px;
      position: relative;
      border-radius: $border-radius-mobile;
    }
 
    &-image {
      @include background-position-size();
      width: 100%;
      padding-top: 100%;
      border-radius: $border-radius-mobile;
      cursor: pointer;
    }

    &-details {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      gap: 15px;
    }

    &-info {
      @include link($white, $white);
      @include flex(flex-start, flex-start, column);
      gap: 3px;
    }
  
    &-genre {
      @include font(14px);
      color: $light-purple;
    }
  
    &-name {
      @include font-semi(14px);
      color: $white;
    }

    &-stats {
      @include flex(flex-start, center, row);
      gap: 5px;

      &-icon {
        @include icon-image('participants-icon', true, $tournaments-url);
        @include width-height(16px, 15px);
        @include background-position-size(center, 15px);
      }

      &-value {
        @include font-light(12px);
      }
    }

    &-button {
      @include orange-button(14px);
      @include padding-two-var(10px);
      width: 100%;
      cursor: pointer;
    }
  }
}
