@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.floating-icon {
  @include width-height-same(100px);
  cursor: pointer;

  @media (max-width: $max-mobile-width-xs) {
    @include width-height-same(64px);
  }

  &-wrapper {
    position: relative;
  }
}