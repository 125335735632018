$white: #fff;
$cream: #e0e0e0;
$light-purple: #a698b9;
$light-pink-1: #e3b7d1;
$grey-1: #6d7278;
$grey-2: #838991;
$grey-3: #615374;
$grey-4: #7e7490;
$grey-5: #f5f5f9;
$grey-6: #b7b7C1;
$grey-8: #b5b5b5;
$grey-9: #6e6f75;
$grey-11: #8f8c86;
$grey-12: #ededea;
$grey-13: #615e77;
$grey-14: #a5a5a5;
$grey-15: #898989;
$black: #000;
$teal: #4bc2c0;
$green-1: #31b380;
$green-2: #00ba7e;
$green-3: #009e94;
$red-1: #cd3d61;
$red-2: #ec436b;
$red-3: #d00250;
$orange-1: #f5945a;
$orange-2: #f76b1c;
$orange-3: #ff965a;
$orange-4: #f8a03d;
$orange-5: #fe6c6c;
$orange-6: #d04900;
$orange-7: #f99f3d;
$orange-8: #e8651a;
$orange-9: #fe9100;
$yellow-1: #fad961;
$yellow-2: #f8dc4b;
$yellow-3: #fee200;
$yellow-4: #f3d96f;
$yellow-5: #fbc12c;
$yellow-6: #ffc981;
$peach: #fe828a;
$pink-1: #f304a0;
$pink-2: #fa0ace;
$pink-3: #b70378;
$pink-3: #e7006f;
$pink-4: #d700ff;
$pink-5: #d46bb7;
$pink-6: #ee049b;
$purple-1: #792de5;
$purple-2: #b889f3;
$purple-3: #411c80;
$purple-4: #4d1b91;
$purple-5: #976beb;
$purple-6: #b47dff;
$purple-7: #2a2858;
$purple-8: #a038e7;
$purple-9: #8643e0;
$purple-10: #8c37c3;
$purple-11: #c18ff4;
$purple-12: #541c7e;
$blue-1: #1f2e8d;
$blue-2: #176fbf;
$blue-3: #0527a7;
$blue-4: #4224f9;
$cyan: #1bc4cb;
$cyan-1: #38dde7;
$teal-1: #0ed3c8;
$dark-purple-1: #110a24;
$dark-purple-2: #0a0e1b;
$dark-purple-3: #18082d;
$dark-purple-4: #100a22;
$dark-purple-5: #19082e;
$dark-purple-6: #23224a;
$dark-purple-7: #161532;
$dark-purple-8: #2f1f51;
$dark-purple-9: #21203c;
$dark-purple-10: #27143a;
$dark-purple-11: #3b1640;
$dark-purple-12: #4a1e05;
$dark-blue-1: #1b1e40;
$dark-blue-2: #242f50;
$dark-blue-3: #2f3157;
$dark-blue-4: #120b25;
$dark-blue-5: #2c2750; 
$dark-blue-6: #221d45;
$dark-blue-7: #11132a;
$dark-blue-8: #161937;
$dark-blue-9: #1d2042;
$dark-blue-10: #121329;
$dark-blue-11: #0f162a;
$dark-pink-1: #8a005a;
$dark-pink-2: #330223;
$dark-pink-3: #af037e;
$dark-pink-4: #7e0a64;
$dark-grey-1: #272433;
$dark-grey-2: #3e3e3e;
$dark-grey-3: #2e2d47;
$dark-grey-4: #413e39;
$dark-grey-5: #302d3c;
$discord: #7187dd;
$telegram: #2699cb;

$dark-purple-1-translucent: rgba(21, 18, 43, 0.8);
$dark-purple-2-translucent: rgba(10, 10, 24, 0.9);
$dark-purple-3-translucent: rgba(22, 21, 50, 0.7);
$dark-purple-transparent: rgba(10, 10, 24, 0);
$loader-overlay-bg: rgba(10, 10, 24, 0.8);
$loader-overlay-bg-dark: rgba(10, 10, 24, 1);
$profile-info-bg: rgb(16, 22, 41, 0.8);
$progress-bar-bg: rgb(74, 53, 146, 0.7);
$step-border-color: rgba(243, 4, 160, 0.5);
$purple-translucent: rgba(121, 45, 229, 0.8);
$purple-translucent-2: rgba(121, 45, 224, 0.2);
$ranked-prizes-bg: rgba(166, 152, 185, 0.2);
$light-purple-translucent: rgba(255, 255, 255, 0.1);
$dark-grey-translucent: rgba(0, 0, 0, 0.21);
$light-gray-translucent: rgba(255, 255, 255, 0.2);
$light-gray-translucent-1: rgba(255, 255, 255, 0.14);
$dark-grey-translucent: rgba(0, 0, 0, 0.21);
$white-translucent: rgba(255, 255, 255, 0.2);
$white-translucent-1: rgba(255, 255, 255, 0.14);
$desktop-toast-box-shadow: rgba(0, 0, 0, 0.7);
$desktop-toast-box-shadow-1: rgba(0, 0, 0, 0.5);
$desktop-toast-box-shadow-2: rgba(0, 0, 0, 0.8);
$desktop-toast-box-shadow-3: rgba(0, 0, 0, 0.4);
$confirm-widthrawal-bg: rgba(134, 67, 224, 0.3);
$stw-mechanics-translucent: rgba(0, 0, 0, 0.15);
$stw-mechanics-prizes-bg: rgba(255, 255, 255, 0.14);
$stw-mechanics-carousel-indicator-bg: rgba(255, 255, 255, 0.28);
$white-translucent: rgba(255, 255, 255, 0.05);
$wen-lambo-mechanics-button-bg: rgba(0, 0, 0, 0.3);
$blur-bg: rgba(73, 71, 116, 0.5);

$purple-button-gradient: linear-gradient(142.79deg, $pink-2 -2.01%, $purple-1 87.59%);
$purple-button-gradient-reverse: linear-gradient(239.64deg, $pink-2 -4.88%, $purple-1 79.08%);
$pink-button-gradient: linear-gradient(24.83deg, $pink-3 -38.02%, $peach 71.13%);
$progress-bar-gradient: linear-gradient(to right, $teal 0%, $purple-1 50%, $pink-1 100%);
$site-background-gradient: linear-gradient($dark-purple-1, $dark-purple-2);
$transparent-gradient-prev: linear-gradient(to right, $dark-purple-2 0%, $dark-purple-transparent 100%);
$transparent-gradient-next: linear-gradient(to right, $dark-purple-transparent 0%, $dark-purple-2 100%);
$transparent-gradient-vertical: linear-gradient($dark-purple-transparent 0%, $dark-purple-2-translucent 100%);
$orange-button-gradient: linear-gradient(to right, $orange-2 0%, $yellow-1 100%);
$mobile-banner-gradient: linear-gradient(to right, rgba(21, 18, 43, 0) 0%, rgba(21, 18, 43, 0.7) 100%);
$gamepass-coins-gradient: linear-gradient($cyan, $blue-2);
$wen-lambo-gradient: linear-gradient(353.35deg, $dark-blue-11 3.77%, $dark-pink-3 95.01%);
$wen-lambo-tutorial-gradient: linear-gradient(180deg, $light-pink-1 47.65%, $purple-8 100%);
$wen-lambo-border-gradient: linear-gradient(192.41deg, $orange-6 2.64%, $yellow-3 48.61%, $orange-6 97.72%);
$wen-lambo-list-page-gradient: linear-gradient(353.62deg, $dark-pink-3 -76.99%, $dark-blue-11 164.21%);
$wen-lambo-details-page-gradient: linear-gradient(353.69deg, $dark-blue-11 2.47%, $dark-pink-4 84.19%);
$wen-lambo-free-gold-gradient: linear-gradient(343.14deg, $dark-purple-12 13.32%, $orange-8 88.37%);
$wen-lambo-free-spin-gradient: linear-gradient(360deg, $cyan-1 -32.86%, $blue-4 100%);
$wen-lambo-banner-gradient: linear-gradient(86.52deg, $orange-6 -5.37%, $yellow-3 283.65%);
$gamepass-terms-gradient: linear-gradient(180deg, $light-pink-1 0%, $purple-8 100%);
$refer-a-friend-button-gradient: linear-gradient(46.58deg, $red-3 5.29%, $orange-5 65.89%);
$refer-a-friend-background-gradient: linear-gradient(360deg, $red-3 0%, $orange-5 100%);
$mobile-app-prompt-gradient: linear-gradient(270deg, $green-3 0%, $teal-1 137.5%);
$free-spin-gradient: linear-gradient(30deg, $purple-1 0%, $teal-1 100%);
$free-spin-button-gradient: linear-gradient(180deg, $cream 0%, $grey-15 100%);

$glow: 0 0 15px $pink-4;
$glow-purple: 0 0 15px $purple-1;
$glow-purple-small: 0 0 10px $purple-1;
$glow-purple-large: 0 0 34px $purple-1;
$reward-status-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
$tutorial-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
$glow-yellow: 0 0 10px 0 $yellow-4;
$glow-yellow-large: 0px 0px 24px $yellow-5;
$blur-modal-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$glow-teal: 0 0 10px 0 $teal-1;

$purple-border: 1px solid $purple-1;
$purple-border-medium: 1.5px solid $purple-1;
$purple-border-thick: 2px solid $purple-1;
$purple-light-border: 1px solid $purple-2;
$purple-light-border-thick: 2px solid $purple-2;
$dark-blue-border: 1px solid $dark-blue-1;
$dark-blue-border-thick: 4px solid $dark-blue-1;
$pink-border: 1px solid $pink-1;
$pink-border-thick: 2px solid $pink-1;
$pink-border-checkbox: 3px solid $pink-1;
$white-border: 1px solid $white;
$white-border-thick: 2px solid $white;
$white-border-checkbox: 3px solid $white;
$grey-border: 1px solid $grey-1;
$grey-line-border: 1px solid $dark-grey-5;
$light-purple-border: 1px solid $light-purple;
$light-purple-border-thick: 2px solid $light-purple;
$profile-pic-border: 8px solid $dark-purple-2;
$mobile-number-prefix-border: 2px solid $grey-2;
$step-border: 3px solid $step-border-color;
$top-up-amount-border: 2px solid $grey-13;
$home-menu-item-border: 1px solid $purple-7;
$referral-history-border-top: 5px solid $purple-7;
$cart-dot-border: 2px solid $dark-blue-4;
$home-menu-dot-border-1: 2px solid $dark-purple-7;
$home-menu-dot-border-2: 2px solid $dark-purple-1;
$black-border-thick: 2px solid $black;
$transparent-border: 1px solid transparent;
$red-border: 1px solid $red-2;
$red-border-thick: 2px solid $red-2;
