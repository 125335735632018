@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$gamepass-url: '../../assets/gamepass/';
$home-menu-url: '../../assets/home-menu/';
$icons-url: '../../assets/icons/';
$transactions-url: '../../assets/transactions/';

.home {
  &-layout-content {
    @media (min-width: $min-tablet-width) {
      @include padding-two-var(30px, 0);
    }

    &-inner {
      max-width: none;
    }
  }

  &-padding {
    margin-top: 20px;
  }

  &-banners {
    width: 100%;
    max-height: 236px;
    border-radius: $border-radius-mobile;
    cursor: pointer;
    
    &-container {
      @include grid-two-banners(1fr 1fr, 1fr, 20px);
      position: relative;
      z-index: 1;
      width: 100%;
      margin-bottom: 30px !important;
      aspect-ratio: 500 / 88;

      @media (max-width: $max-mobile-width-sm) {
        aspect-ratio: 120 / 88;
      }
    }
  }

  &-menu {
    @include margin(0, auto, 30px, auto);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, auto);
    }

    @media (min-width: $min-tablet-width) {
      width: calc(100% - 60px);
    }

    &-logo {
      width: 80%;

      &-prod {
        @include width-height-same(100%);
        @include background-position-size(center, contain);
        background-repeat: no-repeat;
      }
    }

    &-referral {
      display: block;
      position: relative;
      z-index: 1;
      margin-bottom: 20px;

      @media (max-width: $max-mobile-width-sm) {
        @include margin-two-var(20px);
      }
    }

    &-subtitle {
      width: 100%;
    }

    &-banner {
      display: block;
      margin-bottom: 20px;
      cursor: pointer;

      &-referral {
        @include width-height(100%, auto);
        margin-bottom: 0;
        border-radius: $border-radius-mobile;
        cursor: pointer;
      }

      &-image {
        @include width-height(100%, auto);
        position: relative;
        border-radius: $border-radius-mobile;
        
        @media (max-width: $max-mobile-width-sm) {
          @include margin-two-var(0, -20px);
          width: calc(100% + 40px);
          border-radius: 0;
        }
      }

      &-mobile {
        @include flex(center, center, row);
        @include width-height(calc(100% + 40px), 320px);
        @include background-position-size();
        @include margin-two-var(0, -20px);

        @media (max-width: $max-mobile-width-xs) {
          height: 225px;
        }

        @media (max-width: $max-mobile-width-xxs) {
          height: 200px;
        }

        &-inner {
          @include flex(flex-start, center, column);
          @include margin-two-var(0, auto);
          gap: 20px;
          width: calc(100% - 40px);
          max-width: 240px;

          @media (max-width: $max-mobile-width-xs) {
            max-width: 160px;
          }
  
          @media (max-width: $max-mobile-width-xxs) {
            max-width: 145px;
          }
        }
      }
    }

    &-items {
      @include flex(space-between, center, row);
      width: 100%;
      margin-bottom: 20px;
      gap: 15px;

      @media (max-width: $max-mobile-width-sm) {
        position: relative;
        margin-top: 0;
        gap: 0;
      }
    }

    &-item {
      @include flex(center, center, row);
      width: 100%;
      position: relative;

      @media (max-width: $max-mobile-width-sm) {
        width: fit-content;
        position: initial;
      }
      
      &-arrow {
        @include position-float-bottom-left(-28px, 20px);
        @include width-height-same(0);
        border: 15px solid transparent;
        border-top: 0;
        border-bottom: 15px solid $white;
        z-index: 6;

        @media (max-width: $max-mobile-width-sm) {
          @include position-float-bottom-left(-45px, 18px);
        }
      }

      &-link {
        @include font-semi(14px);
        @include link($white, $white);
        @include flex(center, center, row);
        @include padding-one-var(10px);
        width: 100%;
        gap: 10px;
        border: $home-menu-item-border;
        border-radius: 5px;
        background-color: $dark-purple-7;
        cursor: pointer;

        &:hover > &-name {
          color: $purple-6; 
        }
        
        @media (max-width: $max-mobile-width-sm) {
          @include font-medium(12px);
          @include flex(center, center, column);
          @include padding-one-var();
          width: auto;
          gap: 5px;
          border: none;
          background-color: transparent;
        }
      }

      &-dot {
        @include width-height-same(16px);
        @include position-float-top-right(1px, 0);
        display: block;
        border: $home-menu-dot-border-1;
        border-radius: 50%;
        background-color: $pink-1;

        @media (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
          @include width-height-same(14px);
        }

        @media (max-width: $max-mobile-width-sm) {
          border: $home-menu-dot-border-2;
        }
      }

      &-icon {
        @include width-height-same(70px);
        @include background-position-size();
        display: block;
        position: relative;

        @media (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
          @include width-height-same(55px);
        }

        &-win-prizes {
          @include icon-image('win-prizes', true, $home-menu-url);
        }

        &-games {
          @include icon-image('games', true, $home-menu-url);
        }

        &-nfts {
          @include icon-image('nfts', true, $home-menu-url);
        }

        &-guilds {
          @include icon-image('guilds', true, $home-menu-url);
        }
      }
    }
  }

  &-credits {
    @include flex(flex-start, center, row);
    gap: 15px;

    &-transparent {
      @include padding(10px);
      width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        @include padding(0, 0, 20px);
      }
    }

    &-column {
      @include width-height(50%, 136px);

      @media (max-width: $max-mobile-width-sm) {
        height: 112px;
      }
    }
  }

  &-credit {
    @include padding-one-var(20px);
    @include width-height-same(100%);
    color: $white;
    position: relative;
    
    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(10px);
    }

    &-bg {
      @include position-float-top-right(0, 0);
      @include width-height-same(100%);
      border-radius: 5px;
      object-fit: cover;
      object-position: right center;

      &-center {
        object-fit: fill;
      }
    }

    &-contents {
      @include flex(flex-start, flex-start, column);
      gap: 10px;
      position: relative;
      z-index: 1;
    }
  
    &-icon {
      @include width-height-same(30px);
  
      @media (max-width: $max-mobile-width-sm) {
        @include width-height-same(20px);
      }
    }
    
    &-wallet {
      &-icon {
        @include icon-image('mgo-coin', true, $icons-url);
        @include background-position-size();
      }
    }

    &-gold-coins {
      &-icon {
        @include icon-image('coin', true, $gamepass-url);
        @include background-position-size();
      }
    }
  
    &-title {
      @include font(14px);
  
      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
      }
    }
  
    &-text {
      @include flex(flex-start, center, row);
      @include font-medium(14px);
      vertical-align: top;
      width: 100%;
      gap: 10px;
  
      @media (max-width: $max-mobile-width-sm) {
        gap: 5px;
      }
    }
  
    &-amount {
      @include font-semi(20px);
      color: $white;
    }

    &-arrow {
      @include link($white, $white);
      display: block;
    }
  
    &-instruction {
      @include flex(flex-start, center, row);
      @include font(14px);
      gap: 8px;
  
      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
      }
  
      &-icon {
        @include icon-image('info', false, $transactions-url);
        @include width-height(12px, 12px);
        @include background-position-size(center, cover);
      }
  
      &-link {
        @include link($white, $white);
        text-decoration: underline;
      }
    }

    &-topup {
      @include link($white, $white);
      @include flex(center, center, row);
      @include font-bold(14px);
      text-transform: uppercase;
      cursor: pointer;

      &-arrow {
        @include width-height-same(14px);
        margin-bottom: 1px;
      }
    }
  }

  &-sections {
    margin-bottom: 20px;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }

    &-container {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 30px;

      @media (max-width: $max-mobile-width-sm) {
        @include margin-two-var(0, -20px);
        @include padding(0, 20px, 20px, 20px);
      }

      &-bg {
        @include position-float-bottom-left(0, 0);
        width: 100%;
        object-fit: cover;
      }
    }

    &-inner {
      @include margin-two-var(0, auto);
      width: calc(100% - 60px);
      max-width: $max-layout-width;

      @media (max-width: $max-mobile-width-sm) {
        width: calc(100% - 40px);
      }
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-marketplace {
    @include margin(0, auto, 30px, auto);
    display: block;
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      @include margin(0, 0, 30px);
      width: 100%;
    }
  }
}