@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.nfts {
  @include grid();

  &-item {
    &-name {
      @include padding-two-var(10px, 0);
      text-align: center;
    }

    &-link {
      &:hover {
        .nfts-item {
          &-name {
            color: $purple-6;
          }
        }
      }
    }
  }

  &-loading {
    @include font-medium(14px);
    text-align: center;
    animation: blink 2s linear infinite;
  }
}

@keyframes blink {
  0%    { opacity: 0.25; }
  50%   { opacity: 0.75; }
  100%  { opacity: 0.25; }
}