@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

$social-media-icon-url: '../../assets/icons/social-media/';
$side-menu-icon-url: '../../assets/icons/side-menu/';
$profile-url: '../../assets/profile/';
$referral-icon-url: '../../assets/referral/icons/';

.menu {
  @include width-height(250px, 100vh);
  height: calc(var(--vh, 1vh) * 100);
  background-color: $dark-purple-1;
  border: none;
  border-radius: 0;
  
  .modal {
    &-body {
      @include padding-one-var();
    }
  }

  &-test {
    @include padding-two-var(14px, 20px);
    @include font-semi(14px);
    @include flex(flex-start, flex-start, column);
    gap: 10px;
    color: $white;
    border-bottom: $dark-blue-border;
    border-top: $dark-blue-border;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $purple-1;
    }

    &-button {
      @include padding-two-var(8px, 20px);
      @include font-semi(14px);
      cursor: pointer;
      color: $white;
      width: 100%;

      &:hover {
        background-color: $purple-1;
      }
    }

    &-box {
      @include flex(center, center, column);
      width: 100%;
      background-color: $dark-purple-1;
      border: $purple-border;
      border-radius: 8px;
      overflow: hidden;

      &-container {
        border-radius: 8px;
        width: 100%;
        border-bottom: $dark-blue-border;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &-container {
    padding-left: 0 !important;
    overflow: hidden;
    
    &.fade .modal-dialog {
      transform: translate(-100%, 0);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $loader-overlay-bg;
    
    &.show {
      opacity: 1;
    }
  }

  &-dialog {
    @include margin(0, auto, 0, 0);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 0 !important;
    max-width: 250px !important;
  }

  &-list {
    @include width-height(250px, 100vh);
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;

    &-profile {
      @include padding(0, 12px, 0, 20px);
      @include flex(flex-start, center, row);
      gap: 10px;
      height: 65px;
      border-bottom: $dark-blue-border;
      word-wrap: break-word;
      cursor: pointer;

      &-pic {
        @include icon-image('profile-pic', false, $profile-url);
        @include width-height-same(34px);
        @include background-position-size();
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
      }

      &-details {
        @include link($white, $white);
        @include flex(flex-start, center, row);
        width: calc(100% - 50px);
        margin-bottom: 0;
        cursor: pointer;

        &-name {
          @include font-medium(14px);
          display: inline-block;
          width: calc(100% - 24px);
          line-height: 1.4;
        }

        &-login {
          @include link($pink-1, $pink-1);

          &-btn {
            @include font-semi(12px);
            text-transform: uppercase;
            display: inline-block;
            width: calc(100% - 24px);
            text-align: right;
          }
        }
      }
    }

    &-items {
      @include flex(space-between, flex-start, column);
      height: calc(100vh - 64px);
      height: calc((var(--vh, 1vh) * 100) - 64px);
      overflow-x: hidden;
      overflow-y: auto;

      &-logged-in {
        height: calc(100vh - 129px);
        height: calc((var(--vh, 1vh) * 100) - 129px);
        overflow-x: hidden;
      }

      &-inner {
        @include padding-two-var(10px);
      }

      &-others {
        @include padding-two-var(10px);
        width: 100%;
        border-top: $dark-blue-border;
      }
    }
  }

  &-item {
    @include padding-two-var(10px, 20px);
    color: $white;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &-link {
      @include flex(flex-start, center, row);
      position: relative;
    }

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-icon {
      @include link($white, $white);
      @include width-height-same(24px);
      @include background-position-size(center, 24px);
      display: inline-block;
      vertical-align: middle;

      &-active {
        color: $pink-1;
      }

      &-quests {
        @include icon-image('quests', true, $side-menu-icon-url);

        &.active {
          @include icon-image('quests-active', true, $side-menu-icon-url);
        }
      }

      &-messages {
        @include icon-image('messages', true, $side-menu-icon-url);

        &.active {
          @include icon-image('messages-active', true, $side-menu-icon-url);
        }
      }

      &-spend {
        @include icon-image('spend', true, $side-menu-icon-url);

        &.active {
          @include icon-image('spend-active', true, $side-menu-icon-url);
        }
      }

      &-help-and-support {
        @include icon-image('help-and-support', true, $side-menu-icon-url);

        &.active {
          @include icon-image('help-and-support-active', true, $side-menu-icon-url);
        }
      }
      &-walkthrough {
        @include icon-image('tutorial', true, $side-menu-icon-url);

        &.active {
          @include icon-image('tutorial-active', true, $side-menu-icon-url);
        }
      }
    }

    &-name {
      @include font-medium(10px);
      @include padding-two-var(0, 10px);
      text-transform: uppercase;
      display: inline-block;
      width: 186px;
      color: $white;

      &-active {
        color: $pink-1;
      }
    }

    &-messages {
      @include link($white, $white);
      @include font-semi(10px);
      @include padding-two-var(0, 10px);
      background-color: $pink-1;
      border-radius: $button-border-radius;
      position: absolute;
      top: 5px;
      right: 0;
    }

    &-others {
      @include flex(space-between, center, row);

      &-name {
        @include padding-two-var(10px, 20px);
        @include link($light-purple, $light-purple);
        @include font-medium(10px);
        display: block;
      }

      &-partner-icon {
        @include icon-image('partner-with-us', true, $side-menu-icon-url);
        @include width-height-same(18px);
        @include background-position-size();
        @include margin-two-var(0px, 20px);
      }
    }
  }

  &-logout {
    @include padding-two-var(18px, 20px);
    @include link($pink-1, $pink-1);
    display: block;
    border-top: $dark-blue-border;
    cursor: pointer;

    &-icon {
      @include width-height-same(24px);
      @include icon-image('logout', true, $side-menu-icon-url);
      @include background-position-size();
      display: inline-block;
      vertical-align: middle;
    }

    &-text {
      @include font-medium(12px);
      @include padding-two-var(0, 5px);
      text-transform: uppercase;
      display: inline-block;
      width: 186px;
    }
  }

  &-referral {
    @include padding(5px, 20px, 0, 20px);
    width: 250px;

    &-button {
      @include refer-a-friend-button();
      @include padding-two-var(7px);
      margin-bottom: 20px;
      width: 100%;
      cursor: pointer;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &-icon {
      @include width-height-same(18px);
      @include icon-image('refer-a-friend', true, $referral-icon-url);
      @include background-position-size(center, 18px);
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    &-ad {
      @include margin-two-var(0, -20px);
      width: calc(100% + 20px);
      cursor: pointer;

      &-disabled {
        cursor: default;
      }

      &-image {
        width: inherit;
      }
    }
  }
}