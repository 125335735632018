@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.quests {
  border-top: none;
  padding-top: 0;

  &-grids {
    @include margin(0, -10px, 0, -10px);
  }

  &-grid {
    @include padding(0, 10px, 20px, 10px);

    &-inner {
      @include padding-one-var(20px);
      display: flex;
      height: 100%;
      background-color: $dark-purple-1;
      border: $purple-border;
      border-radius: $border-radius-mobile;

      &-rows {
        flex-direction: column;
      }

      &-columns {
        flex-direction: row;
      }
    }

    &-header {
      @include font-semi(14px);
      padding-bottom: 10px;
    }

    &-paragraph {
      @include font(14px);

      ul {
        margin-bottom: 0;
        padding-inline-start: 1em;
      }

      div {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-list {
    margin-bottom: -20px;

    @media (max-width: $max-mobile-width-sm) {
      margin-bottom: -30px;
    }

    &-item {
      @include margin-one-var();
      width: 100%;
      align-items: center;
    }

    &-xp {
      @include font-semi(18px);
      color: $pink-1;

      &-desktop {
        @include padding-one-var();
      }
    }

    &-quest {
      @include font(14px);
      @include padding(0, 20px);
    }

    &-completed {
      @include padding-one-var();

      &-button {
        @include purple-button();
        width: 100%;

        &-disabled {
          opacity: 0.5;
        }
      }
    }
  }

  &-leaderboards {
    @include font(14px);

    &-row {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-rank {
      text-align: left;
    }

    &-id {
      text-align: center;
    }

    &-xp {
      text-align: right;
    }

    &-table-header {
      @include font-semi(14px);
      color: $pink-1;
    }
  }
}