@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.pin-keyboard {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-header {
    margin-bottom: 30px;
  }

  &-texts {
    text-align: center;

    h1 {
      @include font-semi(18px);
      margin-bottom: 15px;
    }

    p {
      @include font-medium(14px);
      margin-bottom: 10px;
    }
  }

  &-indicators {
    display: block;
    text-align: center;
  }

  &-indicator {
    @include width-height-same(7px);
    @include margin-two-var(0, 5px);
    display: inline-block;
    background-color: $light-purple;
    border-radius: 50%;

    &-filled {
      background-color: $pink-1;
    }
  }

  &-keyboard {
    width: 231px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    position: relative;

    &-delete {
      @include position-float-bottom-right(12px, 15px);
      cursor: pointer;
    }
    
    &-key {
      @include font-semi(28px);
      @include padding-two-var(8px);
      color: $white;
      background-color: $dark-purple-1;
      border: $purple-border-thick;
      border-radius: $border-radius-mobile;

      &-0 {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
  }
}