@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';

.timer {
  transform: translate(10px, -24px);
  background-color: $purple-1;
  border-radius: 12px;
  border: 1px solid $white;
  max-width: 70px;
  p {
    @include font-bold(12px);
    margin: 0px;
    text-align: center;
  }
}