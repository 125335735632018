@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$daily-rewards-url: '../../../assets/daily-rewards/';

.daily-rewards {
  @include background-image('bg', $daily-rewards-url, '.png', false);
  @include padding-one-var(20px);
  @include margin-two-var(0, 30px);
  width: 100%;
  border: none;
  max-width: 356px;
  color: $white;
  background-color: transparent;
  border-radius: 16px;
  box-shadow: $glow-yellow-large;
  min-height: 390px;

  @media (max-width: $max-mobile-width-sm) {
    transform: translateY(40px);
  }

  &-close {
    @include position-float-top-right(-30px, -32px);
    @include width-height-same(26px !important);
    background: $white;
    color: $purple-1;
    border-radius: 36px;
    cursor: pointer;
    font-size: 10px !important;
  }

  &-claimed {
    @include width-height-same(100%);
    @include flex(space-between, center, column);
    @include padding(10px);
    gap: 10px;

    &-contents {
      width: 100%;

      &-image {
        max-width: 184px;
        max-height: 156px;
      }

      &-text {
        @include font-semi(24px);
      }

      &-subtext {
        @include font-light(14px);
        @include padding-two-var(0, 20px);
      }
    }
  }

  &-animated {
    width: 100%;
    max-width: 250px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-solo {
      transform: translate(-50%, -64%);
    }
  }

  &-body {
    @include flex(space-between, center, column);
    @include padding(20px, 0, 10px);
    width: 100%;
    text-align: center;
    max-width: 400px;
  }

  &-button {
    @include orange-button();
    width: 100%;
    max-width: 300px;
    cursor: pointer;
  }

  &-content {
    &-title {
      @include font-semi(18px);
      text-align: justify;
      text-justify: distribute;
      width: 250px;
    }
  }

  &-title {
    @include position-float-top-left(-122px, 50%);
    transform: translateX(-50%);
    max-width: 296px;
    width: 100%;
  }
  
  &-container {
    overflow: hidden;
    background: $desktop-toast-box-shadow-1;

    &.fade .modal-dialog {
      @include position-float-bottom-left(0, 0);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background: $desktop-toast-box-shadow-1;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100%, 100vh);
    max-width: none !important;
    justify-content: center;
  }
}