@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$discord-daily-boost-url: '../../../assets/discord-daily-boost/';

.discord-daily-boost {
  @include background-image('bg', $discord-daily-boost-url, '.png', false);
  @include padding-two-var(0px, 20px);
  @include margin-one-var();
  @include width-height(400px, 590px);
  border: none;
  color: $white;
  background-color: transparent;
  border-radius: 16px;

  @media (max-width: $max-mobile-width-sm) {
    @include width-height(350px, 500px);
  }

  @media (max-width: $max-mobile-width-xxs) {
    @include width-height(300px, 450px);
  }

  &-image {
    @include width-height(200px, auto);

    @media (max-width: $max-mobile-width-sm) {
      width: 150px;
    }
  }

  &-close {
    @include position-float-top-right(45px, 8px);
    @include width-height-same(26px !important);
    background: $white;
    color: $purple-1;
    border-radius: 36px;
    cursor: pointer;
    font-size: 10px !important;

    @media (max-width: $max-mobile-width-sm) {
      @include position-float-top-right(35px, 6px);
    }
  
    @media (max-width: $max-mobile-width-xxs) {
      @include position-float-top-right(30px, -5px);
    }
  }

  &-content {
    @include flex(center, center, column);
    text-align: center;
    width: 100%;
  }

  &-body {
    @include flex(space-between, center, column);
    @include padding(170px, 0px, 40px);
    @include margin-one-var();
    width: 100%;
    text-align: center;
    max-width: 400px;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(145px, 0px, 30px);
    }

    @media (max-width: $max-mobile-width-xxs) {
      padding-top: 135px;
    }
  }

  &-button {
    @include orange-button(12px);
    @include padding-one-var(12px);
    letter-spacing: 1px;
    width: 100%;
    max-width: 300px;
    cursor: pointer;

    @media (max-width: $max-mobile-width-xxs) {
      @include padding-one-var(10px);
    }
  }

  &-title {
    @include font-semi(24px);
    line-height: 1.3;
    text-align: center;
    margin-top: 10px;

    @media (max-width: $max-mobile-width-sm) {
      @include font-semi(18px);
      width: 89%;
    }

    @media (max-width: $max-mobile-width-xxs) {
      @include font-semi(16px);
    }
  }

  &-subtitle {
    @include font-light(14px);
    @include margin-two-var(10px);
    max-width: 90%;

    @media (max-width: $max-mobile-width-sm) {
      @include font-light(12px);
    }

    @media (max-width: $max-mobile-width-xxs) {
      @include font-light(10px);
    }
  }

  &-note {
    @include font-light(10px);
  }
  
  &-container {
    overflow: hidden;

    &.fade .modal-dialog {
      @include position-float-bottom-left(0, 0);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    &.show {
      opacity: 0.6;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100%, 100vh);
    max-width: none !important;
    justify-content: center;
  }
}