@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$launchpad-url: '../assets/launchpad/';

.qr-reader {
  @include padding-two-var(20px);
  color: $white;
  background-color: $dark-purple-1;
  width: 100%;
  max-width: 600px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0px 0px 30px $desktop-toast-box-shadow;

  @media (max-width: $max-mobile-width-sm) {
    box-shadow: none;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    background: $black;
  }

  &-arrow {
    @include position-float-top-left(50%, 0);
    @include width-height-same(36px !important);
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer
  }

  &-title {
    @include font-semi(18px);
    padding-top: 0;
    width: 100%;
  }

  &-header {
    padding-top: 0;
    border-bottom: none;

    @media (max-width: $max-mobile-width-sm) {
      @include position-float-top-left(0, 0);
      @include padding-one-var(20px);
      width: 100%;
      z-index: 100;
    }
  }

  &-container {
    display: flex !important;
    align-items: center;

    &.fade .modal-dialog {
      @media (max-width: $max-mobile-width-sm) {
        @include margin-one-var();
      }
    }
    
    &.show .modal-dialog {
      transform: translateY(0);
    }
  }
    
  &-bg {
    background-color: $black;
    opacity: 0.5;

    &.show {
      @media (max-width: $max-mobile-width-sm) {
        background-color: $dark-blue-4;
      }
    }
  }

  &-dialog {
    max-width: 600px;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(100%);
      max-width: 100%;
    }
  }

  &-body {
    @include flex(center, center, column);
    width: 100%;
    overflow: hidden;

    & > .scan-region-highlight{
      & > .scan-region-highlight-svg {
        stroke: rgba(255, 255, 255, .5) !important;
        stroke-dasharray: none !important;
      }
    }
  }

  &-input {
    display: none;
  }

  &-button {
    @include white-button();
    @include margin(20px);
    width: 100%;
    max-width: 400px;
    background-color: transparent;
    border: 1px solid $white;

    &-wrapper {
      width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        @include position-float-bottom-left(0, 0);
        @include padding-two-var(0, 20px);
        z-index: 100;
      }
    }
  }

  &-video {
    @include width-height(100%, inherit !important);
    position: relative;
    min-width: 400px;
    min-height: 200px;

    @media (max-width: $max-mobile-width-sm) {
      @include position-top-left(fixed, 0, 0);
      @include width-height(100vw, 100vh);
      min-width: 100%;
      min-height: 100%;
    }
  }
}