@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.payment-methods {
  @include padding-one-var(30px);

  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var(20px);
    height: calc(100vh - 126px);
    height: calc((var(--vh, 1vh) * 100) - 126px);

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &-inner {
    height: 100%;
  }

  &-arrow {
    @include position-float-top-right(50%, 15px);
    transform: translateY(-50%);
    cursor: pointer;
  }

  &-title {
    @include font-semi(16px);
    @include margin(30px);
  }

  &-active {
    @include font-semi(14px);
    @include margin(15px);
  }

  &-numbers {
    &-box {
      @include padding-one-var(20px);
      @include margin-two-var(0, -20px);
      border-bottom: $dark-blue-border;
    }

    &-wrapper {
      @include padding(20px);
    }

    &-arrow {
      @include position-float-top-right(50%, 0);
      transform: translateY(-50%);
      cursor: pointer;
    }

    &-title {
      @include font-semi(16px);
      margin-bottom: 10px;
    }

    &-container {
      @include padding-two-var(18px);
      position: relative;

      &:last-child {
        padding-bottom: 120px;
      }
    }

    &-text {
      @include font(14px);
      cursor: pointer;
    }

    &-button {
      @include purple-button();
      width: 100%;
      max-width: $max-layout-width;

      &-container {
        @include position-bottom-left(fixed, 60px, 0);
        @include padding(20px, 20px, 30px, 20px);
        @include flex(center, center, row);
        background-color: $dark-purple-1;
        width: calc(100% - 10px);

        @media (max-width: $max-mobile-width-sm) {
          @include padding-one-var(20px);
          width: 100%;
        }
      }
    }
  }

  &-button {
    @include position-float-bottom-left(0, 0);
    width: 100%;

    &-delete {
      @include position-float-top-right(0, 0);
      @include font-semi(16px);
      color: $red-2;
      cursor: pointer;
    }
  
    &-save {
      @include purple-button();
      width: 100%;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-input {
    @include padding(12px, 12px, 12px, 65px);
    @include font-light(14px);
    background-color: $dark-purple-7;
    outline: none;
    border-radius: 22px;
    border: none;
    width: 100%;
    color: $white;

    &-text {
      @include font-semi();
      color: $light-purple;
    }

    &-prefix {
      @include font-semi(14px);
      @include position-float-top-left(50%, 20px);
      transform: translateY(-50%);
      color: $white;
      border-right: $white-border-thick;
      padding-right: 7px;
    }

    &-box {
      @include flex(center, flex-start, column);
      width: 100%;
      gap: 10px;
    }
  }
  
  &-section {
    border: none;
    padding-top: 0;
  }

  &-box {
    @include margin-two-var(15px);
    @include padding(18px, 10px, 18px, 20px);
    background-color: $dark-purple-7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
  }

  &-content {
    @include flex(space-between, center, row);
    cursor: pointer;
  }

  &-wrapper {
    @include flex(flex-start, center, row);
    width: 100%;
    gap: 20px;

    &-box {
      @include flex(flex-start, center, row);
      max-width: 50px;
      width: 100%;
    }

    &-image {
      max-width: 100%;

      &-small {
        max-width: 33px;
      }
    }

    &-title {
      @include font-semi(14px);
    }

    &-description {
      @include font();
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    &-price {
      @include font(12px);
    }
  }
}