@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.mobile-app-toast {
  color: $white;
  text-align: center;
  padding-bottom: 60px;
  border-radius: 0;
  background: transparent;
  width: 100%;

  &-container {
    overflow: hidden;
    padding-left: 0 !important;
  
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-contents {
    @include flex(center, flex-start, row);
    gap: 10px;
  }

  &-button {
    @include white-button();
    @include padding-two-var(8px, 18px);
    color: $green-3;
    width: fit-content;

    &:hover {
      color: $green-3;
    }
  }

  &-close {
    @include position-float-top-right(16px, 10px);
  }

  &-wrapper {
    @include flex(center, center, column);
  }

  &-title {
    @include font-semi(16px);
    margin-right: 20px;
    text-align: left;
  }

  &-description {
    @include font(14px);
    margin-right: 20px;
    text-align: left;
  }

  &-texts {
    @include flex(flex-start, flex-start, column);
  }

  &-icon {
    max-width: 40px;
  }

  &-bg {
    background-color: transparent;
    z-index: 1;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-symbol {
    @include margin-one-var();
  }

  .modal {
    &-body {
      @include flex(center, center, row);
      background: $mobile-app-prompt-gradient;
      box-shadow: $tutorial-shadow;
      gap: 5px;
    }
  }
}