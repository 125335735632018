@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../../assets/icons/';
$profile-url: '../../assets/profile/';
$profile-menu-icons-url: '../../assets/icons/profile/';

.profile {
  &-info {
    &-pic {
      @include margin-two-var(0, auto);
      @include icon-image('profile-pic', false, $profile-url);
      @include width-height-same(80px);
      @include background-position-size();
      border-radius: 50%;
      position: relative;
    }

    &-wrapper {
      @include margin(-40px);
      @include padding(50px, 20px, 20px, 20px);
      background-color: $profile-info-bg;
      border-radius: 5px;
      word-wrap: break-word;
    }

    &-details {
      text-align: center;

      &-name {
        @include font-semi(16px);
        margin-bottom: 0;
      }

      &-edit-profile {
        @include font-medium(14px);
        @include link($light-purple, $light-purple);
      }
    }

    &-quest {
      margin-top: 10px;

      &-level {
        @include font-semi(16px);
      }

      &-progress {
        position: relative;

        &-bar {
          @include progress-bar();
          margin-top: 15px;
          margin-bottom: 15px;

          .progress-bar {
            background-color: $purple-1;
          }
        }

        &-tick {
          @include width-height-same(60px);
          @include icon-image('progress-bar-arrow', true, $icons-url);
          @include background-position-size(center, 60px);
          position: absolute;
          top: -27.5px;
        }
      }

      &-xp {
        @include font-medium(12px);
        width: 45%;
        display: inline-block;

        @media (max-width: $max-mobile-width-sm) {
          @include font-medium(10px);
        }
      }

      &-remaining {
        @include font-medium(12px);
        width: 55%;
        display: inline-block;
        color: $pink-1;
        text-align: right;

        @media (max-width: $max-mobile-width-sm) {
          @include font-medium(10px);
        }
      }
    }

    &-footnote {
      @include font-medium(12px);
      margin-top: 10px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-medium(10px);
      }

      a {
        @include link($white, $white);
        text-decoration: underline;
      }
    }
  }

  &-ad {
    @include width-height(100%, 120px);
    @include background-position-size();
    border-radius: 10px;
    display: block;
    margin-bottom: 10px;

    &-carousel {
      @include margin(20px);
    }
  }

  &-main-menu {
    @include margin(20px);

    &-header {
      @include font-medium(16px);
      @include margin-one-var(0);
      padding-bottom: 10px;
    }

    &-item {
      @include link($white, $white);
      @include padding-two-var(10px);
      @include font-medium(16px);
      display: block;
      position: relative;

      &-icon {
        @include width-height-same(24px);
        @include background-position-size();
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        &-transactions {
          @include icon-image('transactions', false, $profile-menu-icons-url);
        }

        &-quests {
          @include icon-image('quests', false, $profile-menu-icons-url);
        }

        &-linked-accounts {
          @include icon-image('linked-accounts', false, $profile-menu-icons-url);
        }

        &-invite-friends {
          @include icon-image('invite-friends', false, $profile-menu-icons-url);
        }

        &-report {
          @include icon-image('report', false, $profile-menu-icons-url);
        }

        &-security {
          @include icon-image('security', false, $profile-menu-icons-url);
        }
      }

      &-forward-icon {
        color: $grey-2;
        position: absolute;
        right: 0;
      }

      &-coming-soon {
        @include font-semi(12px);
        color: $pink-1;
        line-height: 24px;
        position: absolute;
        right: 40px;
      }
    }
  }
}