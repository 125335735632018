@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.stats {
  @include margin(30px, -10px, -10px);

  @media (min-width: $min-desktop-width-sm) {
    max-width: 790px;
  }

  &-fluid {
    @media (min-width: $min-desktop-width-sm) {
      max-width: none;
    }
  }

  &-no-top-space {
    margin-top: 0;
  }
}

.stat {
  @include padding(0, 10px, 10px);

  &-inner {
    @include padding-two-var(10px);
    border: $purple-border;
    border-radius: 10px;
    background-color: $dark-purple-1;
    text-align: center;
    height: 100%;
    @include flex(center, center, column);
  }

  &-value {
    @include font-semi(20px);
    color: $pink-1;
    
    @media (max-width: $max-mobile-width-xxs){
      @include font-semi(17px);
    }
  }

  &-label {
    @include font-medium(14px);
    color: $white;
  }
}