@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.withdrawal-result {
  &-container {
    @include flex(center, center, column);
    @include width-height-same(100%);
    text-align: center;

    @media (max-width: $max-mobile-width-sm) {
      height: 100vh;
    }
  }

  &-image {
    @include width-height-same(120px);
    margin-bottom: 10px;
  }

  &-title {
    @include font-semi(18px);
  }

  &-text {
    @include font-medium(12px);
    color: $light-purple;
  }

  &-button {
    @include purple-button(12px);
    width: 100%;

    &-back {
      @include font-bold(12px);
      background-color: transparent;
      border: none;
      width: 100%;
      color: $pink-1;
      margin-top: 20px;
    }

    &-container {
      @include position-float-bottom-left(80px, auto);
      @include padding-one-var(25px);
      width: 100%;
      max-width: 400px;

      @media (max-width: $max-mobile-width-sm) {
        @include position-float-bottom-left(0px, 0px);
        max-width: 100%;
      }
    }
  }
}