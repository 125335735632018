@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$nft-details-url: '../../assets/launchpad/';
$transactions-url: '../../assets/transactions/';
$gamepass-url: '../../assets/gamepass/';
$icons-url: '../../assets/icons/';
$wallet-referral-bg-url: '../../assets/referral/wallet/bg/';

.collection-list {
  @include grid();

  &-inner {
    height: 100%;
  }

  &-banner {
    @include flex(center, center, row);
    width: 100%;
    gap: 15px;
    padding-top: 20px;

    @media (max-width: $max-tablet-width) {
      @include flex(center, center, column);
      gap: 20px;
    }

    &-referral {
      @include flex(center, center, row);
      @include width-height(50%, 78px);
      @include background-image('desktop', $wallet-referral-bg-url, '.png', false);
      border-radius: $border-radius-mobile;
      position: relative;

      @media (max-width: $max-tablet-width) {
        @include width-height(100%, 78px);
      }

      @media (max-width: $max-mobile-width-sm) {
        @include width-height(100%, 70px);
        @include background-image('mobile', $wallet-referral-bg-url, '.png', false);
        width: 100%;
      }

      &-container {
        @include flex(center, flex-start, row);
        gap: 10px;
      }

      &-inner {
        @include padding-two-var(0, 20px);
        @include position-float-top-left(50%, 0);
        @include flex(space-between, center, row);
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;

        @media (min-width: $min-desktop-width-sm) and (max-width: $max-desktop-width-lg) {
          gap: 10px;
        }
      }

      &-text {
        @include width-height(65%, auto);
        max-width: 460px;

        @media (max-width: $max-tablet-width) {
          width: 60%;
          max-width: 460px;
        }

        @media (max-width: $max-mobile-width-sm) {
          width: 55%;
          max-width: 220px;
        }
      }

      &-button {
        @include padding-two-var(10px !important);
        @include white-button();
        @include link($purple-1, $purple-1);
        color: $purple-1;
        width: 100%;
        max-width: 130px;
      }
    }
  }

  &-redeem {
    @extend %wallet-action;

    &-rewards {
      @include icon-image('redeem-rewards', false, $transactions-url);
      @include width-height(70px, 70px);
      @include background-position-size(center, cover);
      margin-bottom: 10px;
    }

    &-title {
      @include font-semi(14px);
      text-align: center;
    }
  }

  &-buy {
    @extend %wallet-action;

    &-load {
      @include icon-image('buy-load', false, $transactions-url);
      @include width-height(70px, 70px);
      @include background-position-size(center, cover);
      margin-bottom: 10px;
    }

    &-title {
      @include font-semi(14px);
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &-redeem {
    cursor: pointer;
    margin-bottom: 30px;
    width: fit-content;
    margin-right: 15px;
    max-width: 70px;

    &-rewards {
      @include icon-image('redeem-rewards', false, $transactions-url);
      @include width-height-same(70px);
      @include background-position-size(center, cover);
    }

    &-title {
      @include font-medium(14px);
      text-align: center;
      max-width: fit-content;
    }
  }

  &.row {
    @include padding(0px, 2px, 10px, 0px);
  }

  &-header > h1 {
    margin-bottom: 5px;
  }

  &-image {
    border-radius: 10px;

    &-nft {
      @include margin-one-var();
      width: 100%;
      height: 0;
      background-position: center !important;
      background-size: cover !important;
      border-radius: 10px;
      padding-top: 100%;
      position: relative;
      z-index: 1;
    }

    &-indicator {
      @include padding-two-var(5px, 10px);
      @include font-semi(12px);
      @include position-float-top-right(0, 0);
      color: $white;
      background-color: $green-2;
      border-top-right-radius: $border-radius-mobile;
      border-bottom-left-radius: $border-radius-mobile;
      z-index: 1;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-one-var(5px);
        @include font-semi(11px);
      }
    }
  }

  &-transaction {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    &-body {
      @include padding-two-var(20px, 20px);
      background-color: $profile-info-bg;
      border-radius: 8px;
      position: relative;

      &:last-child {
        margin-bottom: 30px;
      }
    }

    &-activity {
      @include font-bold(15px);
      margin-bottom: 10px;
    }

    &-date {
      @include font(14px);
    }

    &-amount {
      @include font-bold(15px);
      color: $pink-1;
      position: absolute;
      top: 17px;
      right: 20px;
    }
  }

  &-wrapper {
    @include padding(30px, 0, 10px);
    position: relative;
    border-top: none;
  }

  &-button {
    @include font-medium(13px);
    @include position-float-top-right(32px, 0);
    color: $light-purple;
    background-color: transparent;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;

    &-history {
     top: 22px;
    }

    &:hover {
      color: $pink-1;
    }
  }

  &-item {
    @include padding(0px, 5px, 0px, 10px);

    &-name {
      @include padding(10px);
      text-align: center;
      width: calc(100% - 0px);
    }
  
    &-count {
      color: $pink-1;
    }

    &-link {
      margin-left: 12px;
      cursor: pointer;
      
      &:hover {
        .collection-list {
          &-image {
            box-shadow: $glow-purple;
          }

          &-item {
            &-name {
              color: $purple-6;
            }

            &-image {
              box-shadow: $glow-purple;
            }
  
            &-wrapper {
              &-name {
                color: $purple-6;
              }
            }
          }
        }
      }
    }

    &-wrapper {
      @include flex(flex-start, flex-start, column);
      @include margin(10px);
      gap: 5px;
  
      &-title {
        @include font();
        color: $light-purple;
      }
  
      &-name {
        @include font-semi(14px);
      }
  
      &-mgc {
        @include icon-image('mgo-coin', true, $icons-url);
        @include width-height-same(13px);
        @include background-position-size(center, contain);
      }
  
      &-price {
        @include font-semi(14px);
  
        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(12px);
        }
      }
    }
  }

  &-empty {
    @include font(14px);
  }
}

.wallet {
  @include padding(20px !important);
  position: relative;
  border-top: none;

  &-empty {
    @include flex(center, center, column);
    @include font(14px);
    @include width-height(100%, 130px);
    margin-top: 20px;
    background-color: $light-purple-translucent;
    border-radius: 8px;
  }
}

.wallet-modal {
  background-color: transparent;
  border: none;

  &-image {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background-color: $white;
  }

  &-close {
    &-button {
      @include purple-button();
      width: 100%;
    }
  }

  &-show {
    @include font-semi(14px);
    padding-bottom: 10px;
    color: $white;
  }

  &-body {
    @include flex(center, flex-start, column);
    border-top: 1px solid $grey-13;
    padding-top: 20px;
    width: 100%;
    gap: 10px;

    &-title {
      @include font-semi(14px);
      color: $grey-13;
    }

    &-subtitle {
      @include font-semi(14px);
      color: $white;
      text-align: right;
    }
  }

  &-container {
 
    &.fade .modal-dialog {
      transform: scale(0.2);
    }

    &.show .modal-dialog {
      transform: scale(1);
    }
  }

  &-dialog {
    @include flex(center, center, row);
    max-width: 600px;
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  .modal {
    &-header {
      @include font-semi(18px);
      padding: 0px;
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include font(16px);
      padding: 0px;
      background-color: $dark-purple-1;
      border-radius: 12px;
      border: 1px solid $purple-1;
    }

    &-footer {
      @include padding(10px);
      justify-content: center;
      border-top: none;
    }
  }
}

.gamepass-inventory {
  @include padding-one-var(20px);
  background-color: $purple-7;

  @media (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
    @include padding-two-var(20px, 30px);
    @include margin-two-var(0, -30px);
  }

  @media (max-width: $max-mobile-width-sm) {
    @include margin-two-var(0, -20px);
  }

  &-empty {
    @include flex(space-between, center, row);
    @include padding-one-var(10px);
    background: $light-purple-translucent;
    border-radius: 8px;

    @media (max-width: $max-tablet-width) {
      @include flex(center, center, column); 
      @include margin-two-var(10px);
    }

    &-text {
      @include font-light(14px);
      @include padding-one-var();
      @include margin-two-var(5px, 10px);
    }

    &-button {
      @include purple-button-reverse(12px);
      @include padding-two-var(10px);
      @include margin-one-var();
      width: 100%;
      max-width: 200px;
      text-transform: uppercase;

      @media (max-width: $max-tablet-width) {
        width: 100%;
        max-width: 150px;
      }
    }
  }

  &-title {
    @include font-semi(18px);
    color: $white;

    &-container {
      @include flex(space-between, center, row);
    }
  }

  &-passes {
    @include flex(space-between, center, row);
    @include font-semi(14px);
    @include padding-two-var(5px);
    @include margin-two-var(10px);
    @include width-height(100%, max-content);
    border-radius: 8px;
    text-align: left;

    &-container {
      @include flex(center, center, row);
    }

    &-ticket {
      @include margin(8px, 20px);
      max-width: 50px;
      max-height: 42px;
    }

    &-text {
      @include padding-one-var();
      @include margin-one-var();
      @include font-semi(14px);
      margin-top: 5px;

      &-container {
        @include flex(center, flex-start, column);
      }
    }

    &-quantity {
      @include padding-one-var();
      @include margin-one-var();
      @include font(14px);
      color: $light-purple;
    }

    &-button {
      @include purple-button-reverse(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 200px;

      @media (max-width: $max-tablet-width) {
        width: 100%;
        max-width: 12vh;
      }
    }
  }

  &-view-all {
    @include font-semi(14px);
    color: $light-purple;
    margin-right: 10px;
  }
}

.transactions {
  &-tabs {
    width: 100%;

    & > .rb-tabs-header {
      @include position-top-right(sticky, -32px, 0);
      border-bottom: 2px solid $dark-blue-7 !important;
      background-color: $dark-purple-1;
      z-index: 5;

      @media (min-width: $max-tablet-width) {
        @include flex(center, center, row);
        gap: 20px;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include margin(0, 0, 10px, 0);
        top: 63px;
      }

      & > .rb-tabs-items  {
        & > .rb-tabs-item.active {
          font-weight: 600 !important;
        }
      }
    }

    & > .rb-tabs-content {
      @include padding-two-var(20px);
    }

    &-content > .rb-tabs-content {
      @media (max-width: $max-mobile-width-xs) {
        @include padding-one-var(20px);
      }
    }

    &-indicator {
      background-color: $pink-1 !important;
      height: 3px !important;
      bottom: -2.5px !important;
    }

    &-lists {
      border-bottom: none !important;
      width: 100%;
    }
  }

  &-center-tab {
    &-title {
      @include font(14px); 
      @include padding-two-var(10px !important, 16px !important);
      width: 100%;
      text-align: center;

      & > .active {
        @include font-semi(14px);
      }
    }
  }
}
