@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$onboarding-url: '../../../assets/onboarding/';
$launchpad-url: '../../../assets/launchpad/';

.onboarding-tutorial {
  transform: translateY(-20px);
  position: fixed;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  z-index: 10;
  
  &-box {
    @include padding-one-var(14px);
    @include font-semi(16px);
    @include flex(center, center, row);
    @include position-float-top-right(-76px, 48px);
    border-radius: 4px;
    background-color: $white;
    width: 100%;
    max-width: 200px;
    color: $purple-1;

    &-desktop {
      left: 50%;
      transform: translateX(-50%);
    }

    &-games {
      left: 50%;
      transform: translateX(-50%);
    }

    &-arrow {
      &::after {
        content: '';
        @include position-float-bottom-right(-28px, -26px);
        @include width-height-same(0);
        @include margin-two-var(0, auto);
        border-top: 15px solid $white;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
      }

      &-games {
        &::after {
          content: '';
          @include position-float-bottom-left(-28px, -24px);
          @include width-height-same(0);
          @include margin-two-var(0, auto);
          border-top: 15px solid $white;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
        }
      }
    }
  }

  &-popup {
    @include icon-image('bg', false, $onboarding-url);
    @include width-height-same(100%);
    @include background-position-size(center, cover);
    @include margin-one-var();
    @include padding(10px, 24px, 5px, 24px);
    @include flex(center, center, column);
    color: $white;
    border-radius: 16px;
    border: none;
    max-width: 340px;
    
    &-header-close {
      @include position-float-top-right(-12px, -12px);
      @include width-height-same(26px !important);
      background: $white;
      color: $purple-1;
      border-radius: 36px;
      cursor: pointer;
      font-size: 10px !important;
    }

    &-button {
      &-play {
        @include orange-button();
        width: 100%;
      }

      &-explore {
        @include orange-button-border();
        width: 100%;
      }
    }

    &-image {
      max-width: 142px;
    }

    &-title {
      @include font-semi(24px);
      @include padding-two-var(0, 10px);
      text-align: center;
    }
    
    &-subtitle {
      @include font-light(14px);
      text-align: center;
    }

    &-container {
      @include flex(center, center, column);
      gap: 10px;

      &.fade .modal-dialog {
        @include padding-two-var(0px, 25px);
        @include flex(center, center, column);
      }
  
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
  
    &-bg {
      background-color: $black;

      &.show {
        opacity: 0.5;
      }
    }
  
    &-header {
      @include font-medium(12px);
      @include flex(flex-end, center, row);
      @include padding(0px, 20px);
      color: $grey-6;
      border-bottom: none;
      cursor: pointer;
    }
  
    &-dialog {
      @include flex(center, center, row);
    }
  
    .modal {
      &-body {
        @include padding-two-var(20px);
        @include flex(center, center, column);
        width: 100%;
      }
    }
  }
}