@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.floating-container {
  @include width-height-same(max-content);
  @include position-bottom-right(fixed, 65px, 20px);
  @include flex(flex-end, flex-end, column);
  z-index: 1;

  @media (max-width: $max-mobile-width-sm) {
    right: 10px;
  }
}