@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.gallery {
  @include margin-two-var(-10px, -10px);

  &-scroll {
    .slick-prev, .slick-next {
      &:before {
        margin-top: 0;
      }
    }
  }
}

.screenshot {
  @extend %gallery;
  @include padding-one-var(10px);
  position: relative;
  border: none;
  outline: none;

  &-image {
    @include margin-one-var();
    @include background-position-size();
    width: 100%;
    padding-top: 60%;
    border-radius: $border-radius-mobile;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &-game {
      padding-top: 158%;
    }
  }
}

.game {
  &-inner {
    .featured-section {
      @media (max-width: $max-mobile-width-sm) {
        margin-top: 20px;
      }
  
      &-fluid {
        @media (max-width: $max-mobile-width-sm) {
          margin-top: 20px;
        }
      }
    }
  }

  &-sections {
    border-top: $dark-blue-border;
  }

  &-label {
    @include font-semi(14px);

    @media (max-width: $max-mobile-width-sm) {
      display: inline-block;
      vertical-align: top;
      width: 35%;
    }
  }

  &-value {
    @include font-light(14px);

    @media (max-width: $max-mobile-width-sm) {
      display: inline-block;
      vertical-align: top;
      width: 65%;
      text-align: right;
    }
  }
}