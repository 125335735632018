@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$url: '../../../assets/';

.content {
  @include padding-one-var(30px);
  height: 100%;
  overflow-y: auto;

  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var(20px);
  }

  &-inner {
    @include margin-two-var(0, auto);
    position: relative;
    max-width: $max-layout-width;
  }
}