@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.buy-gamepass {
  background-color: $dark-pink-3;
  background-image: $wen-lambo-list-page-gradient;

  .rts {
    &___tabs {
      @include padding-two-var(10px, 30px);
      gap: 10px;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-two-var(10px, 20px);
      }

      &___container {
        @include padding-one-var();
        @include margin-two-var(0, -30px);

        @media (max-width: $max-mobile-width-sm) {
          @include margin-two-var(0, -20px);
        }
      }
    }

    &___btn {
      background-color: $light-purple-translucent;
      border: $transparent-border;
    }

    &___tab {
      &___selected {
        border: $white-border;
      }
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;

    &-sticky {
      .rts___tabs___container {
        @include position-top-left(fixed, 64px, 30px);
        width: 100%;
        z-index: 1;
        background-color: $dark-purple-11;

        @media (max-width: $max-mobile-width-sm) {
          left: 20px;
        }
      }
    }
  }

  &-tab {
    justify-content: space-between;
    border-bottom: none !important;

    @media (min-width: $min-tablet-width) {
      gap: 20px;
    }

    &-title {
      @include font-bold(12px); 
      @include padding-two-var(2px !important, 10px !important);
      @include margin-one-var(0 !important);
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      color: $white;
    }

    &-icon {
      height: 30px;
      margin-right: 5px;
    }
  }

  &-contents {
    position: relative;

    &-sticky {
      margin-top: 76px;
    }
  }

  &-content {
    @include padding-one-var();

    &-box {
      @include padding-one-var(10px);
      position: relative;
    }

    &-recommended-badge {
      @include game-pass-recommended-badge(0, 0);
    }

    &-new-badge {
      @include game-pass-recommended-badge(0, 0, $free-spin-gradient);
    }

    &-thumbnail {
      display: block;
      border-radius: $border-radius-mobile;

      &::before {
        @include wen-lambo-thumbnail-border(9px);
        z-index: 0;
      }

      &:hover {
        box-shadow: $glow-yellow;
      }

      &-new {
        &::before {
          background: $free-spin-gradient !important;
        }
  
        &:hover {
          box-shadow: $glow-teal !important;
        }
      }
    }

    &-wrapper {
      @include flex(flex-start, center, column);
      @include margin-one-var();
      @include padding-two-var(15px, 10px);
      background-color: $dark-purple-10;
      border: none;
      border-radius: $border-radius-mobile;
      text-align: left;
      width: 100%;
      gap: 20px;
      position: relative;

      &-recommended {
        gap: 30px;
      }
    }

    &-title {
      @include font-semi(16px);
      @include padding-two-var(20px);
    }
 
    &-image {
      width: 100%;
      max-height: 100%;

      &-wrapper {
        @include flex(center, center, row);
        height: 17.5vw;
        max-height: 230px;

        @media (max-width: $max-desktop-width-lg) {
          height: 22.5vw;
          max-height: 280px;
        }

        @media (max-width: $max-mobile-width-sm) {
          height: 30vw;
        }

        @media (max-width: $max-mobile-width-xs) {
          height: 45vw;
        }
      }

      &-recommended {
        border-radius: 4%;
      }
    }

    &-info {
      @include flex(flex-start, center, column);
      width: 100%;
      gap: 15px;
      cursor: pointer;

      &-recommended {
        gap: 10px;
      }

      &-details {
        @include link($white, $white);
        @include flex(flex-start, flex-start, column);
        width: 100%;
        gap: 3px;
        cursor: pointer;

        &-height {
          height: 66px;
        }
      }
    }

    &-name {
      @include font-semi(14px);
    }

    &-button {
      @include orange-button();
      @include padding-two-var(10px);
      width: 100%;
      cursor: pointer;

      &-new {
        @include purple-blue-button();
        @include padding-two-var(10px);
      }

      &-wrapper {
        @include flex(center, center, row);
        width: 100%;
        gap: 10px;
      }

      &-cart {
        @include width-height-same(38px);
        border-radius: 38px;
        cursor: pointer;
      }
    }
  }
}