@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.pin-form {
  @include padding(30px);
  margin-bottom: 114px;
  width: 100%;
  flex: 0 0 80%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    @include font-semi(18px);
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    @include font-medium(14px);
    @include margin-two-var(0, auto);
    text-align: center;
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
  }

  &-row {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  &-label {
    @extend %form-label;
  }

  &-group {
    @include margin(10px);
    @extend %form-input;

    &-error {
      outline: $pink-border;
    }
  }

  &-hint {
    @include font(12px);
    @include margin(10px);
  }

  &-input {
    @include font(16px);
    @include padding-one-var();
    width: calc(100% - 34px);
    color: $white;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &-password-reveal {
    @include padding-one-var();
    margin-left: 10px;
    color: $white;
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  
  &-submit {
    @include margin(auto, 0, 10px);
    @include purple-button();
    width: 100%;
    max-width: 400px;

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-button {
    @include flex(center, center, column);
    @include margin-two-var(30px, 0);
    @include position-bottom-left(fixed, 0, 0);
    @include padding-two-var(0, 20px);
    width: 100%;

    @media (max-width: $max-mobile-width-sm){
      @include margin(20px);
    }
  }

  &-dismiss {
    @include purple-button-border();
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
  }
}