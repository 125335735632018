@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.share-popup {
  @include flex(center, center, column);
  color: $white;
  background-color: $grey-5;
  border-radius: $border-radius-mobile $border-radius-mobile 0 0;
  text-align: center;

  &-image {
    height: 100%;

    &-share {
      @include width-height-same(45px);
    }
  }

  &-button {
    background-color: transparent;
    border: none;
    outline: none;
  }

  &-content {
    @include flex(flex-start, center, row);
    width: 100%;
    max-width: $max-mobile-width-xs;
    gap: 10px;

    @media (max-width: $max-mobile-width-sm) {
      max-width: $max-button-width-mobile;
    }
  }

  &-details {
    @include flex(center, flex-start, column);
    width: calc(100% - 55px);

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 90px);
    }
  }

  &-title {
    @include font-semi(16px);
    @extend %text-ellipsis;
    color: $black;
    text-align: left;
    width: 100%;
  }

  &-desc {
    @include font-medium(14px);
    color: $grey-1;
    text-align: left;
  }

  .modal {
    &-header {
      @include padding-one-var(20px);
      @include font-semi(18px);
      @include flex(center, center, row);
      color: $grey-1;
      width: 100%;

      .btn-close {
        @include position-float-top-right(20px, 20px);
        @include margin-one-var();
        @include padding-one-var(5px);
        @include background-position-size(center, 12px);
        background-color: $grey-8;
        border-radius: 50%;
      }

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include padding-one-var(20px);
      @include font(16px);
      @include flex(center, center, row);
      gap: 15px;
      width: 100%;
      max-width: 440px;
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      justify-content: center;
      border-top: none;
    }
  }

  &-wrapper {
    padding-left: 0 !important;
    overflow: hidden;
    
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }
}