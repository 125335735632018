@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.popup-menu {
  color: $white;
  background-color: $dark-blue-7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  
  &-container {
    &.fade .modal-dialog {
      @include position-float-bottom-left(0px,0px);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-header {
    @include font-medium(12px);
    @include flex(flex-end, center, row);
    @include padding(10px, 20px);
    color: $grey-6;
    border-bottom: none;
    cursor: pointer;
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  .modal {
    &-body {
      @include padding-two-var(5px);
      @include flex(center, center, column);
    }
  }
}