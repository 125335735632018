@import './colors.scss';
@import './fonts.scss';
@import './mixins.scss';
@import './settings.scss';

%gallery {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;

  @media (max-width: $max-tablet-width) {
    margin-right: 15px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    cursor: pointer;
  }
}

%mobile-number-prefix {
  color: $white;
  width: 50px;
  margin-right: 5px;
  padding-right: 5px;
  border-right: $mobile-number-prefix-border;
}

%chat-grey-text {
  @include font-medium(12px);
  color: $grey-3;
}

%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%horizontal-scroll-button {
  @include width-height(50px, 100%);

  &:before {
    @include margin(-15px, auto, 0, auto);
    @include width-height-same(30px);
    @include background-position-size(center, 15px);
    display: block;
    border: $white-border;
    border-radius: 50%;
    opacity: 1;
    content: '';
  }

  &.slick-disabled {
    background: transparent !important;

    &:before {
      opacity: 0;
    }
  }
}

%form-input {
  @include padding-two-var(10px, 20px);
  @include font(16px);
  width: 100%;
  color: $white;
  background-color: $dark-purple-2 !important;
  border: none !important;
  border-radius: $button-border-radius;
}

%form-label {
  @include font-medium(12px);
  display: block;
  color: $light-purple;
}

%otp-pin-header {
  color: $white;

  .back {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  span {
    @include font-semi(18px);
  }
}

%wallet-action {
  @include link($white, $white);
  @include flex(center, center, column);
  cursor: pointer;
  margin-bottom: 30px;
  width: 70px;
}