@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$launchpad-url: '../../assets/launchpad/';
$icons-url: '../../assets/icons/';

.nft-withdrawal {
  &-section {
    border-top: none;
    padding: 0;
  }

  &-outer {
    padding-bottom: 0;
  }

  &-details {
    &-container {
      @include flex(flex-start, center, row);
      @include padding-one-var(20px);
      @include margin(30px);
      background-color: $dark-purple-7;
      width: 100%;
      border-radius: 8px;
    }

    &-image {
      @include width-height-same(77px);
    }

    &-text {
      @include font-semi(14px);
      @include padding-one-var();
      @include margin-one-var();

      &-container {
        @include flex(flex-start, flex-start, column);
        margin-left: 20px;
      }
    }

    &-title {
      @include font(14px);
      @include padding-one-var();
      @include margin-one-var();
      color: $light-purple;
    }
  }

  &-title {
    @include font-semi(14px);
    @include padding-one-var();
    @include margin-one-var();
    margin-top: 20px;
  }

  &-network {
    &-text {
      @include flex(flex-start, flex-start, center);
      @include font(14px);
      margin-top: 20px;
    }
    
    &-icon {
      @include width-height-same(26px);
      margin-top: -2px;
      margin-right: 10px;
    }
  }

  &-input {
    background-color: transparent;
    border: none;
    color: $light-purple;
    width: 100%;
    max-width: 250px;
    resize: none;
    height: 40px;

    &:focus{
      border: none;
      outline: none;
    }

    &-container {
      @include padding(12px, 60px, 12px, 20px);
      @include font(14px);
      @include flex(space-between, center, row);
      height: 42px;
      width: 100%;
      background-color: $light-purple-translucent;
      border: none;
      border-radius: 8px;
      margin-top: 15px;
      vertical-align: center;
      resize: none;
      outline: none;
      color: $light-purple;
    }
  }

  &-qr {
    @include width-height-same(18px);
    @include icon-image('qr', true, $launchpad-url);
    @include background-position-size(center, cover);
    @include position-float-top-right(50%, 20px);
    transform: translateY(-50%);
    cursor: pointer
  }

  &-notes {
    &-container {
      @include padding-two-var(20px, 18px);
      @include flex(flex-start, flex-start, column);
      background-color: $confirm-widthrawal-bg;
      border: 1px solid $purple-9;
      border-radius: 8px;
      margin-top: 20px;
    }

    &-title {
      @include font-semi(14px);
      @include padding-one-var();
      @include margin-one-var();
    }

    &-text {
      @include font(14px);
      @include padding-one-var();
      @include margin-one-var();
    }
  }

  &-gas {
    &-container {
      @include padding-two-var(17px, 15px);
      background-color: $dark-purple-6;
      margin-top: 40px;
      border-radius: 8px;
    }

    &-text {
      @include font(14px);

      &-container {
        @include flex(space-between, center, row);
      }
    }

    &-mgc {
      @include flex(flex-start, flex-start, row);
      @include font(14px);
      margin-top: -15px;

      &-icon {
        @include width-height-same(15px);
        @include icon-image('mgo-coin', true, $icons-url);
        @include background-position-size();
        @include margin(2px, 5px);
      }
    }

    &-button {
      @include purple-button(12px);
      width: 100%;

      &-disabled {
        opacity: 0.5;
        cursor: default !important;
      }
    }
  }

  &-popup {
    @include padding-two-var(20px);
    color: $white;
    background-color: $dark-purple-1;
    width: 100%;
    max-width: 760px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 0px 30px $desktop-toast-box-shadow;
      
    @media (max-width: $max-tablet-width) {
      width: 100%;
      max-width: 500px;
    }
        
    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var();
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      background-color: $dark-blue-7;
    }

    &-title {
      @include font-semi(24px);
      margin-bottom: 10px;
    }

    &-text {
      @include padding-one-var();
      @include font-light(16px);
      max-width: 522px;
 
      @media (max-width: $max-tablet-width) {
        max-width: 380px;
      }
      
      @media (max-width: $max-mobile-width-sm) {
        @include flex(center, center, column);
        @include font-light(14px);
        border-top: none;
        @include padding-one-var();
        @include margin-one-var();
      }
    }
    
    &-button {
      @include purple-button(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 400px;
      margin-right: 15px;
    }

    &-footer {
      @include flex(center, center, row);
      border-top: none;
      padding-top: 0px;
    }
            
    &-container {
      overflow: hidden;
    
      &.fade .modal-dialog {
        @include flex(center, center, column);
        @include position-float-bottom-left(50%, auto);
        
        @media (max-width: $max-tablet-width) {
          @include position-float-bottom-left(0px, 0px);
          transform: translateY(10vh);
        }
      }
    
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
    
    &-bg {
      background-color: $black;
    
      &.show {
        opacity: 0.5;
      }
    }
    
    &-header {
      @include font-semi(18px);
      @include flex(center, center, row);
      border-bottom: none;
      cursor: pointer;
    
      &-close {
        @include position-float-top-right(15px, 15px);
        @include width-height-same(1.2em !important);
          color: $white;
      }
    }
    
    &-dialog {
      @include margin-one-var();
      width: 100vw;
    
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
    
    .modal {
      &-body {
        @include padding-one-var(20px);
        @include flex(center, center, column);
      }
    }
  }

  &-otp {
    @include flex-page(space-between);
    @include padding(20px);
    width: 100%;
    overflow: hidden;
  }
}