@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.gamepass-play {
  &-inner {
    height: 100%;
  }

  &-web-gl {
    @include flex(center, center, column);
    @include width-height(100%, 92%);

    @media (max-width: $max-mobile-width-sm) {
      @include position-top-left(fixed, 50%, 50%);
      transform: translate(-50%, -50%);
    }

    &-wrapper {
      max-width: 400px;
    }

    &-body {
      @include flex(center, center, column);
      margin-top: -70px;
    }

    &-box {
      width: 100%;
    }

    &-main {
      width: 100%;
      max-width: 208px;
    }

    &-title {
      @include font-semi(18px);
      @include padding-two-var(0, 40px);
      text-align: center;
      margin-bottom: 15px;
    }

    &-subtitle {
      @include font-light(14px);
      @include padding-two-var(0, 40px);
      text-align: center;
    }
  }

  &-container {
    @include width-height(100%, 92%);
    position: relative;
  }

  &-section {
    height: 100%;
    padding-top: 0;
    border: none;
    overflow: hidden;
      
    &-back {
      &-text {
        @include font-medium(14px);
        color: $white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover{
          color: $white;
        }
      }
    
      &-icon {
        color: $white;
        margin-right: 5px;
      }
    
    }
  }

  &-iframe {
    @include position-float-top-left(0, 0);
    @include width-height-same(100%);

    @media (max-width: $max-mobile-width-sm) {
      @include position-top-left(fixed, 50%, 50%);
      transform: translate(-50%, -50%);
      max-height: 100%;
    }
  }
}
