@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.transaction-details-modal {
  background-color: transparent;
  border: none;

  &-image {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background-color: $white;
  }

  &-close {
    &-button {
      @include purple-button();
      width: 100%;
    }
  }

  &-show {
    @include font-semi(14px);
    padding-bottom: 10px;
    color: $white;
  }

  &-body {
    @include flex(center, flex-start, column);
    border-top: 1px solid $grey-13;
    padding-top: 20px;
    width: 100%;
    gap: 10px;

    &-title {
      @include font-semi(14px);
      color: $grey-13;
    }

    &-subtitle {
      @include font-semi(14px);
      color: $white;
      text-align: right;
    }
  }

  &-container {
 
    &.fade .modal-dialog {
      transform: scale(0.2);
    }

    &.show .modal-dialog {
      transform: scale(1);
    }
  }

  &-dialog {
    @include flex(center, center, row);
    max-width: 600px;
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  .modal {

    &-header {
      @include font-semi(18px);
      padding: 0px;
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include font(16px);
      padding: 0px;
      background-color: $dark-purple-1;
      border-radius: 12px;
      border: 1px solid $purple-1;
    }

    &-footer {
      @include padding(10px);
      justify-content: center;
      border-top: none;
    }
  }
}