@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.game-pass-terms-section {
  @include margin(30px, auto, 0, auto);
  width: 100%;
  max-width: $min-tablet-width;

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
  }

  &-list {
    margin-bottom: 0 !important;
  }

  &-item {
    margin-bottom: 0px;

    p {
      @include margin(20px);
    }

    ol {
      @include margin-two-var(10px);
      counter-reset: section;
      padding-left: 0;
  
      li {
        &:last-child {
          margin-bottom: 0;
        }
  
        &::before {
          counter-increment: section;
          content: counter(section, lower-alpha) "." !important;
          width: 30px;
        }
      }
    }
  }
}