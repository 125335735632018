@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.single-action-confirm {
  color: $white;
  background-color: $dark-purple-2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &-container {
    &.fade .modal-dialog {
      @include position-float-bottom-left(0px,0px);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-button {
    @include purple-button();
    width: 100%;
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }

      .btn-close {
        @include margin(-0.5rem, -0.5rem, -0.5rem, 0);
        position: absolute;
        right: 30px;
      }
    }

    &-body {
      @include padding-two-var(20px, 30px);
      @include font(14px);
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      border-top: none;
    }
  }
}