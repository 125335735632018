@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';

.loader {
  @include width-height(100vw, calc(100vh - 125px));
  height: calc((var(--vh, 1vh) * 100) - 125px);
  position: absolute;
  top: 64px;
  background-color: $loader-overlay-bg;
  z-index: 2000;

  @media (max-width: $max-mobile-width-sm){
    position: fixed;
  }

  &-bg {
    background-color: transparent;
  }

  &-no-bottom {
    height: calc(100vh - 64px);
    height: calc((var(--vh, 1vh) * 100) - 64px);
  }

  &-full {
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  &-wrapped {
    @include width-height-same(80px);
    @include position-top-left(relative, 0, 0);
  }

  &-animation {
    @include position-float-top-left(50%, 50%);
    transform: translate(-50%, -50%);
    
    &-no-bottom {
      top: calc(50% - 30.5px);
    }

    &-wrapped {
      @include position-top-left(relative, 0, 0);
      transform: none;
    }
    
    &-logo {
      height: 80px;
    }

    &-text {
      @include font-semi(14px);
      text-align: center;
      white-space: pre;
    }
  }
}