@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$spend-icon-url: '../assets/spend/';
$icons-url: '../assets/icons/';

.spend {
  &-header {
    @include font-semi(20px);
    text-align: center;
    margin-bottom: 20px;
  }

  &-otp {
    &-show {
      opacity: 1;
    }

    &-hide {
      opacity: 0;
    }
  }

  &-authentication {

    &-back {
      cursor: pointer;
    }

    &-message {
      @include margin-two-var(30px, auto);
      @include padding-one-var(20px);
      width: 100%;
      max-width: 600px;
      text-align: center;
      background-color: $profile-info-bg;
      border-radius: 5px;

      &-title {
        @include font-semi(18px);
      }

      &-description {
        @include font(16px);
        margin-bottom: 0;
      }
    }

    &-otp {
      @include margin-two-var(0, auto);
      width: 100%;
      max-width: 400px;
    }
  }

  &-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  &-first-step, &-second-step, &-third-step, &-otp-step{
    flex: 0 0 100%;
    transition: .3s ease-in-out;

    &-inner {
      @include margin-two-var(0, auto);
      display: block;
      width: 100%;
      max-width: 400px;
    }
  }

  &-form {
    &-group {
      background: none;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-label {
      @include font-medium(14px);
      width: 100%;
    }
  }

  &-country-code-dropdown {
    max-width: 400px;
  }

  &-mobile-number {
    @include padding-one-var();
    display: inline-block;
    width: 100%;
    color: $white;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &-prefix {
      @extend %mobile-number-prefix;
    }

    &-container {
      @include font(16px);
      @include padding-two-var(10px, 20px);
      @include margin(15px, 0, 0, 1px);
      width: calc(100% - 2px);
      border-radius: $button-border-radius;
      background-color: $dark-purple-2;
    }

    &-error {
      outline: $pink-border;
    }

    &-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &-coming-soon {
    @include margin(30px, auto, 0, auto);
    @include font-medium(16px);
    text-align: center;
    width: 100%;
    max-width: 350px;
  }
}

.validation-error {
  @include font-medium();
  color: $pink-1;
}

.payment-method {
  @include padding-two-var(10px, 20px);
  margin-top: 10px;
  background-color: $dark-purple-1;
  border-radius: 5px;

  img {
    @include width-height-same(25px);
    margin-right: 10px;
  }

  .wallet {
    &-currency {
      @include font(16px);
      display: inline-block;
      width: 100px;
    }

    &-balance {
      @include font(14px);
      display: inline-block;
      width: calc(100% - 135px);
      text-align: right;
    }
  }
}

.amount {
  &-options {
    @include margin(10px, -10px, 0, -10px);
    display: grid;
    grid-template-columns: auto auto;

    &-item {
      @include padding-one-var(10px);
      cursor: pointer;
    }
  }

  &-details {
    @include padding-two-var(10px, 5px);
    background-color: $dark-blue-7;
    border-radius: 12px;
    text-align: center;
    border: 2px solid transparent;

    &-selected {
      background-color: $dark-purple-3;
      border: $purple-border-thick;
      border-radius: 12px;
    }

    &-error {
      border: $pink-border;
    }

    &-disabled {
      opacity: 0.5;
    }

    .currency {
      @include font(20px);
      margin-right: 5px;
      text-transform: uppercase;
    }

    .amount {
      @include font-semi(20px);
    }

    .conversion {
      @include font(16px);
      @include padding-one-var(5px);
      border-radius: 50px;
      color: $white;
      @include flex(center, center, row);

      span {
        margin-top: 2px;
      }

      &-mgc {
        @include icon-image('mgo-coin', false, $icons-url);
        @include background-position-size(center, cover);
        @include width-height-same(25px);
        margin-right: 5px;
      }
    }
  }
}

.next-step {
  @include margin(20px);
  text-align: center;

  &-button {
    @include purple-button(16px);
    @include padding-two-var(15px);
    width: 100%;
    max-width: 400px;
    outline: none;

    &-disabled {
      opacity: 0.5;
      cursor: default !important;
    }
  }
}

.previous-step-button {
  @include padding-one-var();
  @include font(16px);
  outline: none;
  border: none;
  background: none;
  color: $white;
  position: relative;

  &-icon {
    @include margin(0, 10px, 0, -5px);
  }
}

.checkout {
  &-details {
    margin-top: 20px;

    &-main {
      @include padding-one-var(20px);
      margin-top: 10px;
      background-color: $dark-purple-3;
      border-radius: $border-radius-desktop;
      border: $purple-border-thick;
      text-align: center;
      @include flex(center, center, row);
      flex-wrap: wrap;

      .amount {
        @include font-medium(36px);
      }
  
      img {
        @include width-height-same(32px);
        object-fit: cover;
        margin-left: 6px;
      }

      .note {
        width: 100%;
        flex: 0 0 100%;
        font-size: 14px;
      }
    }

    &-row {
      @include font(16px);
      @include margin(20px, 0px, 0px, 1px);
    }

    &-details {
      @include padding-one-var(20px);
      margin-top: 10px;
      background-color: $dark-blue-7;
      border-radius: $border-radius-desktop;
      text-align: center;
      @include flex(center, center, row);
      flex-wrap: wrap;

      .amount {
        @include font-medium(36px);
      }
  
      img {
        @include width-height-same(50px);
        object-fit: cover;
        margin-left: 15px;
      }

      .note {
        width: 100%;
        flex: 0 0 100%;
        font-size: 14px;
      }
    }
  }
  
  &-table {
    width: 100%;
    grid-template-columns: auto auto;

    &-label {
      text-align: left;
    }

    &-value {
      text-align: right;
    }
  }

  &-wait {
    @include font-light(14px);
    text-align: center;
    margin-top: 10px;
  }

  &-button {
    @include purple-button(16px);
    @include padding-two-var(15px);
    width: 100%;
    max-width: 400px;
    position: relative;

    p {
      margin: 0;
    }

    &-wrapper {
      margin-top: 70px;
      text-align: center;
      transition: 0.5s ease;
    }

    &-load {
      height: 8px;
      width: 30px;
      @include margin(4px, 0, 0, 3px)
    }
  }
}

.success {
  &-container {
    @include position-float-top-left(0, 0);
    @include flex(space-between, center, column);
    @include width-height-same(100%);

    button {
      @include purple-button(16px);
      @include padding-two-var(15px);
      width: 100%;
      max-width: 400px;
    }
  }

  &-back {
    @include padding-one-var();
    @include font(16px);
    outline: none;
    border: none;
    background: none;
    color: $white;
    position: relative;
    display: flex;
    justify-content: flex-start;
    z-index: 100;

    &-icon {
      @include margin(0, 10px, 0, -5px);
    }
  }

  &-wrapper {
    @include flex(center, center, column);
    flex-grow: 1;

    &-success {
      @include icon-image('payment-process', false, $spend-icon-url);
      @include background-position-size(center, contain);
      @include width-height(150px, 160px);
    }

    &-failed {
      @include icon-image('payment-failed', false, $spend-icon-url);
      @include width-height(150px, 160px);
    }

    h3 {
      @include font-bold(18px);
      text-align: center;
    }

    p {
      @include font(12px);
      color: $grey-4;
      margin-bottom: 8px;
      max-width: 400px;
      text-align: center;
    }
  }
}

