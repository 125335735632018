@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.profile-menu {
  &-section {
    @include padding-one-var();
    border-top: none;
  }

  &-list {
    @include margin-two-var(0, -30px);

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
    }
  }

  &-item {
    @include link($white, $white);
    @include padding-two-var(15px, 30px);
    @include font-medium(16px);
    display: block;
    position: relative;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(15px, 20px);
    }

    &-forward-icon {
      color: $grey-2;
      position: absolute;
      right: 30px;

      @media (max-width: $max-mobile-width-sm) {
        right: 20px;
      }
    }
  }
}