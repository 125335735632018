@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.banner {
  @include margin(30px, 30px, 30px, 30px);

  @media (max-width: $max-mobile-width-sm) {
    @include margin(-20px, -20px, 30px);
    width: 100% !important;
  }

  &-mobile {
    margin-bottom: 0 !important;
    position: relative;
  }

  &-clickable {
    @media (max-width: $max-mobile-width-sm) {
      @include margin(-20px, -20px, 30px);
    }
  }

  &-carousel {
    @include margin(20px, 30px, 30px, 30px);
    width: calc(100vw - 60px);

    @media (max-width: $max-mobile-width-sm) {
      @include margin(0, 0, 30px);
    }

    &-solo {
      @include margin(20px, 30px, 0, 30px);

      @media (max-width: $max-mobile-width-sm) {
        @include margin(0, 0, 20px !important);
      }
    }
  }

  &-cover {
    @include width-height(100%, 420px);
    @include background-position-size();
    border-radius: $border-radius-mobile;
    position: relative;
    z-index: 1;
    pointer-events: none;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(100%, 200px);
      @include margin-one-var();
      border-radius: 0;
    }

    &-link {
      display: block;
    }

    &-carousel {
      @include width-height(100%, 30vw);
      max-height: 420px;

      @media (max-width: $max-mobile-width-xs) {
        @include width-height(100%, 58.4vw);
        max-height: 335px;
      }
    }
  }

  &-details {
    @include padding-one-var();
    position: relative;
    z-index: 1;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
      width: auto;
      margin-left: 0;
    }

    &-mobile {
      @include link($white, $white);
      @include width-height(100%, 30vw);
      @include flex(center, flex-end, column);
      max-height: 420px;
      margin-left: auto;
      margin-right: 0;
      background-image: $mobile-banner-gradient;
      position: absolute;
      top: 0;
      z-index: 1;

      @media (max-width: $max-mobile-width-xs) {
        @include width-height(100%, 58.4vw);
        max-height: 335px;
      }

      &-without-text {
        background-image: none;
      }

      &-inner {
        @include width-height(50%, 100%);
        @include flex(center, flex-end, column);
        padding-right: 20px;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(60%, 100%);
        }

        @media (max-width: $max-mobile-width-xxs) {
          @include width-height(60%, 100%);
        }
      }
    }
  }

  &-description {
    @include padding-one-var();
    width: 100%;
    vertical-align: middle;

    &-without-cta {
      width: 100%;
    }
  }

  &-logo {
    @include width-height-same(150px);
    @include margin(-40px, 20px);
    @include background-position-size();
    display: inline-block;
    vertical-align: top;
    background-color: $dark-purple-1;
    border: $profile-pic-border;
    border-radius: 50%;

    @media (max-width: $max-tablet-width) {
      @include width-height-same(100px);
      border-width: 5px;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include margin(-50px, auto, 0, auto);
      display: block;
    }
  }

  &-info {
    @include padding(20px);
  }

  &-text {
    vertical-align: top;

    @media (max-width: $max-mobile-width-sm) {
      text-align: center;
    }

    &-with-button {
      @include responsive-inline-block(calc(100% - 200px));
      padding-right: 20px;

      @media (max-width: $max-mobile-width-sm) {
        padding-right: 0;
      }
    }
  }

  &-title {
    @include font-semi(24px);
    margin-bottom: 20px;

    &-mobile {
      margin-bottom: 0;
      text-align: right;
    }
  }

  &-short-desc {
    @include font-light(14px);

    @media (max-width: $max-tablet-width) {
      @include font-light();
    }
  }

  &-button {
    @include purple-button();
    @include responsive-width(100%, 100%);
    max-width: none;
    cursor: pointer;

    @media (max-width: $max-mobile-width-sm) {
      max-width: $max-button-width-mobile;
    }

    &-wrapper {
      @include responsive-inline-block(200px);
      vertical-align: top;
      text-align: center;

      @media (max-width: $max-mobile-width-sm) {
        margin-top: 20px;
      }
    }
  }
}