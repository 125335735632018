@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.properties {
  @include margin(20px, -10px, -10px);

  @media (min-width: $min-desktop-width-sm) {
    max-width: 790px;
  }

  &-fluid {
    @media (min-width: $min-desktop-width-sm) {
      max-width: none;
    }
  }

  &-no-top-space {
    margin-top: 0;
  }
}

.property {
  @include padding(0, 10px, 10px);

  &-inner {
    @include padding-two-var(15px, 20px);
    border: $purple-border;
    border-radius: 10px;
    background-color: $dark-purple-1;
    text-align: left;
  }

  &-value {
    @include font-medium(14px);
    text-transform: uppercase;
    color: $white;
  }

  &-label {
    @include font();
    text-transform: uppercase;
    color: $white;
  }
}