@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.payment-instructions {
  @include flex(space-between, center, column);
  @include width-height-same(100%);

  @media (min-width: $max-mobile-width-sm){
    color: $white;
  }

  &-layout {
    position: relative;
    overflow-x: hidden;
    height: 100%;

    @media (max-width: $max-mobile-width-sm){
      height: calc(var(--vh, 1vh) * 100 - 66px);
      padding-bottom: 0;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    &-inner {
      height: 100%;
    }
  }

  &-header {
    @include font-bold(17px);
    text-align: center;
    margin-bottom: 10px;

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &-hide {
      @media (max-width: $max-mobile-width-sm){
        display: none;
      }
    }
  }

  &-container {
    @include flex(center, center, column);
    margin-top: 30px;

    &-mobile {
      margin: 0;
    }
  }

  &-box {
    width: 100%;
    max-width: 600px;
    background-color: $dark-blue-7;
    border-radius: 12px;

    @media (max-width: $max-mobile-width-sm){
      margin-bottom: 20px;
    }

    &-transparent {
      background-color: transparent;
    }
  }

  &-title {
    @include padding-two-var(15px, 20px);
    @include font-bold(16px);
    color: $teal;
    text-align: center;
    text-transform: uppercase;
  }

  &-step {
    @include padding-two-var(15px, 20px);
    border-top: 1px solid $dark-blue-3;

    &-title {
      @include font-semi(16px);
      color: $teal;
      text-transform: uppercase;
    }

    &-details, &-subdetails {
      @include font(14px);
      list-style-type: disc;
      margin-block-end: 0;
      padding-inline-start: 20px;
    }

    &-details {
      margin-block-start: 5px;
    }

    &-detail {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-text {
      a {
        color: $pink-1;
      }
    }

    &-label {
      @include font(14px);
    }

    &-value {
      @include font-bold(14px);
    }
  }

  &-form {
    @include margin(15px, 0, 15px, -20px);
    width: calc(100% + 20px);
    text-align: center;

    &-image {
      width: 97%;
    }
  }

  &-table {
    margin-top: 5px;

    &-header {
      @include font-bold(14px);
    }

    .col-sm-5, .col-4 {
      padding-left: 0;
    }
  }

  &-back-to-wallet {
    @include flex(center, center, row);
    width: 100%;

    &-mobile {
      @media (max-width: $max-mobile-width-sm){
        bottom: 20px;
      }
    }

    &-link {
      @include padding(20px);
      margin-bottom: 20px;
      text-decoration: none;
      width: 100%;
      text-align: center;
      background-color: $dark-purple-1;

      @media (min-width: $max-mobile-width-sm){
        margin-bottom: 0;
      }
    }

    &-button {
      @include purple-button();
      width: 100%;
      text-align: center;

      @media (min-width: $max-mobile-width-sm){
        max-width: 600px;
      }
    }
  }
}