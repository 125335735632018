@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.wallet-connect {
  @include flex(space-between, center, row);
  @include font-semi(14px);
  @include padding(16px, 16px, 16px, 20px);
  background-color: $dark-purple-7;
  gap: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  &-faqs {
    @include font(14px);
    display: block;
    color: $pink-1;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: $pink-1;
    }
  }

  &-name {
    @include font-semi(14px);
  }

  &-details {
    @include flex(center, center, row);
    gap: 12px;
  }

  &-icon {
    @include width-height-same(30px);
  }

  &-title {
    @include font-semi(18px);
    padding-bottom: 10px;
  }

  &-text {
    @include font(14px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    @media (max-width: $max-mobile-width-sm) {
      @include font();
    }

    &-wrapper {
      @include flex(flex-start, center, row);
      flex-wrap: wrap;
      gap: 4px;
    }
  }

  &-chains {
    @include font(14px);
  }

  &-disconnect {
    @include font-semi(14px);
    color: $pink-1;
    border: none;
    background-color: transparent;
    text-transform: uppercase;

    @media (max-width: $max-mobile-width-sm) {
      @include font-semi();
    }
  }
}