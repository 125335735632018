@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$mgo-coin-url: '../../../assets/icons/';

.order-summary {
  color: $white;
  background-color: $dark-purple-1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &-container {
    padding-left: 0 !important;

    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;
    height: 100vh;
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-table {
    display: table;
    width: 100%;

    @media (max-width: $max-mobile-width-xs) {
      @include font-semi(16px);
    }

    &-row {
      display: table-row;
    }

    &-cell {
      display: table-cell;
      vertical-align: top;
      width: 50%;
    }

    &-label {
      @include font(14px);
      text-align: left;

      @media (max-width: $max-mobile-width-xs) {
        width: 30%;
      }
    }

    &-value {
      @include font(14px);
      text-align: right;

      @media (max-width: $max-mobile-width-xs) {
        width: 70%;
      }

      &-large {
        @include font-semi(18px);
      }
    }
  }

  &-mgo-coin {
    @include icon-image('mgo-coin', true, $mgo-coin-url);
    @include background-position-size(center, 18px);
    @include width-height-same(18px);
    @include margin(-2.5px, 0, 0, 5px);
    display: inline-block;
    vertical-align: middle;
  }

  &-cancel-button {
    @include dark-grey-button();
    @include margin(0, 10px);
    width: calc(50% - 10px);
  }

  &-accept-button {
    @include purple-button();
    @include margin(0, 0, 0, 10px);
    width: calc(50% - 10px);
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }

      .btn-close {
        @include margin(-0.5rem, -0.5rem, -0.5rem, 0);
        position: absolute;
        right: 30px;
      }
    }

    &-body {
      @include padding-two-var(20px, 30px);
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      border-top: none;
    }
  }
}