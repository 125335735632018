@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.partners {
  overflow-x: hidden;
  background-color: $dark-pink-3;
  background-image: $wen-lambo-list-page-gradient;
  
  &-title {
    @include font-semi(18px);
    margin-top: 5px;
  }

  &-section {
    padding-top: 0;
    border-top: none;

    &-container {
      @include flex(space-between, center, row);
      @include padding(0px, 0px, 10px, 0px);
    }

    &-title {
      @include font-semi(24px);
      margin: 0px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(18px);
      }
    }
      
    &-back {
      &-text {
        @include flex(center, center, row);
        @include font-medium(14px);
        color: $white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover{
          color: $white;
        }
      }
    
      &-icon {
        color: $white;
        margin-right: 5px;
      }
    }
  }
 
  &-banner {
    @include width-height(100%, auto);
    border-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-xs) {
      @include width-height(calc(100% + 40px), auto);
      @include margin-two-var(0, -20px);
      border-radius: 0;
    }
  }

  &-sections {
    margin-top: 20px;
  }

  &-thumbnails {
    &-title {
      @include font-semi(18px);
      margin-bottom: 20px;
    }

    &-items {
      @include margin-one-var();
      gap: 20px;
      width: 100%;
    }

    &-item {
      @include padding-one-var(15px);
      width: calc(50% - 10px);
      border-radius: $border-radius-mobile;
      background-color: $light-purple-translucent;

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;
      }

      &-header {
        @include flex(center, center, row);
        gap: 10px;
        margin-bottom: 10px;
      }

      &-logo {
        @include width-height-same(43px);
      }

      &-title {
        @include font-semi(18px);
      }

      &-banner {
        width: 100%;
        border-radius: 4px;
      }
    }
  }

  &-icons {
    &-title {
      @include font-semi(18px);
      margin-bottom: 20px;
    }

    &-items {
      @include margin(0, 0, -15px);
      width: 100%;
    }

    &-item {
      margin-bottom: 15px;

      &-inner {
        @include flex(center, center, column);
        gap: 10px;
      }

      &-logo {
        @include width-height-same(120px);
      }

      &-title {
        @include font-semi(14px);
        text-align: center;
      }
    }
  }

  &-carousels {
    @include margin(20px);
  }

  &-carousel {
    @include padding-one-var();

    &-items {
      @include margin-one-var();
    }

    &-banner {
      width: 100%;
      border-radius: $border-radius-mobile;
    }
  }
}