@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.transactions-history {
  padding-top: 20px;
  position: relative;

  &:last-child {
    padding-bottom: 20px;
  }

  &-container {
    @include font-medium(14px);

    &-header {
      @include padding-two-var(20px, 40px);
      border-bottom: 2px solid $dark-purple-9;
    }

    &-body {
      @include padding-two-var(15px, 40px)
    }
  }

  &-transaction {
    @include padding-two-var(15px, 15px);
    @include margin(15px);
    display: block;
    background-color: $dark-blue-7;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }

    &-empty {
      @include flex(center, center, column);
      @include font-semi(14px);
      background-color: $dark-blue-7;
      border-radius: 8px;
      height: 130px;
      width: 100%;
    }

    &-success {
      border-left: 4px solid $green-1;

      &-outcoming {
        border-left: 4px solid $red-1;
      }
    }

    &-failed {
      border-left: 4px solid $grey-9;
    }

    &-pending {
      border-left: 4px solid $orange-1;
    }
  
    &:first-child {
      @include margin();
    }

    &-arrow {
      font-size: 18px !important;
    }

    &-wrapper {
      @include flex(space-between, center, row);
      position: relative;
    }
  
    &-title {
      @include font-semi(16px);

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(14px);
      }
    }

    &-subtitle {
      @include font-semi(13px);
      color: $light-purple;

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(12px);
      }
    }

    &-right {
      @include flex(center, flex-end, column);
      gap: 40px;

      &-gap {
        gap: 10px;
      }
    }
  
    &-date {
      @include font(13px);

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(12px);
      }

      &-wrapper {
        @include flex(center, center, row);
      }
    }

    &-time {
      @include font(13px);

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(12px);
      }
    }

    &-line {
      @include flex(center, center, row);
    }
  
    &-amount {
      @include font-semi(16px);

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(14px);
      }

      &-success {
        color: $green-1;

        &-outcoming {
          color: $red-1;
        }
      }

      &-failed {
        color: $grey-9;
      }

      &-pending {
        color: $orange-1;
      }
    }

    &-barcode {
      @include font-semi(13px);
      color: $pink-1;
      text-decoration: underline;

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(12px);
      }
    }

    &-status {
      @include font-semi(13px);
      text-transform: uppercase;

      @media (max-width: $max-mobile-width-xs){
        @include font-semi(12px);
      }
    }
  }
}