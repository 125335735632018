@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/mixins.scss';

$url: '../../../assets/icons/';

.slick {
  &-track {
    margin-left: 0;
  }

  &-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
  }

  &-prev {
    @extend %horizontal-scroll-button;
    left: 0;
    background: $transparent-gradient-prev;

    &:before {
      @include icon-image('arrow-prev', true, $url);
    }

    &:hover, &:focus {
      background: $transparent-gradient-prev;
    }
  }

  &-next {
    @extend %horizontal-scroll-button;
    right: 0;
    background: $transparent-gradient-next;

    &:before {
      @include icon-image('arrow-next', true, $url);
    }

    &:hover, &:focus {
      background: $transparent-gradient-next;
    }
  }
}