@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/settings.scss';

.select-currency-toast {
  color: $white;
  background-color: $dark-purple-7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  &-container {
    padding-left: 0 !important;
    overflow: hidden;

    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;

    &.show {
      opacity: 0.9;
    }
  }

  
  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-header {
    border-bottom: none;

    &-title {
      @include font-semi(18px);
      margin-left: 30px;
      width: 100%;
    }
  }

  &-footer {
    @include flex(center, center, row);
    border-top: none;

    &-button {
      @include purple-button();
      width: 100%;
      max-width: 400px;
    }
  }

  &-body {
    @include flex(center, center, column);
    @include padding(0, 0, 0, 10px);

    &-container {
      @include flex(center, center, column);
      width: 100%;
      max-width: 400px;
      gap: 10px;
    }

    &-content {
      @include flex(flex-start, center, row);
      @include font-semi(14px);
      width: 100%;
      gap: 10px;
      cursor: pointer;

      &-image {
        @include width-height-same(32px);
        margin-right: 10px;
      }
    }
  }
}