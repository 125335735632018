@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../assets/icons/login-wait-list/';
$logo-url: '../assets/logo/';

.login {
  &-container {
    width: 100%;
    // height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;

    &-wait {
      @include position-float-top-left(0, 0);
      @include flex(flex-end, center, column);
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      background: rgba(0, 0, 0, 0.55);

      &-header {
        h1 {
          @include font-bold(20px);
          @include padding-two-var(0, 20px);
          text-align: center;
          margin-bottom: 20px;
        }
      }

      &-body {
        @include padding-two-var(0, 5px);
        p {
          @include font-light(15px);
          text-align: center;
          &:nth-child(2) {
            @include padding-two-var(0, 20px);
          }
          &:nth-child(3) {
            @include padding-two-var(0, 20px);
          }
        }
      }

      &-footer {
        margin-top: 20px;
        display: flex;
        gap: 15px;
        &-facebook {
          @include width-height-same(40px);
          @include icon-image("facebook", false, $icons-url);
        }
        &-instagram {
          @include width-height-same(40px);
          @include icon-image("instagram", false, $icons-url);
        }
        &-twitter {
          @include width-height-same(40px);
          @include icon-image("twitter", false, $icons-url);
        }
        &-discord {
          @include width-height-same(40px);
          @include icon-image("discord", false, $icons-url);
        }
        &-telegram {
          @include width-height-same(40px);
          @include icon-image("telegram", false, $icons-url);
        }
      }

      &-close {
        @include position-float-top-right(15px, 15px);
        cursor: pointer;
      }

      &-wrapper {
        @include width-height(100vw, auto);
        @include flex(flex-end, center, column);
        @include border-top-side(20px, 20px);
        background-color: $dark-purple-4;
        position: relative;
        @include padding(60px, 20px, 20px, 20px);
      }
    }
  }

  &-pages {
    display: flex;
    width: 300%;
    box-sizing: border-box;
    overflow-y: overlay;
  }

  &-content {
    @include width-height(100%, auto);
    @include flex-page(center);
    @include padding-one-var(20px);
  }

  &-logo {
    @include icon-image('logo', true, $logo-url);
    @include width-height(100%, 45px);
    @include background-position-size(center, 100%);
    @include margin-two-var(30px, auto);
    max-width: 467px;

    @media (max-width: $max-mobile-width-sm){
      max-width: 304px;
    }
  }

  &-form {
    @include font(16px);
    @include margin-two-var(0, auto);
    display: block;
    width: 100%;
    max-width: 483px;

    &-box {
      @include padding-one-var(20px);
      @include margin-two-var(30px);
      border-radius: 8px;
      background-color: $dark-blue-7;
      display: flex;
      gap: 5px;

      @media (max-width: $max-mobile-width-sm){
        @include padding-one-var(15px);
      }
    }

    &-error {
      @include font(14px);

      &-icon {
        @include icon-image("info", false, $icons-url);
        @include width-height-same(21px);
        @include background-position-size(center, contain);
        transform: translateY(-1px);

        @media (max-width: $max-mobile-width-sm){
          @include width-height(28px, 22px);
        }
      }
    }

    &-row {
      margin-bottom: 20px;
    }

    &-label {
      @extend %form-label;

      &-error {
        @include margin(10px);
        color: $pink-1;
      }
    }

    &-group {
      @include margin(10px);
      @extend %form-input;

      &-error {
        outline: $pink-border;
      }
    }

    &-input {
      @include margin(10px);
      @extend %form-input;

      &-error {
        outline: $pink-border;
      }

      &:focus {
        outline: none;
      }
    }

    &-dropdown-menu {
      max-width: 400px;
    }
  }

  &-signup {
    @include flex(center, center, row);
    @include font(14px);
    text-align: center;
    

    &-link {
      @include font(14px);
      @include padding-one-var(0);
      color: $pink-1;
      background-color: transparent;
      text-decoration: none;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $pink-1;
      }
    }
  }

  &-signin {
    margin-top: 20px;
  }

  &-consent {
    @include font(10px);
    color: $light-purple;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;
    }

    &-label {
      display: inline-block;
      margin-left: 30px;

      a {
        @include font-bold(10px);
        @include padding-one-var(0);
        @include link($pink-1, $pink-1);
        background-color: transparent;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .checkbox {
      @include width-height-same(20px);
      position: absolute;
      top: 0;
      left: 0;
      background-color: $dark-purple-2;
      border: $white-border-checkbox;
      border-radius: 5px;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ .checkbox {
      background-color: $grey-2;
    }

    & input:checked ~ .checkbox {
      border: $pink-border-checkbox;
      background-color: $pink-1;

      &:after {
        display: block;
        border-color: $dark-purple-1;
      }
    }

    & .checkbox::after {
      @include width-height(4px, 9px);
      left: 5px;
      top: 2px;
      border: $white-border-thick;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &-submit {
    @include purple-button(12px);
    @include padding-two-var(15px);
    width: calc(100vw - 60px);
    margin-top: 20px;
    max-width: 483px;

    @media (max-width: $max-tablet-width) {
      width: calc(100vw - 40px);
    }

    &-disabled {
      opacity: 0.5;
      cursor: default !important;
    }
  }

  &-otp {
    @include width-height(100%, 100vh);
    height: calc(var(--vh, 1vh) * 100);
    @include flex-page(space-between);
    @include padding-one-var(20px);

    &-header {
      @extend %otp-pin-header;
    }

    &-form {
      width: 100%;
      max-width: 400px;
      flex: 0 0 70%;
    }

    &-submit {
      @include margin(auto, 0, 20px);
      @include purple-button(16px);
      width: 100%;
      max-width: 400px;
    }
  }

  &-pin {
    @include width-height(100%, 100vh);
    height: calc(var(--vh, 1vh) * 100);
    @include flex-page(space-between);
    @include padding-one-var(20px);

    &-header {
      @extend %otp-pin-header;
    }

    &-keyboard {
      display: flex;
      height: calc(100% - 27px);
      align-items: center;
    }
  }
}
