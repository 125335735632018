@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.active-game-pass {
  @include flex(flex-start, center, row);
  @include padding-one-var(15px);
  width: 100%;
  border-radius: $border-radius-mobile;
  background-color: $white-translucent;

  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var();
    border-radius: 0;
    background-color: transparent;
  }

  &-inner {
    @include flex(center, center, row);
    width: 100%;
    gap: 20px;

    @media (max-width: $max-desktop-width-sm) {
      gap: 15px;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include flex(flex-start, center, column);
    }
  }

  &-tickets {
    @include flex(center, center, row);
    @include width-height(160px, 210px);
    position: relative;

    @media (max-width: $max-desktop-width-sm) {
      @include width-height(135px, 173px);
    }

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(25vw, 35vw);
      max-width: 150px;
      max-height: 200px;
    }

    &-stacked {
      cursor: pointer;
    }
  }

  &-ticket {
    &-images {
      @include width-height-same(100%);
      position: relative;
      text-align: center;
    }

    &-image {
      @include width-height(auto, 100%);

      &-multiple {
        @include width-height(auto, calc(100% - 10px));
        max-height: 200px;
        position: absolute;

        @media (max-width: $max-desktop-width-sm) {
          max-height: 188px;
        }

        &-max {
          @include width-height(auto, calc(100% - 7.5px));
        }
      }
    }
  }

  &-timer {
    @include flex(center, flex-start, column);
    text-align: left;
    width: auto;

    @media (min-width: $min-tablet-width) and (max-width: $max-desktop-width-sm) {
      width: calc(100% - 145px);
    }

    @media (max-width: $max-mobile-width-sm) {
      @include flex(center, center, column);
      text-align: center;
      width: auto;
    }

    &-title {
      @include font-semi(16px);
      color: $white;

      @media (max-width: $max-desktop-width-sm) {
        @include font-semi(14px);
      }

      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
        color: $light-purple;
      }
    }

    &-duration {
      @include font-semi(36px);

      @media (max-width: $max-desktop-width-sm) {
        @include font-semi(24px);
      }
    }

    &-expiry-date {
      @include font-light(14px);
      @include margin-one-var();
      @include padding-one-var();

      @media (max-width: $max-desktop-width-sm) {
        @include font-light(12px);
        max-width: 100%;
      }
    
      b {
        @include font-semi(12px);
      }
    }
  }
}