@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

$gamepass-details-bg-url: '../../assets/gamepass/bg/details/';
$icons-url: '../../assets/icons/';

.gamepass-details {
  &-outer {
    @include padding(30px, 30px, 0, 30px);
    overflow-x: hidden;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(20px, 20px, 0, 20px);
    }
  }

  &-inner {
    max-width: none;
  }

  &-header {
    &-title {
      @include font-semi(18px);
    }
  }

  &-section {
    padding-top: 0;
    border-top: none;

    .section-header {
      @include margin-two-var(0, auto);
      max-width: $max-layout-width;
    }

    &-container {
      @include flex(space-between, center, row);
      margin-bottom: 20px;

      @media (max-width: $max-mobile-width-sm) {
        margin-bottom: 15px;
      }
    }
            
    &-back {
      &-text {
        @include flex(center, center, row);
        @include font-medium(14px);
        @include margin(0px, 0px, 0px, -5px);
        gap: 0.5rem;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
      
        &:hover {
          color: $white;
        }
      }
          
      &-icon {
        color: $white;
      }
    }

    &-coin-container {
      display: none;
    }

    &-cart {
      @include width-height-same(22px);
      @include icon-image('gamepass-cart', true, $icons-url);
      @include background-position-size();
      cursor: pointer;

      &-dot {
        @include width-height-same(12px);
        @include position-float-top-right(-2px, -6px);
        background-color: $pink-6;
        border-radius: 100%;
        border: $cart-dot-border;
      }
    }
  }

  &-image {
    width: 100%;
    max-height: 100%;
    object-fit: contain;

    &-container {
      @include flex(center, center, row);
      @include width-height(calc(100% + 60px), 450px);
      @include padding-two-var(40px);
      @include margin-two-var(0px, -30px);
      @include background-image('desktop', $gamepass-details-bg-url, '.png', false);
      @include background-position-size(right center, cover);

      @media (max-width: $max-mobile-width-sm) {
        @include margin-two-var(0px, -20px);
        @include background-image('mobile', $gamepass-details-bg-url, '.png', false);
        @include background-position-size(right center, cover);
        width: calc(100% + 40px);
      }
    }
  }

  &-contents {
    @include margin-two-var(0, -30px);
    @include padding(0, 30px, 30px, 30px);
    width: calc(100% + 60px);
    background-color: $dark-pink-4;
    background-image: $wen-lambo-details-page-gradient;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      @include padding(0, 20px, 20px, 20px);
      width: calc(100% + 40px);
    }
  }

  &-container {
    @include margin-two-var(0, -30px);
    border-bottom: 1px solid $light-purple-translucent;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
    }

    &-inner {
      @include flex(space-between, center, row);
      @include margin-two-var(0, auto);
      @include padding-two-var(20px);
      width: calc(100% - 60px);
      max-width: $max-layout-width;
  
      @media (max-width: $max-mobile-width-sm) {
        @include margin-one-var();
        @include padding-one-var(20px);
        width: 100%;
      }
    }
  }

  &-description {
    @include font-light(14px);
    @include margin-two-var(15px);

    &-container {
      @include flex(flex-start, flex-start, column);
      @include margin-two-var(0, auto);
      padding-top: 20px;
      width: 100%;
      max-width: $max-layout-width;
    }
  }

  &-title {
    @include font-semi(18px);
    @include margin-one-var();
    @include padding-one-var();
  } 

  &-text {
    &-container {
      @include flex(flex-start, flex-start, column);
      @include margin-one-var();
      @include padding-one-var();
    }

    &-mgc {
      &-icon {
        @include width-height-same(18px);
        @include icon-image('mgo-coin', true, $icons-url);
        @include background-position-size();
        margin-right: 3px;
      }

      &-prices {
        @include flex(center, center, row);
        @include font(18px);
        @include margin-one-var();
        @include padding-one-var();
        gap: 5px;
      }

      &-price {
        @include flex(center, center, row);
        gap: 5px;
      }
    }

    &-currency-price {
      @include flex(center, center, row);
      color: $light-purple;
    }
  }

  &-buttons {
    @include flex(flex-start, center, row);
    width: 100%;
    margin-top: 20px;
    gap: 10px;

    @media (max-width: $max-mobile-width-sm) {
      @include flex(flex-start, flex-start, column);
    }
  }

  &-buy-button {
    @include orange-button(12px);
    width: 100%;
  }

  &-add-to-cart-button {
    @include orange-button-border(12px);
    width: 100%;
  }

  &-quantity {
    &-icon {
      padding: 0;
      background-color: transparent;
      outline: none;
      border: none;
    }

    &-container {
      @include flex(center, center, row);
      width: 100px;
      gap: 5px;
    }

    &-input {
      @include font-semi(14px);
      width: 25px;
      text-align: center;
      background-color: transparent;
      border: none;
      color: $white;
    }
  }
}