@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.promos {
  @include flex(flex-start, center, column);
  overflow: scroll;
  overflow: hidden;
  padding-bottom: 100px;

  &-back {
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
    transform: translateX(-5px);
    cursor: pointer;
  }

  &-buy {
    @include purple-button(14px);
    @include margin-two-var(10px);
    width: 100%;
    max-width: 400px;

    &-wrapper {
      @include padding-two-var(15px, 20px);
      width: 100%;
      background-color: $dark-purple-1;
      position: fixed;
      bottom: 0;
      text-align: center;
    }
  }

  &-container {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-wrapper {
    @include padding-two-var(0px, 5px);
    width: 100%;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-button {
    @include purple-button(14px);
    @include margin-two-var(20px);
    width: 100%;
    max-width: 400px;
  }
  
  &-title {
    @include font-bold(20px);
  }

  &-list {
    @include font-light(14px);
    @include margin(15px);

    &-number {
      display: inline-block;
      width: 20px;
      vertical-align: top;
    }

    &-name {
      display: inline-block;
      width: calc(100% - 20px);
      vertical-align: top;
    }
  }

  &-eligible {
    @include font-bold(20px);
    padding-bottom: 20px;
  }

  &-item {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media (min-width: $min-tablet-width) and (max-width: $max-desktop-width-lg){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (min-width: $min-mobile-width-sm) and (max-width: $max-mobile-width-sm){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: $max-mobile-width-xs){
      grid-template-columns: 1fr 1fr;
    }
    
    &-title {
      @include font-semi(16px);
      @include margin(0, 0, 20px, 0px);
    }

    &-box {
      @include flex(center, center, column);
      @include width-height(100%, 80px);
      @include padding-one-var(10px);
      background-color: $dark-purple-1;
      border-radius: 12px;
      border: $purple-border;

      &:hover {
        box-shadow: $glow;
        cursor: pointer;
      }

      &-solo {
        width: 100%;
      }

      &-name {
        @include font-semi(16px);
        text-align: center;
      }

      &-desc {
        @include font-light(12px);
        text-align: center;
      }
    }
  }
}