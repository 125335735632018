@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.checkout {
  @include padding-one-var(30px);

  @media (max-width: $max-mobile-width-sm){
    @include padding-one-var(20px);
    height: calc(100vh - 126px);
    height: calc((var(--vh, 1vh) * 100) - 126px);

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &-empty {
    @include width-height(100%, 110px);
    @include font(14px);
    @include flex(center, center, row);
    background-color: $dark-purple-7;
    border-radius: 8px;
  }

  &-purchase {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-success {
      @include width-height-same(120px);
      
      &-text {
        @include font-semi(18px);
      }

      &-id {
        @include font-medium(12px);
        color: $light-purple;
      }

      &-note {
        @include font-medium(12px);
        color: $light-purple;
        text-align: center;
        max-width: 400px;
      }
    }

    &-button {
      @include padding(40px, 20px, 20px, 20px);
      @include flex(flex-end, center, column);
      width: 100%;
      max-width: 400px;
      gap: 10px;

      &-activate {
        @include purple-button();
        width: 100%;
      }

      &-back {
        @include purple-button-border();
        @include padding-two-var(15px);
        color: $white;
        width: 100%;
      }
    }

    &-box {
      @include padding(30px, 30px, 20px, 30px);
      background-color: $dark-purple-7;
      border-radius: 8px;
      width: 100%;
      max-width: 400px;
      margin-top: 30px;

      &-bottom {
        @include flex(flex-start, flex-start, column);
        @include padding(20px);
        border-top: 1px solid $dark-blue-1;
        gap: 15px;
      }

      &-card {
        max-width: 45px;
      }

      &-text {
        @include font-semi(14px);
        transform: translateX(2px);
      }

      &-icon {
        @include width-height-same(15px);
      }

      &-price {
        @include font(14px);
      }

      &-sub {
        @include flex(space-between, center, row);
        @include font(14px);
        width: 100%;
      }

      &-total {
        @include flex(space-between, center, row);
        @include font-semi(16px);
        width: 100%;
      }
    }
  }

  &-section {
    @include flex(flex-start, normal, column);
    border: none;
    padding-top: 0;
    height: 100%;

    &-content {
      @include flex(space-between, center, column);
      height: 100%;
    }
  }

  &-inner {
    height: 100%;
  }

  &-card {
    @include padding-two-var(15px, 20px);
    @include flex(flex-start, center, row);
    @include margin-two-var(20px);
    gap: 20px;
    width: 100%;
    background-color: $dark-purple-7;
    border-radius: 8px;

    &-image {
      width: 100%;
      max-width: 50px;
    }

    &-name {
      @include font-semi(14px);
    }

    &-price {
      @include font(14px);
    }

    &-icon {
      @include width-height-same(15px);
    }

    &-mgc {
      @include width-height-same(20px);
      margin-bottom: 0;
    }
  }

  &-payment {
    margin-top: 20px;
    
    &-title {
      @include font-semi(14px);
      padding-bottom: 20px;
    }

    &-card {
      @include padding-two-var(20px, 15px);
      @include flex(flex-start, center, row);
      gap: 20px;
      width: 100%;
      background-color: $dark-purple-7;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      min-height: 82px;
      
      &-wrapper {
        @include flex(flex-start, center, row);
        gap: 15px;
        width: 100%;
      }

      &-icon {
        max-width: 50px;

        &-small {
          max-width: 33px;
        }
      }

      &-arrow {
        @include position-float-top-right(50%, 10px);
        transform: translateY(-50%);
      }

      &-text {
        @include font-semi(14px);
        @include flex(flex-start, center, row);
        flex-wrap: wrap;
        gap: 4px;
      }

      &-subtext {
        @include font(14px);
        margin-right: 10px;
      }

      &-description {
        @include font();
        margin-right: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    &-bottom {
      @include padding-one-var(20px);
      @include flex(center, center, column);
      background-color: $dark-purple-6;
      border-radius: 8px;
      width: 100%;
      margin-top: 20px;

      &-wrapper {
        @include flex(center, center, column);
        gap: 10px;
        width: 100%;
      }

      &-icon {
        @include width-height-same(13px);
      }

      &-currency {
        @include flex(space-between, center, row);
        width: 100%;

        &-disabled {
          opacity: 0.2;
          pointer-events: none;
        }

        &-title {
          @include font(14px);
        }
      }

      &-voucher {
        @include flex(space-between, center, row);
        width: 100%;
        opacity: 0.2;
        pointer-events: none;

        &-title {
          @include font(14px);
        }

        &-arrow {
          @include flex(center, center, row);
          @include font-semi(14px);
          transform: translateX(7px);
          color: $light-purple;
          white-space: pre;
          cursor: pointer;
        }
      }

      &-sub {
        @include font(14px);
        @include flex(space-between, center, row);
        width: 100%;
      }

      &-total {
        @include font-semi(18px);
        @include flex(space-between, center, row);
        width: 100%;
      }

      &-button {
        @include purple-button(14px);
        width: 100%;

        &-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}