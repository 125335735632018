@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.image-preview {
  background-color: transparent;

  &-gallery {
    max-width: 400px;
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @media (min-width: $min-mobile-width-sm) and (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
      max-width: 700px;
    }

    @media (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
      max-width: 700px;
    }
  }

  .modal {
    &-header {
      @include padding-one-var();
      border-bottom: none;

      @media (max-width: $max-mobile-width-xs) {
        @include padding-two-var(0, 12px);
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      @include padding(20px);

      @media (max-width: $max-mobile-width-xs) {
        @include padding(20px, 12px, 0, 12px);
      }

      img {
        width: 100%;
      }
    }
  }
}