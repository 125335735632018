$max-desktop-width-lg: 1199px;
$max-desktop-width-sm: 1023px;
$max-tablet-width: 991px;
$max-mobile-width-sm: 767px;
$max-mobile-width-xs: 575px;
$max-mobile-width-xxs: 376px;

$max-mobile-height-md: 780px;
$max-mobile-height-sm: 640px;
$max-mobile-height-xs: 575px;

$min-desktop-width-xxl: 1441px;
$min-desktop-width-xl: 1200px;
$min-desktop-width-md: 1024px;
$min-desktop-width-sm: 992px;
$min-tablet-width: 768px;
$min-mobile-width-sm: 576px;

$min-width-grid-item-md: 900px;
$min-width-grid-item-sm: 600px;

$max-button-width: 200px;
$max-button-width-mobile: 400px;
$button-border-radius: 40px;

$border-radius-desktop: 15px;
$border-radius-mobile: 10px;

$max-layout-width: 1306px;