@import '../../baseStyles/colors.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$url: '../../assets/';
$icons-url: '../../assets/icons/';
$logo-url: '../../assets/logo/';
$profile-url: '../../assets/profile/';

.header {
  @include padding-one-var(15px);
  background-color: transparent;

  @media (max-width: $max-mobile-width-sm){
    @include position-top-right(fixed, 0, 0);
    @include padding-two-var(15px, 5px);
    width: 100%;
    background-color: $dark-purple-1;
    z-index: 10;
  }

  &-menu-icon {
    @include margin(1px, 0, 1px);
    cursor: pointer;
  }

  &-profile-pic {
    @include width-height-same(34px);
    @include icon-image('profile-pic', false, $profile-url);
    @include background-position-size();
    @include margin-two-var(0, 3px);
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &-dot {
      @include width-height-same(11px);
      background-color: $pink-1;
      border-radius: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  
  &-logo {
    @include icon-image('logo', true, $logo-url);
    @include width-height(175px, 34px);
    @include margin-two-var(0, auto);
    @include background-position-size(center, 175px);
    display: block;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &-cart {
    @include width-height-same(100%);
    position: relative;
    cursor: pointer;

    &-image {
      @include position-float-top-right(50%, 8px);
      @include width-height(24px, 22px);
      @include icon-image('gamepass-cart', true, $icons-url);
      @include background-position-size();
      transform: translateY(-50%);
    }

    &-count {
      @include margin(-4px, 0, 0, 16px);
      @include padding-one-var(4px);
      @include font-semi(12px);
      @include flex(center, center, row);
      @include width-height(fit-content, 18px);
      background-color: $pink-6;
      border-radius: 2.75rem;
      border: $cart-dot-border;
      position: relative;
    }
  }
}