@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.beta-code {
  @include background-position-size();
  padding-bottom: 30px;

  &-wrapper {
    @include margin-two-var(0, -30px);
    width: calc(100% + 60px);
    max-width: none;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-header {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-content {
    @include margin-two-var(0, auto);
    @include padding-two-var(0, 30px);
    @include font-light(14px);
    @include width-height(100%, calc(var(--vh, 1vh) * 100 - 102px));

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
      height: calc(var(--vh, 1vh) * 100 - 92px);
    }

    &-inner {
      @include flex(space-between, center, column);
      @include margin-two-var(0, auto);
      @include width-height-same(100%);
      max-width: 800px;

      @media (max-width: $max-mobile-width-sm) {
        max-width: none;
      }
    }

    &-top {
      @include flex(flex-start, center, column);
      gap: 20px;

      &-inner {
        width: auto;
        max-width: $max-button-width-mobile;
      }
    }

    &-bottom {
      @include flex(flex-end, center, column);
      gap: 15px;
      width: auto;
      max-width: $max-button-width-mobile;
    }
  }

  &-banner {
    @include margin-one-var();
    width: 100%;
    border-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-xs) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
      border-radius: 0;
    }
  }

  &-description {
    @include margin(0, auto, 20px, auto);
    @include font-semi(18px);
    text-align: center;
    width: 100%;
  }

  &-form {
    &-label {
      @include font(12px);
      color: $white;
      margin-bottom: 10px;
    }

    &-input {
      @include font-semi(24px);
      @include padding-one-var(15px);
      width: 100%;
      color: $white;
      background-color: $dark-purple-7;
      border: $purple-border;
      border-radius: 5px;

      &:focus {
        outline: none;
      }

      &-error {
        border: $pink-border;
      }
    }
  }
  
  &-checkbox {
    @include font(12px);
    color: $light-purple;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    max-width: 483px;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;
    }

    &-label {
      display: inline-block;
      margin-left: 30px;

      a {
        @include font-bold(12px);
        @include padding-one-var();
        @include link($pink-1, $pink-1);
        background-color: transparent;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .checkbox {
      @include width-height-same(20px);
      position: absolute;
      top: 0;
      left: 0;
      background-color: $dark-purple-2;
      border: $white-border-checkbox;
      border-radius: 5px;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ .checkbox {
      background-color: $grey-2;
    }

    & input:checked ~ .checkbox {
      border: $pink-border-checkbox;
      background-color: $pink-1;

      &:after {
        display: block;
      }
    }

    & .checkbox::after {
      @include width-height(4px, 9px);
      left: 5px;
      top: 2px;
      border: $white-border-thick;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &-error-message {
    @include font(12px);
    margin-top: 10px;
  }

  &-button {
    @include purple-button();
    width: 100%;

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-signup {
    @include font(14px);
    color: $white;
    text-align: center;

    a {
      @include font-semi(14px);
      @include link($pink-1, $pink-1);
    }
  }
}