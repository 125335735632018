@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$url: '../../assets/icons/footer-tabs/';

.footer {
  @include padding-one-var(10px);
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 5;
  background-color: $dark-purple-1;

  &-disable {
    pointer-events: none;
    z-index: 2000;
  }

  &-menu {
    @include flex(space-evenly, center, row);
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: 545px;

    &-item {
      display: inline-block;
      text-align: center;
      vertical-align: bottom;
      width: 20%;
      position: relative;
      cursor: pointer;

      &-hide {
        display: none;
      }

      &-arrow {
        @include position-float-top-left(-20px, 50%);
        @include width-height-same(0);
        border: 15px solid transparent;
        border-top: 0;
        border-bottom: 15px solid $white;
        transform: translateX(-50%) rotate(180deg);
        z-index: 101;

        @media (max-width: $max-mobile-width-sm) {
          @include position-float-top-left(-20px, 50%);
        }
      }

      &-link {
        @include link($grey-3, $grey-3);

        &-active {
          @include link($pink-1, $pink-1);
        }
      }

      &-custom-link {
        @include width-height(100%, 40px);
        display: block;
        position: relative;

        &-icon {
          @include width-height(56px, auto);
          margin-top: -10px;
        }
      }

      &-icon {
        @include width-height-same(25px);
        @include margin-two-var(0, auto);
        @include background-position-size(center, 25px);
        position: relative;

        &-home {
          @include icon-image('home-inactive', true, $url);

          &-active {
            @include icon-image('home-active', true, $url);
          }
        }

        &-inbox {
          @include icon-image('inbox-inactive', true, $url);

          &-active {
            @include icon-image('inbox-active', true, $url);
          }
        }

        &-wallet {
          @include icon-image('wallet-inactive', true, $url);

          &-active {
            @include icon-image('wallet-active', true, $url);
          }
        }

        &-profile {
          @include icon-image('profile-inactive', true, $url);

          &-active {
            @include icon-image('profile-active', true, $url);
          }
        }

        &-win-prizes {
          @include icon-image('win-prizes-inactive', true, $url);

          &-active {
            @include icon-image('win-prizes-active', true, $url);
          }
        }
      }

      &-name {
        @include font-medium(10px);
      }
    }
  }
}