@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$referral-url: '../../assets/referral/';

.game-pass-mechanics {
  padding-bottom: 30px;

  &-wrapper {
    @include margin-two-var(0, -30px);
    width: calc(100% + 60px);
    max-width: none;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-header {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-content {
    @include margin-two-var(0, auto);
    @include padding-two-var(0, 30px);
    @include font-light(14px);
    width: 100%;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
    }

    ul {
      @include margin-two-var(15px);
      counter-reset: section;
      list-style-type: none;
      padding-left: 0;

      li {
        @include flex(flex-start, flex-start, row);
        width: 100%;
        max-width: fit-content;

        &::before {
          counter-increment: section;
          content: counter(section, disc);
          width: 15px;
        }

        div {
          width: calc(100% - 15px);
        }
      }
    }
  }

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
  }

  &-pass {
    @include margin(0, auto, 30px, auto);
    width: 100%;
    max-width: $min-tablet-width;

    img {
      @include margin(0, auto, 20px, auto);
      @include width-height(100%, auto);
      max-width: $max-mobile-width-xs;
      display: block;
    }
  }

  &-faqs {
    @include margin(30px, auto, 30px, auto);
    width: 100%;
    max-width: $min-tablet-width;

    &-mobile {
      @include margin(0, auto, 30px, auto);
    }
  }

  &-faq {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &-question {
      @include font-semi(14px);
    }

    &-answer {
      @include font(12px);
    }
  }
}