@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.otp {
  &-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      @include font-semi(14px);
      @include padding-one-var();
      color: $pink-1;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
    
  &-digit {
    @include font(24px);
    width: calc(100% - 10px) !important;
    height: 50px;
    border: $purple-border-thick;
    border-radius: $border-radius-mobile;
    color: $white;
    background-color: $dark-purple-1-translucent;
    outline: none;
    text-align: center;

    &-focused {
      border: $pink-border-thick;
    }
  }

  &-label {
    @include font-semi(16px);
    margin-bottom: 20px;
    width: auto;
    max-width: 320px;
    text-align: center;
  }

  &-input-grid {
    display: flex;
    flex-direction: row;

    div {
      justify-content: center;
    }
  }

  &-timer {
    @include font(14px);
    @include padding-one-var();
    margin-top: 20px;
    color: $light-purple;
    background-color: transparent;
    border: none;
    text-align: center;

    &-email {
      margin-top: 0px;
    }

    button {
      @include font-semi(14px);
      @include padding-one-var();
      color: $pink-1;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &-or {
    @include font(14px);
    @include margin-two-var(8px);
    color: $light-purple;
  }

  &-submit {
    @include margin(auto, 0, 20px);
    @include purple-button(16px);
    width: 100%;
    max-width: 400px;
  }
}