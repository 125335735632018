@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.step {
  margin-bottom: 5px;
  position: relative;

  &-indicator {
    width: 11px;
    display: inline-block;
  }

  &-bullet {
    @include width-height-same(11px);
    display: inline-block;
    background-color: $grey-2;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    z-index: 1;

    &-active {
      background-color: $pink-1;
      outline: $step-border;
    }
  }

  &-connector {
    @include width-height(1px, 100%);
    position: absolute;
    background-color: $grey-2;
    top: 11px;
    left: 5px;

    &-active {
      background-color: $pink-1;
    }
  }

  &-text {
    @include font(12px);
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 27px);
    margin-left: 15px;
  }
}