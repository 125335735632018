@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.nft-item {
  padding-bottom: 30px;

  &-back-mobile {
    @include padding-one-var();
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-top: none;
  }

  &-image {
    @include responsive-inline-block(450px);
    @include padding-one-var(10px);
    vertical-align: top;
    text-align: center;
    position: relative;
    border: $purple-border;
    border-radius: $border-radius-desktop;

    @media (max-width: $max-tablet-width) {
      @include responsive-inline-block(350px);
      padding-bottom: 20px;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var();
      width: 100%;
      border: none;
    }

    img {
      @include margin-one-var();
      border-radius: $border-radius-mobile;
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &-info {
    @include responsive-inline-block(calc(100% - 480px));
    @include margin(20px, 0, 0, 30px);
    vertical-align: top;

    @media (max-width: $max-tablet-width) {
      @include responsive-inline-block(calc(100% - 380px));
    }

    @media (max-width: $max-mobile-width-sm) {
      @include margin(20px);
      text-align: center;
    }
  }

  &-name {
    @include font-semi(24px);
    margin-bottom: 10px;
    line-height: 1;
  }

  &-description {
    @include font-light(14px);
    margin-bottom: 20px;

    @media (max-width: $max-tablet-width) {
      @include font-light();
    }
  }

  &-buy-button {
    @include purple-button();
    @include margin(20px);
    width: 100%;
    max-width: 400px;
  }
}