
@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.gamepass {
  &-inventory-section {
    border-top: none;
    padding-top: 0;
  }

  &-timer {
    &-container {
      @include flex(flex-start, center, row);
      width: 23.5%;

      @media (max-width: $max-desktop-width-lg) {
        width: 100%;
      }
    }

    &-inner {
      @include flex(flex-start, center, row);
      width: 100%;
    }

    &-empty {
      @include flex(center, center, column);
      @include padding-two-var(15px, 10px);
      @include width-height(100%, 93px);
      margin-left: 15px;
      background-color: $light-purple-translucent;
      border-radius: 5px;
      border: none;

      @media (max-width: $max-mobile-width-xs) {
        @include padding-one-var(5px);
        margin-left: 20px;
        max-width: 100%;
        max-height: 60px;
      }

      &-text {
        @include font-light(12px);
        @include margin-one-var();
        @include padding-two-var(5px);
        color: $white;
      }

      &-buy {
        @include font-bold(12px);
        @include margin-one-var();
        @include padding-one-var();
        text-transform: uppercase;
        color: $pink-6;
      }

      &-icon {
        @include font-light(12px !important);
        margin-bottom: 2px;
      }
    }

    &-active {
      @include flex(center, flex-start, column);
      margin-left: 15px;
      max-height: 60px;
      width: 100%;

      @media (max-width: $max-mobile-width-xs) {
        margin-left: 20px;
      }
    }
  }

  &-ticket {
    height: 93px;

    @media (max-width: $max-mobile-width-xs) {
      height: 59px;
    }

    &-inactive {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  &-text-container {
    &-text {
      @include font-light(12px);
      @include margin-one-var();
      @include padding-two-var(5px);
    }

    &-duration {
      @include font-semi(18px);
      @include margin-one-var();
      @include padding-one-var();
    }

    &-expiry-date {
      @include font-light(12px);
      @include margin-one-var();
      @include padding-one-var();

      @media (max-width: $max-tablet-width) {
        max-width: 100%;
      }
    
      &-bold {
        @include font-semi(12px);
      }
    }
  }

  &-empty {
    @include flex(center, center, column);
    @include font-light(14px);
    @include padding-two-var(20px);
    @include width-height(100%, 130px);
    background: $light-purple-translucent;
    border-radius: 8px;
    margin-top: 30px !important;
  }
}

.gamepasses {
  @include flex(space-between, space-between, column);
  @include font-semi(14px);
  @include padding-two-var(15px, 20px);
  @include margin-two-var(10px);
  @include width-height(100%, max-content);
  background-color: $dark-purple-7;
  border-radius: $border-radius-mobile;
  text-align: left;
  gap: 15px;

  @media (max-width: $max-mobile-width-xs) {
    gap: 10px;
  }

  &-container {
    @include flex(space-between, center, row);
  }

  &-pass {
    @include flex(flex-start, flex-start, row);
    gap: 10px;
  }

  &-ticket {
    width: 25px;
    
    @media (max-width: $max-mobile-width-xs) {
      margin-top: 4px;
    }
  }

  &-section {
    border-top: none;
    @include padding(20px);
  }

  &-texts {
    @include flex(flex-start, flex-start, column);
  }

  &-name {
    @include padding-one-var();
    @include margin-one-var();
    @include font-semi(14px);
    
    @media (max-width: $max-mobile-width-xs) {
      max-width: 150px;
    }
  }

  &-quantity {
    @include padding-one-var();
    @include margin-one-var();
    @include font(14px);
    color: $light-purple;
  }

  &-expiry {
    @include font-light(12px);  
    @include padding-one-var();
    @include margin-one-var();
    color: $pink-6;

    &-date {
      @include font-semi(12px);
    }
  }

  &-button {
    @include purple-button-reverse(12px);
    @include padding-two-var(10px);
    text-transform: uppercase;
    width: 100%;
    max-width: 100px;
    border: none;
    outline: none;

    @media (max-width: $max-mobile-width-xxs) {
      max-width: 80px;
    }

    &-disabled {
      opacity: 0.5;
      cursor: default !important;
    }
  }

  &-empty {
    @include flex(flex-start, center, column);
    @include padding-two-var(52px);
    background: $light-purple-translucent;
    border-radius: $border-radius-mobile;
    gap: 10px;
   
    &-text {
      @include font-light(14px);
      @include padding-one-var();
      @include margin-one-var();
    }

    &-button {
      @include purple-button-reverse(12px);
      @include padding-two-var(10px);
      @include margin-one-var();
      text-transform: uppercase;
      width: 100%;
      max-width: 200px;

      @media (max-width: $max-tablet-width) {
        width: 100%;
        max-width: 150px;
      }
    
      &-disabled {
        opacity: 0.5;
        cursor: default !important;
      }
    }
  }
}