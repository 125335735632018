@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.globe-otp {
  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var(20px);
  }

  &-section {
    border: none;
    padding-top: 0;
  }

  &-resend {
    @include font-light(14px);
    text-align: center;

    &-text {
      @include font(14px);
      color: $pink-1;
      text-align: center;
      cursor: pointer;
    }
  }

  &-text {
    @include font-semi(17px);
    text-align: center;
  }

  &-input {
    @include font-semi(24px);
    @include padding-one-var(16px);
    border: $purple-border;
    background-color: $dark-purple-7;
    border-radius: 8px;
    outline: none;
    color: $white;
    width: 100%;

    &-title {
      @include font-semi();
      color: $light-purple;
    }
  }

  &-container {
    @include flex(center, center, row);
    margin-top: 50px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 400px;
  }
}