@import '../../baseStyles/colors.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

%game-center-layout {
  @include margin-two-var(0, auto);
  width: calc(100% - 60px);
  max-width: $max-layout-width;

  @media (max-width: $max-mobile-width-sm) {
    width: 100%;
  }
}

.game-center {
  @media (min-width: $min-tablet-width) {
    @include padding-two-var(30px, 0);
  }

  &-inner {
    max-width: none;
  }

  &-featured {
    @extend %game-center-layout;
  }

  &-section {
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }

    &-themed {
      @include padding-two-var(30px);
      background-color: $dark-pink-3;
      background-image: $wen-lambo-list-page-gradient;
      margin-bottom: 30px;

      @media (max-width: $max-mobile-width-sm) {
        @include margin(0, -20px, 30px, -20px);
        @include padding-two-var(30px, 20px);
      }
    }

    &-inner {
      @extend %game-center-layout;
      border-top: none;
      padding-top: 0;
    }
  }

  .featured-section {
    margin-top: 0;
  }
}