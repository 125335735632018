@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$transactions-url: '../../assets/transactions/';

.transactions {
  &-section {
    @include padding(0);
    border-top: none;

    @media (max-width: $min-mobile-width-sm) {
      @include padding-two-var(20px);
    }

    &-header {
      @media (max-width: $min-mobile-width-sm) {
        @include margin-two-var(0, 20px);
      }
    }
  }

  &-container {
    @media (max-width: $min-mobile-width-sm) {
      overflow: visible;
      padding: 0;
    }
  }

  &-toast {
    color: $white;
    background-color: $dark-blue-7;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    width: 100%;
    max-width: 400px;
    justify-content: space-between;
    height: 100%;
  
    &-container {
      padding-left: 0 !important;
      
      &.fade .modal-dialog {
        transform: translate(90vh, 0);
      }
  
      &.show .modal-dialog {
        transform: translate(0, 0);
      }
    }
  
    &-bg {
      background-color: $dark-purple-2;
      
      &.show {
        opacity: 0.75;
      }
    }

    &-header {
      @include padding-two-var(17px, 15px);
      @include flex(center, center, row);
      color: $white;
      border-bottom: $dark-blue-border;
      position: relative;
      cursor: pointer;

      &-filters {
        @include font-semi(18px);
      }

      &-clear {
        @include font-semi(14px);
        color: $light-purple;
        position: absolute;
        top: 20px;
        right: 15px;
      }
    }

    &-body {
      @include flex(center, flex-start, column);
      width: 100%;
      max-width: 420px;
      gap: 15px;

      &-type {
        @include font-medium(14px);
        @include padding-two-var(5px, 20px);
        border: 1px solid $white;
        background-color: transparent;
        border-radius: 6px;
        color: $white;

        &-active {
          border: 1px solid transparent;
          background-color: $purple-1;
          border: none;
        }
      }

      &-wrapper {
        @include flex(flex-start, center, column);
        @include margin-one-var();
        @include padding-two-var(15px);
        width: 100%;
      }

      &-section {
        @include flex(center, flex-start, column);
        @include padding(0, 15px, 20px, 15px);
        width: 100%;
        border-bottom: $dark-blue-border;

        &-last {
          border-bottom: none;
        }
      }

      &-title {
        @include font-semi(14px);
        padding-bottom: 15px;
        width: 100%;
        text-align: left;
      }

      &-date {
        @include font-semi(14px);
        padding-bottom: 15px;
        width: 100%;
        text-align: left;

        &-title {
          @include font-light(11px);
          color: $light-purple;
          text-align: left;
        }

        &-line {
          @include flex(center, center, column);
          padding-top: 20px;
        }

        &-icon {
          @include icon-image('calendar', false, $transactions-url);
          @include width-height(20px, 20px);
          @include background-position-size(center, cover);
          position: absolute;
          top: 10px;
          right: 15px;
          pointer-events: none;
        }

        &-picker {
          @include padding-two-var(10px, 20px);
          @include font-semi(14px);
          background-color: $dark-blue-9;
          outline: none;
          border: none;
          border-radius: 30px;
          color: $white;
          width: 100%;
          max-width: 250px;
          cursor: pointer;
        }
      }
    }

    &-footer {
      @include font-semi(16px);
      @include flex(center, center, row);
      border-top: none;
      color: $pink-1;
    }
  
    &-dialog {
      @include margin-one-var();
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      align-items: flex-end;
      justify-content: flex-end;
  
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }

    &-buttons {
      @include padding(0, 15px, 15px, 15px);
      width: 100%;
    }

    &-reset-button {
      @include transparent-button();
      width: 100%;
    }

    &-apply-button {
      @include purple-button();
      width: 100%;
    }
  }
}

.react-datepicker {
  border: none;
  background-color: transparent;

  &-popper[data-placement^=top] {
    padding-bottom: 0;
  }

  &__header {
    background-color: $purple-1;
  }

  &__triangle {
    visibility: hidden;
  }

  &__current-month {
    @include font-semi(16px);
    color: $white;
  }

  &__navigation {
    height: 45px;
  }

  &__day {
    @include font(16px);
    @include margin-one-var(5px);
    color: $white;

    &:hover {
      color: $purple-1;
      background-color: $white;
    }

    &-name {
      @include font-semi(16px);
      @include margin-one-var(5px);
      color: $white;
    }

    &--outside-month, &--disabled {
      opacity: 0.5;
    }

    &--selected, &--keyboard-selected {
      color: $purple-1;
      background-color: $white;
    }
  }

  &__month {
    @include margin-two-var(5px);
    
    &-container {
      background-color: $purple-1;
      border-radius: 12px;
    }

    &-text {
      @include font(16px);
      @include margin-one-var(5px);
      color: $white;

      &:hover {
        color: $purple-1;
        background-color: $white;
      }

      &--keyboard-selected {
        color: $purple-1;
        background-color: $white !important;
      }
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__year {
    @include margin-two-var(5px);

    &-wrapper {
      max-width: 222px;
    }

    &-text {
      @include font(16px);
      @include margin-one-var(5px);
      color: $white;

      &:hover {
        color: $purple-1;
        background-color: $white;
      }

      &--keyboard-selected, &--selected {
        color: $purple-1;
        background-color: $white;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
  }
}

