@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/colors.scss';
@import '../../baseStyles/settings.scss';

$prizes-url: '../../assets/spin/prizes/';
$redeem-url: '../../assets/spin/';

.redeem {
  @include icon-image('desktop-bg', false, $redeem-url);
  @include width-height-same(100%);
  @include background-position-size(center, cover);
  position: absolute;
  top: 0;
  z-index: 3;
  padding: 20px;

  @media (max-width: $max-mobile-width-xs) {
    @include icon-image('mobile-bg', false, $redeem-url);
  }
  &-inner {
    @include width-height-same(100%);
    @include flex(center, center, column);
  }

  &-container {
    @include width-height-same(100%);
    max-width: 500px;
    transition: 0.3s ease-in-out;
    position: relative;
    display: flex;
    
    &-wrapper {
      @include padding-two-var(30px);
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      margin: auto auto;
    }

    &-load {
        @include icon-image('load50', false, $prizes-url);
        @include width-height-same(250px);
        background-size: cover;
        position: relative;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(200px, 200px);
        }
  
        @media (max-height: 700px) {
          @include width-height(150px, 150px);
        }
      }

      &-load-small {
        @include icon-image('load20', false, $prizes-url);
        @include width-height-same(240px);
        background-size: cover;
        position: relative;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(180px, 180px);
        }
  
        @media (max-height: 700px) {
          @include width-height(150px, 150px);
        }
      }

      &-gcash {
        @include icon-image('gcash', false, $prizes-url);
        @include width-height-same(250px);
        background-size: cover;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(200px, 200px);
        }
  
        @media (max-height: 700px) {
          @include width-height(150px, 150px);
        }
      }


      &-metapup {
        @include icon-image('metapup', false, $prizes-url);
        @include width-height-same(250px);
        background-size: cover;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(200px, 200px);
        }
  
        @media (max-height: 700px) {
          @include width-height(150px, 150px);
        }
      }

      &-mgc {
        @include icon-image('mgc', false, $prizes-url);
        @include width-height-same(250px);
        background-size: cover;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(200px, 200px);
        }
  
        @media (max-height: 700px) {
          @include width-height(150px, 150px);
        }
      }

      &-iphone {
        @include icon-image('iphone', false, $prizes-url);
        @include width-height-same(250px);
        background-size: cover;

        @media (max-width: $max-mobile-width-xs) {
          @include width-height(200px, 200px);
        }
  
        @media (max-height: 798px) {
          @include width-height(150px, 150px);
        }
      }

    &-icon {
      @include icon-image('stage', false, $redeem-url);
      @include width-height(100%, 100px);
      @include background-position-size(center, cover);

      @media (max-width: $max-mobile-width-xs) {
        height: 90px;
      }

      @media (max-height: 798px) {
        height: 70px;
      }
    }

    &-title {
      @include font-bold(20px);
      text-align: center;
    }

    &-desc {
      @include font(14px);
      text-align: center;

      @media (max-height: 700px) {
        @include font(13px);
      }
    }

    &-button {
      @include white-button();
      color: $purple-1;
      width: 100%;

      &:hover {
        color: $purple-1;
      }

      &-disabled {
        @include white-button();
        color: $purple-1;
        width: 100%;
        opacity: 0.5;
        pointer-events: none;

        &:hover {
          color: $purple-1;
        }
      }
    }

    &-input {
      @include padding(15px, 60px, 15px, 70px);
      @include font(14px);
      border-radius: 30px;
      width: 100%;
      outline: none;
      border: none;
      background-color: $pink-5;
      color: $white;
      

      &-country {
        @include width-height(60px, 30px);
        @include flex(flex-end, center, row);
        padding-right: 10px;
        @include font(14px);
        text-align: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-right: 1px solid $white;
      }
    }
  }
}