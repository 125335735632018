@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.mgc-conversion {
  text-align: center;
  padding-bottom: 114px;

  @media (max-width: $max-mobile-width-sm) {
    padding-bottom: 20px;
  }

  h1 {
    @include font-semi(20px);
    margin-bottom: 20px;
  }
  
  &-sample {
    @include font(14px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-back {
    @include padding-one-var();
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    background-color: transparent;
    cursor: pointer;

    &-icon {
      @include link($white, $white);
      @include margin(-1px, 0, 0, -5px);
    }
  }

  &-content {
    text-align: left;

    p {
      @include font(14px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  
    ul {
      padding-left: 15px;
    }
  }

  &-got-it {
    @include padding-two-var(40px, 30px);
    position: fixed;
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $dark-purple-1;

    @media (max-width: $max-tablet-width) {
      @include padding-one-var(20px);
    }

    &-btn {
      @include purple-button(16px);
      @include margin-two-var(0, auto);
      @include padding-two-var(15px);
      width: 100%;
      max-width: 400px;
      display: block;
    }
  }
}