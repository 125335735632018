@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.rewards-center {
  position: relative;

  &-container {
    overflow-x: hidden;

    @media (max-width: $max-mobile-width-xs) {
      @include padding-two-var(20px);
      overflow: visible;
    }
  }

  &-inner {
    width: 100%;
    max-width: none;
  }

  &-section {
    width: 100%;
    padding: 0;
    border-top: none;

    &-header {
      @include margin(0, auto, 20px, auto);
      max-width: $max-layout-width;
      height: 26px;
      margin-bottom: 20px;

      @media (max-width: $max-mobile-width-xs) {
        @include margin(0, 20px, 15px, 20px);
      }
    }
  }
  
  &-banner {
    @include width-height-same(100%);
    @include margin(0, auto, 20px, auto);
    display: block;
    max-width: $max-layout-width;
    border-radius: $border-radius-desktop;

    @media (max-width: $max-tablet-width) {
      border-radius: $border-radius-mobile;
    }

    @media (max-width: $max-mobile-width-sm) {
      margin-bottom: 0;
    }

    @media (max-width: $max-mobile-width-xs) {
      border-radius: 0;
    }
  }
  
  &-tabs {
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: $max-layout-width;

    & > .rb-tabs-header {
      border-bottom: 2px solid $dark-blue-7 !important;
      position: sticky !important;
      top: -32px;
      right: 0;
      background-color: $dark-purple-1;
      z-index: 10;

      @media (min-width: $max-tablet-width) {
        @include flex(center, center, row);
        gap: 20px;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include margin(0px, 0px, 10px, 0px);
        top: 60px;
      }

      & > .rb-tabs-items  {
        & > .rb-tabs-item.active {
          font-weight: 600 !important;
        }
      }
    }

    & > .rb-tabs-content {
      @include margin-two-var(0, auto);
      @include padding-two-var(30px);
      max-width: 957px;

      @media (max-width: $max-mobile-width-xs) {
        @include padding-one-var(20px);
      }
    }

    &-indicator {
      background-color: $pink-1 !important;
      height: 3px !important;
      bottom: -2.5px !important;
    }

    &-lists {
      justify-content: space-between;
      border-bottom: none !important;

      @media (min-width: $min-tablet-width) {
        gap: 40px;
      }
    }
  }

  &-tab {
    &-title {
      @include font(14px); 
      @include padding-two-var(10px !important, 16px !important);
      width: 100%;
      text-align: center;

      & > .active {
        @include font-semi(14px);
      }
    }
  }

  &-rewards {
    &-row {
      @include margin-two-var(0, -15px);
      row-gap: 30px;
    }

    &-cell {
      @include padding-two-var(0, 15px);
    }

    &-empty {
      @include font(14px);
      text-align: center;
    }
  }

  &-reward {
    @include width-height(100%, 165px);
    display: flex;
    position: relative;

    &-status {
      @include padding(0, 12px, 0, 8px);
      @include font-semi(12px);
      text-transform: uppercase;
      display: inline-block;
      margin-top: 15px;
      border-top-right-radius: $border-radius-mobile;
      border-bottom-right-radius: $border-radius-mobile;
      box-shadow: $reward-status-shadow;

      &-active {
        background-color: $green-2;
      }

      &-expired {
        background-color: $red-2;
      }

      &-used {
        background-color: $grey-8;
      }

      &-redeemed {
        background-color: $orange-3;
      }
    }

    &-info {
      &-top {
        width: 100%;
      }

      &-title {
        @extend %text-ellipsis;
        @include link($white, $white);
        @include font-semi(14px);
      }

      &-subtitle {
        @include font(14px);
      }

      &-expiry-date {
        @include font-medium(10px);
        color: $light-purple;
      }

      &-button {
        @include purple-button-reverse(12px);
        @include padding-two-var(13px);
        width: 100%;
      }

      &-view {
        @include link($white, $white);
        @include font-semi(12px);
        @include flex(center, center, row);

        &-icon {
          margin-top: -1px;
        }
      }
    }

    &-button {
      @include purple-button-reverse(12px);
      width: 100%;
    }
  }

  &-voucher-input {
    @include padding-two-var(20px, 30px);
    @include margin(0, -30px, 10px, -30px);
    background-color: $dark-purple-7;
    width: calc(100% + 60px);

    @media (max-width: $max-mobile-width-sm) {
      @include margin(20px, -30px, 10px, -30px);
    }

    @media (max-width: $max-mobile-width-xs) {
      @include padding-two-var(30px, 20px);
      @include margin(0, 0, 10px);
      width: 100%;
    }

    &-inner {
      @include flex(center, center, row);
      @include margin-two-var(0, auto);
      width: 100%;
      max-width: 560px;
    }

    &-text-field {
      @include font(14px);
      @include padding-two-var(12px, 20px);
      width: calc(100% - 75px);
      border: none;
      border-top-left-radius: $button-border-radius;
      border-bottom-left-radius: $button-border-radius;
      color: $white;
      background-color: $light-purple-translucent;

      &:focus {
        outline: none;
      }
    }

    &-button {
      @include purple-button-reverse(12px);
      @include padding-two-var(13.5px);
      width: 75px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &-error {
      @include margin(15px, auto, 0, auto);
      @include font(12px);
      color: $red-2;
      width: 100%;
      max-width: 560px;
    }
  }
}