@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';

.withdrawal-confirmation {
  @include padding(20px, 20px, 0px, 20px);
  background-color: $dark-purple-1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 600px;
  
  @media (max-width: $max-mobile-width-sm) {
    @include padding(20px, 20px, 0px, 14px);
    max-width: 100%;
  }

  &-bottom {
    @include flex(flex-end, center, column);
    flex-grow: 1;
  }

  &-wrapper {
    @include flex(center, center, column);
    width: 100%;
    margin-top: 20px;
  }

  &-image {
    @include width-height-same(100%);
    object-fit: contain;
    max-width: 280px;
  } 

  &-details {
    width: 100%;

    &-container {
      @include flex(center, center, column);
      @include padding-two-var(15px, 10px);
      width: 100%;
      background-color: $dark-purple-7;
      margin-top: 20px;
      border-radius: 8px;
      gap: 10px;
    }

    &-title {
      @include font(14px);
      text-align: left;
    }

    &-view {
      @include font-bold();
      color: $pink-1;
      cursor: pointer;
      text-align: end;

      &-wrapper {
        @include font(14px);
        @include flex(flex-end, center, row);
        gap: 5px;
      }
    }

    &-text {
      @include font(14px);
      text-align: right;
    }

    &-value {
      @include font-semi(14px);
      padding-bottom: 0;
      text-align: right;
      white-space: pre;
    }

    &-mgc-icon {
      @include width-height-same(15px);
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size();
      margin-right: 5px;
    }
  }

  &-container {
    @include flex(center, flex-start, column);
    @include padding-two-var(20px, 15px);
    background-color: $confirm-widthrawal-bg;
    gap: 10px;
    border: 1px solid $purple-9;
    border-radius: 8px;

    &-title {
      @include font-semi(14px);
      @include padding-one-var();
      @include margin-one-var();
    }
    &-text {
      @include font-light(14px);
      @include padding-one-var();
      @include margin-one-var();
      text-align: left;
    }
  }

  &-button {
    &-confirm {
      @include purple-button(12px);
      width: 100%;
      margin-top: 20px;
    }

    &-cancel {
      @include font-bold(12px);
      @include button($dark-grey-2);
      width: 100%;
      margin-top: 10px;
    }
  }

  &-popup {
    @include margin-one-var();
    @include flex(center, center, column);
    color: $white;
    background-color: $dark-blue-4;
    border: none;
    max-width: 600px;
    border-radius: 7px;
    border: $purple-border;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(100vw, 100%);
      border: none;
      max-width: 100%;
    }

    &-close {
      @include icon-image('close', false, $icons-url);
      @include width-height-same(29px);
      @include background-position-size(center, cover);
      @include position-float-top-right(-12px, -12px);
      @include padding-one-var(2px);
      z-index: 2;
      cursor: pointer;
  
      @media (max-width: $max-mobile-width-sm) {
        display: none;
        pointer-events: none;
      }
    }
        
    &-container {
      &.fade .modal-dialog {
        @media (max-width: $max-mobile-width-sm) {
          @include margin-one-var();
        }
      }
      
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
      
    &-bg {
      background-color: $black;
      opacity: 0.5;

      &.show {
        @media (max-width: $max-mobile-width-sm) {
          background-color: $dark-blue-4;
        }
      }
    }
      
    &-dialog {
      @include flex(center, center, row);
      @include padding-two-var(30px);
      justify-content: center;
      margin: 0;
      max-width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        @include padding();
      }
    }
      
    .modal {
      &-body {
        height: 100%;
        padding: 0;
      }
    }
  }

  &-toast {
    @include padding-two-var(20px);
    color: $white;
    background-color: $dark-purple-1;
    width: 100%;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 0px 30px $desktop-toast-box-shadow;
        
    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var();
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      background-color: $dark-blue-7;
    }

    &-title {
      @include font-semi(12px);
      max-width: 100px;
      word-wrap: break-word;
      text-align: left;
    }
    
    &-wrapper {
      @include flex(center, center, row);
      @include padding-one-var();
      @include margin-one-var();
      width: 100%;

      & > p {
        @include margin();
        word-break: break-all;
      }
    }

    &-address {
      word-wrap: break-word;
      text-align: left;
    }

    &-text {
      @include padding-one-var();
      @include font-light(16px);
      max-width: 522px;
 
      @media (max-width: $max-tablet-width) {
        max-width: 380px;
      }
      
      @media (max-width: $max-mobile-width-sm) {
        @include flex(center, center, column);
        @include font-light(14px);
        border-top: none;
      }
    }
            
    &-container {
      overflow: hidden;
    
      &.fade .modal-dialog {
        @include position-float-bottom-left(0px, 0px);
        transform: translateY(10vh);
      }
    
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
    
    &-bg {
      background-color: $black;
    
      &.show {
        opacity: 0.5;
      }
    }
    
    &-header {
      @include font-semi(18px);
      @include flex(center, center, row);
      border-bottom: none;
      cursor: pointer;
    
      &-close {
        @include position-float-top-right(15px, 15px);
        @include width-height-same(1.2em !important);
          color: $white;
      }
    }
    
    &-dialog {
      @include margin-one-var();
      width: 100vw;
    
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
    
    .modal {
      &-body {
        @include flex(center, center, column);
        @include padding();

        @media (max-width: $max-mobile-width-sm) {
          @include flex(flex-start, flex-start, column);
          @include padding-one-var(20px);
        }
      }
    }
  }

  
}