@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.text-only-toast {
  color: $white;
  background-color: $dark-purple-2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  &-container {
    overflow: hidden;
    padding-left: 0 !important;
    
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-symbol {
    @include margin-one-var();
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }

      .btn-close {
        @include margin-one-var();
        @include padding-one-var();
        @include position-float-top-right(23px, 30px);
      }
    }

    &-body {
      @include padding-two-var(20px, 30px);
      @include font(14px);
    }
  }
}