@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$gamepass-url: '../../../assets/gamepass/';
$icons-url: '../../../assets/icons/';

.free-gamepass {
  &-container {
    text-align: center;
    width: 100%;
    
    &-title {
      @include font-semi(24px);
      text-align: center;
    }

    &-image {
      @include width-height(auto, 233px);
      @include padding-two-var(20px);
      @include flex(center, center, column);
      max-height: 233px;
    }

    &-description {
      @include font-light(14px);
      @include padding-one-var();
      @include margin-one-var();
      width: 100%;
      max-width: 290px;
      text-align: center;
    }

    &-activate-button {
      @include orange-button();
      @include padding-two-var(13px);
      width: 100%;
      margin-top: 20px;
  
      @media (max-width: $max-tablet-width) {
        width: 100%;
      }
  
      &-disabled {
        opacity: 0.5;
        cursor: default !important;
      }
    }

    &-claim-later-button {
      @include font-bold(12px);
      @include button($dark-grey-2);
      @include padding-two-var(10px);
      width: 100%;
      margin-top: 10px;
    }
  }

  &-popup {
    @include icon-image('free-pass-bg', false, $gamepass-url);
    @include width-height-same(100%);
    @include background-position-size(center, cover);
    @include margin-one-var();
    @include padding-two-var(5px, 25px);
    @include flex(center, center, column);
    color: $white;
    border-radius: 16px;
    border: none;
    max-width: 340px;
    
    &-container {
      &.fade .modal-dialog {
        @include padding-two-var(0px, 25px);
        @include flex(center, center, column);
      }
  
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
  
    &-bg {
      background-color: $black;
      &.show {
        opacity: 0.5;
      }
    }

    &-close {
      @include icon-image('close', false, $icons-url);
      @include width-height-same(29px);
      @include background-position-size(center, cover);
      @include position-float-top-right(-12px, -12px);
      @include padding-one-var(2px);
      z-index: 2;
      cursor: pointer;
  
      @media (max-width: $max-mobile-width-sm) {
        @include width-height-same(30px);
        top: -10px;
        right: -10px;
      }
    }
  
    &-header {
      @include font-medium(12px);
      @include flex(flex-end, center, row);
      @include padding(0px, 20px);
      color: $grey-6;
      border-bottom: none;
      cursor: pointer;
    }
  
    &-dialog {
      @include flex(center, center, row);
    }
  
    .modal {
      &-body {
        @include padding-two-var(20px);
        @include flex(center, center, column);
        width: 100%;
      }
    }
  }
}

