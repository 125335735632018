@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.welcome-popup {
  width: calc(100% - 40px);
  max-width: 340px;
  color: $white;
  background-color: $dark-blue-7;
  border-radius: $border-radius-mobile;
  box-shadow: $glow-purple-large;
  
  &-success {
    @include width-height-same(130px);
  }
  
  &-container {
    overflow: hidden;
    background: $desktop-toast-box-shadow-1;

    &.fade .modal-dialog {
      @include position-float-bottom-left(0px,0px);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background: $desktop-toast-box-shadow-1;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100%, 100vh);
    max-width: none !important;
    justify-content: center;
  }

  &-body {
    @include flex(center, center, column);
    @include padding-two-var(0, 30px);
    transform: translateY(-10px);
    width: 100%;
  }

  &-image {
    width: 100%;
    max-width: 150px;
    transform: translateY(10px);
  }

  &-title {
    @include font-semi(18px);
  }

  &-description {
    @include font-light(14px);
    @include padding-two-var(0, 10px);
    text-align: center;
  }

  &-button {
    &-continue {
      @include purple-button-reverse();
      width: 100%;
      cursor: pointer;
    }

    &-later {
      @include font-semi(14px);
      background-color: transparent;
      color: $pink-1;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}