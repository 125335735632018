@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$wen-lambo-stw-bg-url: '../../assets/gamepass/bg/stw/';

%page-content-width {
  width: 100%;
  max-width: $min-tablet-width;
}

.stw-mechanics {
  @include background-image('desktop', $wen-lambo-stw-bg-url, '.png', false);
  @include background-position-size();
  padding-bottom: 30px;

  @media (max-width: $max-mobile-width-sm) {
    @include background-image('mobile', $wen-lambo-stw-bg-url, '.png', false);
    @include background-position-size();
  }

  &-wrapper {
    @include margin-two-var(0, -30px);
    width: calc(100% + 60px);
    max-width: none;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }
  }

  &-dti {
    @include flex(center, center, row);
    @include font-semi(12px);
    @include margin-two-var(0px, 20px);
    padding-top: 10px;
    color: $light-purple;
  }

  &-section {
    @include padding-one-var();
    border-top: none;
  }

  &-header {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-content {
    @include margin-two-var(0, auto);
    @include padding(20px, 30px, 0, 30px);
    @include font-light(14px);
    width: 100%;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(20px, 20px, 0, 20px);
    }
  }

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
    padding-top: 50px;
  }

  &-description {
    @include font(12px);
    padding-bottom: 10px;

    &-local {
      @include font(12px);
      text-align: center;
    }
  }

  ul {
    @include margin-two-var(15px);
    counter-reset: section;
    list-style-type: none;
    padding-left: 0;

    li {
      @include flex(flex-start, flex-start, row);
      width: 100%;
      max-width: fit-content;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        counter-increment: section;
        content: counter(section, disc);
        width: 15px;
      }

      div {
        width: calc(100% - 15px);
      }
    }
  }

  &-dropdown {
    &-toggle {
      @include white-button();
      @include font-semi(14px);
      @include link($purple-1, $purple-1);
      @include padding-two-var(10px, 20px);
      @include margin-two-var(0, auto);
      @include flex(space-between, center, row);
      width: 100%;
      max-width: $min-tablet-width;
      text-transform: capitalize;
    }

    &-list {
      @include position-top-left(fixed, 0, 0);
      @include width-height(99.3vw, 100vh);
      display: none;

      @media (min-width: $min-mobile-width-sm) and (max-width: $max-mobile-height-sm) {
        width: 99vw;
      }
      
      @media (max-width: $max-mobile-height-xs) {
        width: 98.5vw;
      }

      &-visible {
        display: block;
      }

      &-overlay {
        @include width-height-same(100%);
        background-color: $dark-purple-transparent;
      }
    
      &-dialog {
        @include position-bottom-left(fixed, 0, 0);
        @include flex(flex-start, flex-start, column);
        @include margin-one-var();
        @include padding-two-var(20px, 30px);
        width: 100%;
        background-color: $white;
        border: none;
        border-radius: $border-radius-mobile $border-radius-mobile 0 0;
        gap: 10px;
        box-shadow: $tutorial-shadow;
      }

      &-item {
        @include flex(space-between, center, row);
        @include font-semi(14px);
        @include width-height(100%, 24px);
        color: $dark-purple-7;
        cursor: pointer;

        &-selected {
          color: $purple-1;
        }
      }
    }
  }

  &-overview {
    @include margin(30px, auto, 0, auto);
    @extend %page-content-width;

    &-item {
      text-align: center;
      margin-top: 0px;

      img {
        @include margin-two-var(0, auto);
        display: block;
        width: 100%;
        max-width: $max-mobile-height-xs;
      }

      h1 {
        margin-top: -30px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &-prizes {
    &-title {
      @include font-semi(16px);
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
      margin-top: -5px;
    }
    &-items {
      @include flex(flex-start, flex-start, column);
      @include padding-one-var(20px);
      gap: 10px;
      background-color: $light-purple-translucent;
      border-radius: $border-radius-mobile;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    &-item {
      @include padding-one-var(10px);
      width: 100%;
      background-color: $stw-mechanics-translucent;
      border-radius: 5px;

      &-inner {
        @include flex(flex-start, center, row);
        gap: 10px;
        width: 100%;
      }

      &-image {
        @include width-height(40px, auto);
      }

      &-title {
        @include flex(space-between, center, row);
        @include font-semi(14px);
        width: calc(100% - 50px);

        &-collapsible {
          cursor: pointer;
        }
      }

      &-details {
        @include font(12px);
        width: 100%;
        margin-top: 10px;

        &-title {
          @include font-semi(12px);
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }

  &-mega-prize {
    @include font-semi(20px);
    @include margin(30px);
    text-align: center;
  }

  &-eligibility {
    @include margin(-20px, auto, 30px, auto);
    @extend %page-content-width;
  }

  &-carousel {
    background-color: $stw-mechanics-translucent;

    .indicator {
      &-button {
        background-color: $stw-mechanics-carousel-indicator-bg;

        &-active {
          background-color: $white;
        }
      }
    }
  }

  &-faqs {
    @include margin(0px, auto, 0, auto);
    @extend %page-content-width;

    &-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-question {
      @include font-semi(14px);
      margin-bottom: 5px;
    }

    &-answer {
      @include font(12px);
    }
  }
}