@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.beta-confirm-popup {
  width: calc(100% - 40px);
  max-width: 340px;
  color: $white;
  background-color: $dark-blue-7;
  border-radius: $border-radius-mobile;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  align-items: center;

  &-gamepass {
    border-bottom-left-radius: $border-radius-mobile;
    border-bottom-right-radius: $border-radius-mobile;
    gap: 0
  }

  &-success {
    @include width-height-same(130px);
  }
  
  &-container {
    overflow: hidden;

    &.fade .modal-dialog {
      @include position-float-bottom-left(0px,0px);
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100%, 100vh);
    max-width: none !important;
    justify-content: center;
  }

  &-body {
    @include flex(flex-start, center, column);
    @include padding-two-var(100px, 30px);
    gap: 30px;

    &-gamepass {
      @include padding-two-var(90px, 30px);
      gap: 0;
    }
  }

  &-message {
    @include font-semi(18px);
    text-align: center;
  }
}