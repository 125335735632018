@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$launchpad-url: '../../assets/launchpad/';
$spin-url: '../../assets/spin/';

.spin {
  @include flex(center, center, column);
  width: 100%;
  
  @media (max-width: $max-mobile-width-sm){
    position: fixed;
    bottom: -135px;
    left: 0;
  }

  @media (max-height: $max-mobile-width-xs){
    position: fixed;
    bottom: -165px;
    left: 0;
  }

  &-layout {
    @include icon-image('desktop-bg', false, $spin-url);
    @include background-position-size(center, cover);
    @include padding-one-var(20px);
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    position: fixed;
    top: 0;
    z-index: 3;

    @media (max-width: $max-mobile-width-xs){
      @include icon-image('mobile-bg', false, $spin-url);
      overflow: hidden;
    }

    &-sm {
      @include icon-image('sm-desktop-bg', false, $spin-url);
      @include background-position-size(center, cover);
      @include padding-one-var(20px);
      @include width-height(100vw, calc(var(--vh, 1vh) * 100));
      position: fixed;
      top: 0;
      z-index: 3;
  
      @media (max-width: $max-mobile-width-sm){
        @include icon-image('sm-mobile-bg', false, $spin-url);
        overflow: hidden;
      }
  
      &-inner {
        height: 100%;
        position: initial;
      }
    }

    &-inner {
      height: 100%;
      position: initial;
    }
  }
  
  &-back {
      @include position-float-top-left(20px, 20px);
      cursor: pointer;
      z-index: 10;
  }

  &-title {
    @include font-bold(14px);
    text-align: center;
  }

  &-body {
    @include flex(center, center, column);
    @include width-height-same(100%);
    gap: 40px;
    margin-top: 25px;

    &-head {
      @include width-height-same(100%);
      @include margin-two-var(20px);
      max-width: 958px;

      @media (max-width: $max-mobile-width-sm){
        @include margin-two-var(10px);
        max-width: 280px;
      }

      &-sm {
        @include width-height-same(100%);
        @include margin(60px, 0px, 30px, 0px);
        max-width: 958px;

        @media (max-width: $max-mobile-width-sm){
          @include margin-two-var(10px);
          max-width: 270px;
        }

        @media (max-height: $max-mobile-height-sm) {
          max-width: 220px;
        }

        @media (max-height: $max-mobile-width-xs){
          @include margin-two-var(10px);
          max-width: 185px;
        }
      }
    }

    &-title {
      @include font-semi(24px);
      text-align: center;

      @media (max-width: $max-mobile-width-sm){
        @include padding-two-var(0px, 20px);
      }

      @media (max-height: $max-mobile-height-sm) {
        @include font-semi(20px);
        @include padding-two-var(0px, 50px);
      }

      @media (max-height: $max-mobile-width-xs){
        @include font-semi(16px);
        @include padding-two-var(0px, 70px);
      }
    }

    &-spin {
      @include font(14px);
      text-align: center;

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }

    &-mechanics {
      @include flex(center, center, row);
      @include font(14px);
      text-align: center;
      opacity: 1;
      transition: 0.3s ease-in-out;

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }

      &-hide {
        opacity: 0;
        pointer-events: none;
        transition: 0.3s ease-in-out;

        @media (max-width: $max-mobile-width-sm){
          @include font(12px);
        }

        @media (min-width: $min-tablet-width) {
          display: none;
        }

        span {
          margin-left: 5px;
          color: $pink-1;
          cursor: pointer;
        }
      }
      
      span {
        @include flex(center, center, row);
        @include font-medium(14px);
        margin-left: 4px;
        color: $pink-1;
        cursor: pointer;

        @media (max-width: $max-mobile-width-sm){
          @include font-medium(12px);
        }
      }

      @media (min-width: $min-tablet-width) {
        display: none;
      }
    }
  }
}