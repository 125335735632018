@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.activate-gamepass-toast {
  color: $white;
  background-color: $dark-purple-7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  &-info {
    @include flex(flex-start, center, row);
    gap: 15px;
  }

  &-texts {
    @include flex(flex-start, center, column);
  }

  &-text-container {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    max-width: 400px;
    gap: 25px;
  }

  &-name {
    @include padding-one-var();
    @include margin-one-var();
    @include font-semi(14px);
  }

  &-quantity {
    @include padding-one-var();
    @include margin-one-var();
    @include font(14px);
    text-align: left !important;
    width: 100%;
    color: $light-purple;

    &-container {
      @include flex(space-between, center, row);
      width: 100%;
      gap: 20px;
    }

    &-controller {
      @include flex(flex-start, center, row);
      width: 100%;
    }
  
    &-input {
      @include margin-two-var(0, 10px);
      @include padding-two-var(8.5px);
      @include font-semi(14px);
      background-color: $light-gray-translucent;
      color: $white;
      border: none;
      text-align: center;
      width: 50vw;
      max-width: 200px;

      @media (max-width: $max-mobile-width-sm) {
        width: 40vw;
      }
    }

    &-icon {
      @include width-height-same(23px);
      color: $light-purple;
    }

    &-button {
      @include font-bold(12px);
      @include padding-two-var(10px);
      color: $pink-6;
      background-color: $light-gray-translucent;
      border: none;
      border-radius: $button-border-radius;
      text-transform: uppercase;
      width: 100%;
      max-width: 80px;

      &-icon {
        @include padding-one-var();
        background-color: transparent;
        border: none;
      }
    }
  }

  &-ticket {
    width: 25px;
  }

  &-container {
    padding-left: 0 !important;
    overflow: hidden;

    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;

    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-symbol {
    @include margin-one-var();
  }

  &-icon {
    vertical-align: middle !important;
    margin-top: 8px;

    &.check {
      margin-top: 9px;
    }
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding-two-var(0px, 30px);
      border-bottom: none;
      justify-content: center;
      
      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }

      &-title {
        text-align: center !important;
        width: 100%;
        margin-left: 20px;
        margin-top: 15px;
      }
    }

    &-body {
      @include padding-one-var(20px);
      @include font(12px);
      @include flex(center, center, column);
    }

    &-footer {
      @include padding(0, 20px, 20px, 20px);
      justify-content: center;
      border-top: none;

      &-button {
        @include purple-button(12px);
        @include margin-one-var();
        @include padding-two-var(15px);
        width: 100%;
        max-width: 400px;

        @media (max-width: $max-tablet-width) {
          width: 100%;
        }
      }
    }
  }
}