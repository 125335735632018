@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.guild-application {
  &-header {
    @include margin(-30px, -30px, 0, -30px);
    @include padding-two-var(20px, 30px);
    background-color: $dark-purple-1;
    position: sticky;
    z-index: 1;
    top: -30px;

    @media (max-width: $max-mobile-width-sm) {
      @include margin(-20px, -20px, 0, -20px);
      @include padding-one-var(20px);
      top: -20px;
    }

    &-title {
      @include font-semi(18px);
      margin-bottom: 5px;
    }

    &-subtitle {
      @include font-medium();
      color: $pink-1;
    }

    &-progress {
      @include progress-bar();
      margin-bottom: 0;

      .progress-bar {
        background-color: $teal;
        background-image: $progress-bar-gradient;
      }
    }
  }

  &-form {
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: 400px;

    &-group {
      margin-top: 20px;
      background-color: transparent;
      display: block;
    }

    &-label {
      @extend %form-label;
      
      span {
        color: $pink-1;
        margin-right: 5px;
      }
    }

    &-error {
      @include font-semi(12px);
      color: $pink-1;
      margin-left: 5px;
      margin-top: 5px;
    }

    &-input {
      @include padding-one-var(16px);
      margin-top: 10px;
      width: 100%;
      background-color: $dark-blue-6;
      border: $purple-light-border;
      border-radius: 5px;

      input[type="text"] {
        @include font(16px);
        @include padding-one-var(0);
        width: 100%;
        border: none;
        color: $white;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .img-container {
        @include width-height(100%, 150px);
        background-color: $black;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-text {
      @include padding-two-var(10px, 20px);
      border: none;
      border-radius: $button-border-radius;
      background-color: $dark-blue-5;
    }

    &-textarea {
      margin-top: 10px;

      textarea {
        @include padding-one-var(16px);
        @include font(16px);
        width: 100%;
        color: $white;
        background-color: $dark-blue-5;
        border: $purple-light-border;
        border-radius: 5px;

        &:focus {
          outline: none;
        }
      }
    }

    &-checkbox {
      @include font(16px);
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      min-height: 27px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }

      // Radio Button
      .radio {
        @include width-height-same(27px);
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        border-radius: 50%;
        border: $pink-border-checkbox;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.radio {
        background-color: $dark-purple-1;
      }

      & input:checked~.radio {
        background-color: $dark-purple-1-translucent;

        &:after {
          display: block;
        }
      }

      & .radio::after {
        @include width-height-same(13px);
        top: 4px;
        left: 4px;
        border-radius: 50%;
        background: $pink-1;
      }

      .checkbox {
        @include width-height-same(27px);
        position: absolute;
        top: 0;
        left: 0;
        background-color: $dark-purple-1-translucent;
        border-radius: 5px;
        border: $white-border-checkbox;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.checkbox {
        background-color: $grey-2;
      }

      & input:checked~.checkbox {
        background-color: $dark-purple-1-translucent;

        &:after {
          display: block;
        }
      }

      & .checkbox::after {
        @include width-height(8px, 14px);
        left: 7px;
        top: 2px;
        border: $white-border-checkbox;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &-prefix {
      @extend %mobile-number-prefix;
    }

    &-submit {
      margin-top: 20px;
      text-align: center;
    
      button {
        @include purple-button(16px);
        width: 100%;
        max-width: 400px;
      }
    }
  }
}