@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/settings.scss';

.select-currency-dropdown {
  @include flex(flex-start, center, row);
  @include padding-two-var(10px, 16px);
  @include font-semi(14px);
  margin-top: 10px;
  background-color: $desktop-toast-box-shadow-3;
  position: relative;
  border: $transparent-border;
  border-radius: $border-radius-mobile;
  cursor: pointer;
  min-height: 54px;

  &-solo {
    @include padding-one-var(16px);
  }

  &-icon {
    @include position-float-top-right(50%, 10px);
    transform: translateY(-50%);
    font-size: 2rem !important;
  }

  &-image {
    @include width-height-same(32px);
  }

  &-container {
    @include position-float-top-left(48px, -1px);
    @include padding(14px, 10px, 10px, 20px);
    @include flex(center, flex-start, column);
    gap: 4px;
    width: calc(100% + 2px);
    bottom: auto;
    overflow-y: auto;
    background: $desktop-toast-box-shadow-2;
    border-radius: 0 0 $border-radius-mobile $border-radius-mobile;
    z-index: 5;

    @media (max-width: $max-mobile-width-sm) {
      @include position-float-bottom-left(50px, -1px);
      top: auto;
      border-radius: $border-radius-mobile $border-radius-mobile 0 0;
    }
  }

  &-wrapper {
    @include flex(flex-start, center, row);
    @include font-semi(14px);
    gap: 20px;
    width: 100%;

    &-image {
      @include width-height-same(32px);
    }
  }
}