@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$spend-icon-url: '../assets/spend/';
$transactions-icon-url: '../assets/transactions/';
$icons-url: '../assets/icons/';

.payment {
  @include width-height-same(100%);
  position: relative;
  transition: 0.3s ease-in-out;

  @media (min-width: $max-mobile-width-sm) {
    color: $white;
  }
  
  &-inner {
    height: 100%;
  }

  &-loader {
    @include width-height(100vw, 100vh);
    position: fixed;
    top: 0;
    background-color: $loader-overlay-bg;
    z-index: 2000;

    @media (min-width: $max-mobile-width-sm) {
      @include width-height-same(100%);
      position: absolute;
      border-radius: 12px;
      color: $white;
    }

    &-text {
      @include font-semi(14px);
      margin-top: 10px;
      text-align: center;
    }

    &-wrapper {
      @include position-top-left(fixed, 50%, 50%);
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &-animation {
      height: 90px;
    }
  }

  &-header {
    @include font-bold(17px);
    text-align: center;
    margin-bottom: 10px;

    &-back {
      @include position-float-top-left(0, 0);
      cursor: pointer;
    }
  }

  &-pay {
    @include flex(center, center, row);
    @include font-semi(15px);
    text-align: center;
  }

  &-type {
    display: flex;
    align-items: flex-end;
    gap: 4px;
  }

  &-input {
    &-box {
      @include padding-two-var(15px);
      background-color: $dark-blue-7;
      width: 100%;
      max-width: 586px;
      border-radius: 12px;
    }

    &-blank {
      @include font-light(50px);
      background-color: transparent;
      color: $white;
      border: none;
      border-bottom: $top-up-amount-border;
      outline: none;
      text-align: center;
      height: 50px;
      max-width: 220px;
      
      @supports selector(:nth-child(1 of x)) {
        height: 62px;
        padding: 0;
        border-radius: 0;
      }
    }
  }

  &-country {
    font-size: 20px;
    margin-right: 6px;
    margin-bottom: 12px;
  }

  &-label {
    @include font(14px);
    color: $grey-4;
  }

  &-text {
    @include font(15px);
    @include padding-two-var(0, 10px);
    color: $white;
    text-align: right;

    &-amount {
      @include font(14px);
      @include padding-two-var(20px);
    }
  }

  &-currency {
    @include font(20px);
    margin-bottom: 8px;
  }

  &-converted-amount {
    @include font-semi(14px);
    color: $white;

    &-icon {
      cursor: pointer;
    }
  }

  &-container {
    @include flex(center, center, column);
    margin-top: 30px;
  }

  &-box {
    @include flex(center, center, column);
    @include padding-two-var(15px);
    height: auto;
    width: 100%;
    background-color: $dark-blue-7;
    text-align: center;
    border-radius: 12px;
    gap: 10px;
    border: 2px solid transparent;
    cursor: pointer;

    &-placeholder {
      height: 100px;
    }

    &-active {
      border: $purple-border-thick;
    }

    &-price {
      @include font-semi(16px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(14px);
      }
    }

    &-mgc {
      @include font(14px);

      @media (max-width: $max-mobile-width-sm) {
        @include font(12px);
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-icon {
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size(center, cover);
      @include width-height-same(22px);
      margin-bottom: 1px;

      &-input {
        margin-right: 3px;
        margin-bottom: 14px;
      }

      &-us {
        @include icon-image('us-flag', true, $icons-url);
        @include background-position-size(center, cover);
        @include width-height(20px, 16px);
        margin-right: 6px;
        margin-bottom: 14px;
      }
    }

  }

  &-change {
    @include padding(30px, 0px, 100px, 0px);
    @include flex(center, center, column);
    width: 100%;

    @media (min-width: $max-mobile-width-sm) {
      @include padding(30px);
    }
    
    &-text {
      @include font(14px);
      color: $pink-1;
      cursor: pointer;
      text-decoration: underline;
    }

    &-button {
      @include purple-button();
      background-color: transparent;
      width: 100%;

      &-wrapper {
        @include flex(center, center, column);
        @include padding-two-var(10px, 20px);
        @include position-bottom-left(fixed, 60px, 0);
        width: 100%;
        background-color: $dark-purple-1;

        @media (min-width: $max-mobile-width-sm) {
          @include padding(20px);
          @include position-bottom-left(relative, 0, 0);
          border: none;
        }
      }

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-amount {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }

  &-breakdown {
    @include padding(15px, 20px, 0, 20px);
    width: 100%;
    border-top: 1px solid $dark-blue-3;

    .row {
      @include margin(0, 0, 5px);

      &:last-child {
        @include margin-one-var();
      }
    }

    &-title {
      @include font(14px);
      padding-left: 0;
      padding-right: 10px;
    }

    &-value {
      @include font-bold(14px);
      text-align: right;
      padding-left: 0;
      padding-right: 0;
      word-break: break-all;
    }
  }
}

.buy {
  &-order-summary {
    @include flex(flex-start, center, column);
    @include width-height-same(100%);
    padding-bottom: 100px;

    @media (max-width: $max-mobile-width-xs) {
      padding: 0px;
    }

    @media (min-width: $max-mobile-width-sm) {
      color: $white;
      padding: 0px;
    }

    &-instruction {
      @include font(14px);
      @include padding-two-var(20px, 20px);
      text-align: center;
    }

    &-container {
      @include width-height-same(100%);
      max-width: 500px;

      @media (min-width: $max-mobile-width-sm) {
        max-width: 100%;
      }
    }
  
    &-header {
      @include flex(center, center, column);
      @include padding(0px, 20px, 20px, 20px);
      gap: 10px;

      &-pending {
        @include icon-image('payment-process', false, $spend-icon-url);
        @include background-position-size(center, contain);
        @include width-height-same(120px);
      }

      &-mgc {
        @include icon-image('mgo-coin', true, $icons-url);
        @include background-position-size(center, contain);
        @include width-height-same(25px);
      }

      &-amount {
        @include font-semi(14px);
      }

      &-title {
        @include font-bold(16px);
      }
    }

    &-body {
      &-content {
        display: flex;
        justify-content: space-between;

        @media (min-width: $max-mobile-width-sm) {
          display: block;
        }
      }

      &-qrcode {
        padding: 10px;
        width: 100%;
        background-color: $white;
      }

      &-title {
        @include font-semi(18px);
        color: $dark-grey-4;
      }

      &-wrapper {
        @include padding-two-var(20px, 20px);
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background-color: $dark-blue-7;
        width: 100%;
        gap: 15px;
        white-space: nowrap;

        @media (min-width: $max-mobile-width-sm) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        &-content {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }

      &-value {
        @include font-light(16px);
        color: $dark-grey-4;
      }

      &-show {
        @include font-semi(14px);
        text-align: center;
      }

      &-text {
        @include font(14px);
        color: $grey-13;

        @media (max-width: $max-mobile-width-sm) {
          @include font(12px);
        }
      }

      &-subtitle {
        @include font-semi(14px);
        text-align: right;
        color: $white;
        white-space: pre-line;

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(12px);
        }

        @media (min-width: $max-mobile-width-sm) {
          text-align: left;
        }
      }

      &-container {
        border-radius: 3px;
      }
    }

    &-footer {
      @include flex(center, center, column);
      @include padding-two-var(10px);
      width: 100%;
      gap: 10px;

      &-button {
        @include purple-button();
        width: 100%;
        max-width: 500px;

        @media (min-width: $max-mobile-width-sm) {
          max-width: 100%;
        }

        &-grey {
          @include grey-button();
          width: 100%;
          max-width: 500px;

          @media (min-width: $max-mobile-width-sm) {
            max-width: 100%;
            background-color: transparent;
            border: $pink-border;
          }
        }
      }
    }
  }
}