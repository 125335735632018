@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.referral-ad-banner {
  width: 100%;
  border: none;
  max-width: 400px;
  color: $white;
  background-color: transparent;

  &-animated {
    max-width: 100%;
    max-height: 100%;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;
  }

  &-wrapper {
    @include flex(center, center, column);
    width: 100%;
    gap: 16px;
    position: relative;
  }

  &-close {
    @include position-float-top-right(-10px, -10px);
    @include width-height-same(26px !important);
    background: $white;
    color: $purple-1;
    border-radius: 36px;
    cursor: pointer;
    font-size: 10px !important;
  }

  &-container {
    @include width-height(100%, calc((var(--vh, 1vh) * 100 + 100px)));
    overflow: hidden;
    z-index: 1000;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(100%);
    }

    &.fade .modal-dialog {
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    z-index: 1000;
    
    &.show {
      opacity: 0.6;
    }
  }

  &-body {
    @include padding-one-var(20px);

    &-text {
      @include font-semi(14px);
      cursor: pointer;
    }
  }

  &-dialog {
    @include margin-one-var();
    max-width: none !important;
    justify-content: center;
  }
}