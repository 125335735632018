@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.chat-thread {
  &-page {
    @include padding-one-var(0);

    &-inner {
      height: 100%;
    }
  }

  &-header {
    @include padding-two-var(17px, 30px);
    position: sticky;
    border-bottom: $dark-blue-border;

    @media (max-width: $max-tablet-width) {
      @include padding-two-var(17px, 20px);
    }

    &-back {
      @include link($white, $white);
      @include margin(0, 15px, 0, -5px);
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    &-info {
      display: inline-block;
      width: calc(100% - 34px);
    }

    &-image {
      @include chat-profile-pic(30px);
    }

    &-name {
      @include font-medium(14px);
      margin-left: 15px;
    }
  }

  &-messages {
    position: relative;
    height: calc(100% - 149px);

    &-chatbox-hidden {
      height: calc(100% - 65px);
    }

    &-inner {
      @include padding-two-var(0, 30px);
      @include width-height-same(100%);
      position: absolute;
      bottom: 0px;
      overflow-y: scroll;

      @media (max-width: $max-tablet-width) {
        @include padding-two-var(0, 20px);
        position: initial;
      }
    }

    &-title {
      @include padding-two-var(60px);
      text-align: center;

      &-pic {
        @include chat-profile-pic(100px);
      }

      &-name {
        @include font-medium(20px);
        margin-top: 20px;
      }
    }
  }

  &-group {
    margin-bottom: 20px;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  &-label {
    @extend %chat-grey-text;
    text-align: center;
  }

  &-message {
    @include font(14px);
    display: block;
    margin-top: 20px;
    position: relative;
    
    &-profile-pic {
      @include chat-profile-pic(30px);
      vertical-align: top;
    }

    &-self {
      text-align: right;
    }

    &-consecutive {
      margin-top: 5px;
    }

    &-text {
      border-radius: 20px;
      color: $white;
      display: inline-block;
      max-width: 400px;
      text-align: left;
      overflow-wrap: anywhere;

      @media (max-width: $max-mobile-width-xs) {
        max-width: 250px;
      }

      &-sender {
        background-color: $dark-blue-1;
        margin-left: 15px;
        vertical-align: top;

        &-before, &-between {
          border-bottom-left-radius: 5px;
        }

        &-after, &-between {
          border-top-left-radius: 5px;
        }
      }
  
      &-self {
        background-color: $purple-1;
        vertical-align: middle;

        &-before, &-between {
          border-bottom-right-radius: 5px;
        }

        &-after, &-between {
          border-top-right-radius: 5px;
        }
      }

      &-spacing {
        margin-bottom: 10px;
        white-space: pre-wrap;
      }
    }

    &-menu {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;

      button {
        @include padding-one-var();
        border: none;
        color: $grey-2;
        background-color: transparent;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-event {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 100%;

      @media (max-width: $max-mobile-width-xs){
        height: 130px;
      }
    }

    &-qr-code {
      width: 100%;
    }

    &-nft, &-image {
      width: 100%;
      cursor: pointer;
    }

    &-path {
      @include purple-button(15px);
      @include font-bold(15px);
      margin-top: 10px;
      width: 100%;
      color: $white;
      text-decoration: none;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    a, button {
      @include purple-button(15px);
      @include font-bold(15px);
      margin-top: 10px;
      width: 100%;
      color: $white;
      text-decoration: none;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  
  &-options {
    &-button {
      @include blue-button();
      @include padding-two-var(10px);
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.chatbox {
  @include padding-two-var(20px, 30px);
  width: 100%;

  @media (max-width: $max-tablet-width) {
    @include padding-one-var(20px);
  }

  &-input {
    @include padding-two-var(10px, 20px);
    @include font(16px);
    width: calc(100% - 43px);
    border: none;
    border-radius: $button-border-radius;
    color: $white;
    background-color: $dark-purple-1;

    &:focus {
      outline: none;
    }
  }

  &-submit {
    @include padding-one-var();
    margin-left: 15px;
    border: none;
    color: $white;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}