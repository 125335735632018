@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.gamepass-cart {
  @include padding(30px, 30px, 0px, 30px);
  height: calc(100vh - 126px);
  height: calc(var(--vh, 1vh) * 100 - 126px);

  @media (max-width: $max-mobile-width-sm) {
    @include padding(20px, 20px, 0px, 20px);

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &-container {
    @include flex(space-between, center, column);
    @include width-height(100%, inherit);
  }

  &-inner {
    height: 100%;
  }

  &-empty {
    @include width-height(100%, 110px);
    @include font(14px);
    @include flex(center, center, row);
    background-color: $dark-purple-7;
    border-radius: 8px;

  }

  &-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
    border: none;
  }

  &-footer {
    @include flex(flex-end, center, column);
    padding-bottom: 30px;
    width: 100%;
    z-index: 5;
    
    @media (max-width: $max-mobile-width-sm) {
      padding-bottom: 20px;
    }

    &-amount {
      @include font-semi(18px);
      @include flex(space-between, center, row);
      @include padding-two-var(15px, 20px);
      background-color: $dark-purple-6;
      border-radius: 8px;
      width: 100%;
    }

    &-button {
      @include purple-button-reverse();
      width: 100%;
    }
  }

  &-checkbox {
    @include padding-one-var(0 !important);

    &-floater {
      @include position-float-top-right(6px, 4px);
      @include width-height-same(16px);
      background-color: $white;
    }
  }

  &-card {
    @include padding-two-var(15px, 20px);
    @include flex(flex-start, center, row);
    @include margin-two-var(15px);
    background-color: $dark-purple-7;
    border-radius: 8px;
    gap: 15px;

    &:first-child {
      margin-top: 0;
    }

    &-wrapper {
      @include flex(flex-start, center, row);
      gap: 10px;
    }

    &-container {
      @include flex(center, flex-start, column);
      gap: 10px;
      width: 100%;
    }

    &-name {
      @include font-semi(14px);
    }

    &-image {
      max-width: 50px;
    }

    &-text {
      @include font(14px);
    }

    &-mgc {
      @include width-height-same(15px);
    }

    &-sign {
      @include width-height-same(23px !important);
      @include margin-one-var(-1px);
      color: $light-purple;
      cursor: pointer;
    }

    &-quantity {
      @include font-semi(14px);
      width: 15px;
      text-align: center;
    }
  }
}