@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.login-modal {
  &-footer {
    @include flex(center, center, row);
    @include font(14px);
    @include padding(14px);
    gap: 6px;

    &-button {
      @include font-semi(14px);
      color: $pink-1;
      cursor: pointer;
    }
  }

  &-arrow {
    font-size: 30px !important; 
    padding-bottom: 2px;
  }

  &-email {
    @include padding-two-var(0, 20px);
    @include font-semi(14px);
    @include width-height(100%, 44px);
    background-color: $white;
    outline: none;
    border: none;
    border-radius: 40px;
    width: 100%;
  }

  &-phone {
    @include padding(0, 20px, 0, 4px);
    @include font-semi(14px);
    @include margin(8px);
    @include flex(flex-start, center, row);
    @include width-height(100%, 44px);
    background-color: $white;
    border-radius: 40px;
    color: $white;
    border: $transparent-border;
    outline: none;

    &-text {
      @include font-semi();
      width: 100%;
      text-align: left;
      color: $light-purple;

      &.optional {
        @include padding(10px);
        cursor: pointer;
      }
    }

    &-error {
      border: $red-border;
    }

    & > .dropdown {
      & > .phone-dropdown-toggle {
        @include font-semi(14px);
        @include padding();
        color: $dark-purple-7;
      }
    }

    & > .phone-input {
      @include font-semi(14px);
      color: $dark-purple-7;
    }

    &-dropdown {
      max-width: 400px;
    }
  }

  &-error {
    @include font-semi();
    @include margin(5px);
    color: $red-2;
    width: 100%;
    text-align: left;
  }
}