@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.launchpad-mechanics {
  padding-bottom: 0px;
  overflow-x: hidden;
  &-title {
    @include font-bold(18px);
    text-align: center;
  }

  &-back {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &-wrapper {
    @include flex(center, center, column);
    @include padding(30px, 0px, 20px, 0px);

    &-mobile {
      padding: 0px;
    }
  }

  &-container {
    @include flex(center, center, column);
    @include width-height-same(100%);
    max-width: 400px;
    gap: 20px;
  }

  &-box {
    @include padding-two-var(20px, 20px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $dark-purple-3;
    color: $white;
    border: $purple-border;
    border-radius: 5px;
    width: 100%;

    &-title {
      @include font-bold(16px);
    }

    &-sub {
      @include font(14px);
      color: $grey-6;
    }

    &-desc {
      @include font(13px);
      color: $grey-6;

      ol {
        // list-style-type: lower-alpha;
        padding-inline-start: 1.7em;

        li {
          padding-left: 5px;

          ol {
            list-style-type: lower-roman;
          }

          ul {
            list-style-type: lower-alpha;
          }
        }
      }

      a {
        color: $pink-1;
      }
    }
  }
  
}