@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.datepicker {
  color: $white;
  border: none;
  background-color: $purple-1;

  &-custom-header {
    @include margin-one-var(10px);
    display: flex;
    justify-content: center;
  }

  &-prev-next-btn {
    @include padding-one-var();
    border: none;
    color: $white;
    background-color: transparent;

    &-disabled {
      opacity: 0.5;
    }
  }

  &-header-btn {
    @include padding-one-var();
    @include font-semi(16px);
    width: calc(100% - 48px);
    border: none;
    color: $white;
    background-color: transparent;
  }

  .react-datepicker {
    &-popper[data-placement^=top] {
      padding-bottom: 0;
    }

    &__header {
      background-color: transparent;
      border-bottom: $dark-blue-border;
    }

    &__triangle {
      visibility: hidden;
    }

    &__current-month {
      @include font-semi(16px);
      color: $white;
    }

    &__day {
      @include font(16px);
      @include margin-one-var(5px);
      color: $white;

      &:hover {
        color: $purple-1;
        background-color: $white;
      }

      &-name {
        @include font-semi(16px);
        @include margin-one-var(5px);
        color: $white;
      }

      &--outside-month, &--disabled {
        opacity: 0.5;
      }

      &--selected, &--keyboard-selected {
        color: $purple-1;
        background-color: $white;
      }
    }

    &__month {
      @include margin-two-var(5px);

      &-text {
        @include font(16px);
        @include margin-one-var(5px);
        color: $white;

        &:hover {
          color: $purple-1;
          background-color: $white;
        }

        &--keyboard-selected {
          color: $purple-1;
          background-color: $white !important;
        }
      }

      &--disabled {
        opacity: 0.5;
      }
    }

    &__year {
      @include margin-two-var(5px);

      &-wrapper {
        max-width: 222px;
      }

      &-text {
        @include font(16px);
        @include margin-one-var(5px);
        color: $white;

        &:hover {
          color: $purple-1;
          background-color: $white;
        }

        &--keyboard-selected, &--selected {
          color: $purple-1;
          background-color: $white;
        }

        &--disabled {
          opacity: 0.5;
        }
      }
    }
  }
}