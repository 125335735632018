@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.empty-game-pass {
  @include flex(flex-start, center, row);
  @include padding-one-var(15px);
  width: 100%;
  border-radius: $border-radius-mobile;
  background-color: $white-translucent;

  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var();
    border-radius: 0;
    background-color: transparent;
  }

  &-inner {
    @include flex(center, center, row);
    width: 100%;
    gap: 20px;

    @media (max-width: $max-desktop-width-sm) {
      gap: 15px;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include flex(flex-start, center, column);
    }

    &-compact {
      gap: 15px;

      @media (max-width: $max-mobile-width-sm) {
        @include flex(flex-start, center, row);
      }

      &-tutorial {
        position: relative;
        z-index: 20;
      }
    }
  }

  &-ticket {
    @include flex(center, center, row);
    @include padding-one-var(10px);
    @include width-height(auto, 210px);

    @media (max-width: $max-desktop-width-sm) {
      height: 173px;
    }

    @media (max-width: $max-mobile-width-sm) {
      height: 35vw;
      max-height: 200px;
    }

    &-compact {
      height: 12.5vw;
      max-height: 150px;

      @media (max-width: $max-mobile-width-sm) {
        @include width-height(50px, auto);
      }
    }

    &-grayscale {
      @include padding-one-var(0);
    }

    &-image {
      height: 100%;

      &-compact {
        @media (max-width: $max-mobile-width-sm) {
          @include width-height(100%, auto);
        }
      }

      &-grayscale {
        filter: grayscale(100%);
      }
    }
  }

  &-empty {
    @include flex(center, center, column);
    text-align: center;
    width: 100%;
    max-width: 300px;
    gap: 10px;

    &-compact {
      @media (max-width: $max-mobile-width-sm) {
        @include flex(space-between, center, row);
        @include padding-one-var(15px);
        width: calc(100% - 65px);
        max-width: none;
        background-color: $white-translucent;
        border-radius: $border-radius-mobile;
        gap: 15px;
      }
    }

    &-text {
      @include font-semi(16px);
      color: $white;

      @media (max-width: $max-tablet-width) {
        @include font-semi(14px);
      }

      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
      }

      &-compact {
        @media (max-width: $max-desktop-width-sm) {
          text-align: left;
        }
      }
    }
  }

  &-buy-button {
    @include purple-button-reverse(12px);
    @include padding-two-var(15px);
    width: 100%;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(10px);
    }

    &-compact {
      @media (max-width: $max-mobile-width-sm) {
        max-width: 120px;
      }
      
      &-tutorial {
        @include orange-button();

        @media (max-width: $max-mobile-width-sm) {
          @include padding-two-var(10px);
        }
      }
    }
  }
}