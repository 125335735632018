@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$gamepass-url: '../../../assets/gamepass/';

.transaction-history {
  @include padding-one-var(1rem);
  background-color: $dark-blue-7;
  margin-bottom: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-products {
    @include padding-one-var(20px);
    background-color: $dark-purple-7;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(15px, 20px);
    }

    &-box {
      margin-bottom: 18px;
    }

    &-wrapper {
      @include padding(30px, 0, 20px, 0);

      &-gap {
        @include padding(30px, 0, 10px, 0);
      }
    }

    &-coin {
      @include icon-image('coin', true, $gamepass-url);
      @include width-height-same(15px);
      @include background-position-size(center, cover);
    }

    &-card {
      width: 100%;
    }

    &-value {
      @include font(14px)
    }

    &-line {
      border-bottom: $grey-line-border;
    }

    &-body {
      @include flex(space-between, center, row);
      @include padding-two-var(30px);
      width: 100%;
    }

    &-button {
      @include font-semi();
      text-align: right;
      color: $pink-1;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      width: fit-content;
    }

    &-image {
      box-shadow: $glow-purple-small;
      max-width: 42px;
      border-radius: 2px;
    }

    &-total {
      @include font-semi(14px);
    }

    &-name {
      @include font-semi(14px);
    }

    &-quantity {
      @include font(14px);
      color: $light-purple;
    }

    &-amount {
      @include font-semi(14px);
      @include flex(center, center, row);
      gap: 5px;
    }

    &-count {
      @include font();
      @include padding-two-var(10px);
      @include margin(0, -20px, 15px, -20px);
      border-top: $grey-line-border;
      border-bottom: $grey-line-border;
      text-align: center;
    }

    &-time {
      @include font-semi(14px);

      @media (max-width: $max-mobile-width-sm) {
        @include font();
      }
    }

    &-arrow {
      @include width-height-same(15px !important);
    }
  }

  &-status {
    @include font-semi(14px);
    text-transform: uppercase;
    flex-wrap: wrap;
    text-align: right;

    @media (max-width: $max-mobile-width-sm) {
      @include font-bold(12px);
    }

    &-completed {
      color: $green-1;
    }

    &-failed {
      color: $red-1;
    }

    &-pending {
      color: $orange-1;
    }
  }

  &-arrow {
    height: 18px !important;
    cursor: pointer;
    margin-bottom: 1px;
  }

  &-channel {
    @include font-medium();
    color: $light-purple;
  }

  &-type {
    @include font-semi(14px);
    text-transform: capitalize;
  }

  &-amount {
    @include flex(flex-end, center, row);
    @include font-semi(14px);
    text-align: right;
    gap: 5px;
  }

  &-date {
    @include font();
  }
}