@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.discord-daily-boost-icon {
  &-timer {
    @include position-float-bottom-left(8px, 50%);
    @include flex(center, center, row);
    @include font-bold(10px);
    @include padding(0, 0, 2px);
    transform: translateX(-50%);
    text-align: center;
    color: $black;
    width: 80px;

    @media (max-width: $max-mobile-width-xs) {
      @include font-bold(9px);
      @include padding(0, 0, 2px, 2px);
      bottom: 2px;
    }
  }
}