@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.active-game-pass-popup {
  color: $white;
  background-color: $dark-purple-2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;

  &-container {
    overflow: hidden;
    padding-left: 0 !important;
    
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-header {
    &-left {
      width: 24px;
    }

    &-title {
      width: calc(100% - 32px);
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 30px, 0, 30px);
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }
    }

    &-body {
      @include padding(20px, 0, 30px);
      @include font(16px);
    }
  }

  &-tabs {
    @include margin-one-var();
    width: 100%;

    & > .rb-tabs-header {
      border-bottom: 2px solid $dark-blue-7 !important;

      & > .rb-tabs-items {
        & > .rb-tabs-item.active {
          font-weight: 600 !important;
        }
      }
    }

    & > .rb-tabs-content {
      padding-top: 30px;

      & > .rb-tabs-content-item {
        overflow: auto;
        max-height: 234px;
      }
    }

    &-indicator {
      @include width-height(50% !important, 3px !important);
      background-color: $pink-1 !important;
      bottom: -2.5px !important;

      &-solo {
        width: 100% !important;
      }
    }

    &-lists {
      justify-content: space-between;
      border-bottom: none !important;

      @media (min-width: $min-tablet-width) {
        gap: 20px;
      }
    }
  }

  &-tab {
    @include font(14px); 
    @include padding-two-var(10px !important, 16px !important);
    width: 100%;
    text-align: center;

    &-content {
      @include padding-two-var(0, 30px);
      @include flex(flex-start, flex-start, column);
      gap: 15px;
    }

    &-item {
      @include flex(flex-start, center, row);
      @include padding-one-var(15px);
      gap: 10px;
      width: 100%;
      border-radius: 5px;
      background-color: $purple-7;

      &-image {
        width: 25px;
      }

      &-name {
        @include font-semi(14px);
        width: calc(100% - 175px);
        text-align: left;
      }

      &-timer {
        @include font-semi(18px);
        width: 130px;
        text-align: right;
      }
    }
  }
}