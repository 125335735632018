@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.mobile-pin-auth {
  &-section {
    @include position-top-left(fixed, 0, 0);
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    @include padding-one-var(30px !important);
    border: none !important;
    background-color: $dark-purple-1;
    z-index: 10;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(20px !important);
    }
  }
}