@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.terms-and-conditions {
  &-consent {
    @include font(14px);

    & > a {
      @include font-semi(14px);
      color: $pink-6;
      text-decoration: none;
    }
  }

  &-button {
    @include purple-button();
    @include margin(14px);
    width: 100%;
  }
}