@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.pin-modal {
  &-description {
    @include font();
    text-align: center;
    margin-bottom: 15px;
  }

  &-form {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    gap: 15px;

    &-row {
      @include flex(flex-start, flex-start, column);
      width: 100%;
    }

    &-label {
      @include font-semi();
      width: 100%;
      text-align: left;
      color: $light-purple;
    }

    &-group {
      @include flex(flex-start, center, row);
      @include padding-two-var(10px, 20px);
      gap: 10px;
      width: 100%;
      margin-top: 8px;
      border-radius: $button-border-radius;
      background-color: $white;

      &-error {
        outline: $red-border;
      }
    }

    &-input {
      @include padding-one-var();
      @include font-semi(14px);
      width: calc(100% - 34px);
      border: none;
      color: $dark-purple-7;

      &:focus {
        outline: none;
      }
    }

    &-password-reveal {
      @include padding-one-var();
      color: $dark-purple-7;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  &-buttons {
    width: 100%;
    margin-top: 15px;
  }

  &-submit {
    margin-top: 0;
  }

  &-dismiss {
    @include padding-one-var();
    @include font-semi(14px);
    margin-top: 15px;
    border: none;
    color: $pink-1;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  &-error {
    @include font-semi();
    margin-top: 15px;
    color: $red-2;
    width: 100%;
    text-align: left;
  }
}