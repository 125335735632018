@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.legal-statement {
  text-align: left;
  padding-top: 0px;

  &-last-modified {
    @include font-semi();
    color: $light-purple;
    margin-bottom: 20px;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-center {
    text-align: center;
  }

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
  }

  &-paragraph {
    @include font-light(14px);
    margin-bottom: 20px;

    a {
      @include link($pink-1, $pink-1);
      @include font-semi(14px);
    }

    li {
      div {
        margin-left: 10px;
      }
    }
  }

  &-numbered-list {
    display: block;

    &-item {
      @include flex(flex-start, flex-start, row);
      width: 100%;

      &-number {
        width: 30px;
      }

      &-text {
        width: calc(100% - 30px);
        max-width: fit-content;
      }
    }
  }

  &-sections {
    margin-bottom: 20px;
  }

  &-section {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &-contents {
      @include font-light(14px);
    }

    &-paragraph {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        @include font-semi(14px);
      }

      em {
        strong {
          font-style: italic;
        }
      }

      a {
        @include link($pink-1, $pink-1);
        @include font-semi(14px);
      }

      ol {
        counter-reset: section;
        list-style-type: none;
        padding-left: 0;

        li {
          @include flex(flex-start, flex-start, row);
          width: 100%;
          max-width: fit-content;

          &::before {
            counter-increment: section;
            content: counter(section, lower-alpha) ".";
            width: 30px;
          }

          div {
            width: calc(100% - 30px);
          }

          ol {
            counter-reset: subsection;
            list-style-type: none;

            li {
              &::before {
                counter-increment: subsection;
                content: counter(subsection, lower-roman) ".";
              }
            }
          }

          ul {
            counter-reset: subsection;
            list-style-type: none;
            padding-left: 0;

            li {
              &::before {
                counter-increment: subsection;
                content: counter(subsection, disc);
              }
            }
          }
        }
      }

      ul {
        counter-reset: section;
        list-style-type: none;
        padding-left: 0;

        li {
          @include flex(flex-start, flex-start, row);
          width: 100%;
          max-width: fit-content;

          &::before {
            counter-increment: section;
            content: counter(section, disc);
            width: 30px;
          }

          div {
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  &-end {
    @include font-light(14px);
    text-align: center;

    span {
      @include padding-two-var(0, 5px);
    }
  }
}