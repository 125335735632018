@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../../assets/icons/';
$launchpad-url: '../../assets/launchpad/';
$marketplace-url: '../../assets/marketplace/';

.marketplace {
  &-collection {
    @media (max-width: $max-mobile-width-sm) {
      padding: 0;
      overflow: overlay;
    }

    &-bg {
      @include background-position-size(center, contain);
      @include width-height(100%, 127px);
      background-color: $dark-purple-1;
      background-image: linear-gradient($purple-1, $dark-purple-1);
      position: absolute;
      top: 0;
      left: 0;
      display: none;

      @media (max-width: $max-mobile-width-sm) {
        display: block;
        background-repeat-x: repeat;
      }

      @media (max-width: $max-mobile-width-xxs) {
        height: 120px;
      }
    }
  
    &-section {
      padding: 0;
      border: none;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-one-var(20px);
      }
    }
  
    &-image {
      @include width-height-same(100%);
      max-width: 416px;
      border-radius: 15px;
      z-index: 5;

      @media (max-width: $max-mobile-width-sm) {
        max-width: 200px;
        margin-bottom: 15px;
      }

      @media (max-width: $max-mobile-width-xs) {
        max-width: 115px;
      }

      &-container {
        @include flex(center, center, row);
        width: 100%;
      }
    }
  
    &-main {
      @include padding(50px, 0px, 40px, 0px);
      border-bottom: 1px solid $dark-blue-1;

      @media (max-width: $max-mobile-width-sm) {
        @include padding(50px, 0px, 0px, 0px);
      }
    }
  }

  &-nft {
    &-info {
      @include flex(flex-start, flex-start, column);
      @include width-height-same(100%);
    }

    &-title {
      @include font-semi(24px);

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;
        text-align: center;
      }
    }

    &-description {
      @include font-light(14px);
      color: $light-purple;

      @media (max-width: $max-mobile-width-sm) {
        text-align: center;
      }
    }

    &-collection {
      @include width-height-same(100%);
      border-top: 1px solid $dark-blue-1;
      transition: 0.5s ease-in-out;


      &-hide {
        @include width-height-same(100%);
        border-top: 1px solid $dark-blue-1;
        transition: 0.5s ease-in-out;
      }

      &-wrapper {
        @include flex(space-between, center, row);
        @include padding-two-var(20px);

        &-hide {
          padding-bottom: 0;
        }
      }
    }

    &-stats {
      @include flex(center, center, column);
      @include padding-two-var(10px);
      background-color: $dark-purple-3;
      border: $purple-border;
      border-radius: $border-radius-mobile;

      &-wrapper {
        width: 100%;
        padding-bottom: 10px;
      }

      &-box {
        padding: 0;
      }

      &-text {
        @include font();
        color: $light-purple;
      }

      &-value {
        @include font-semi(18px);
        color: $pink-1;
      }
    }

    &-about {
      @include icon-image('info', false, $launchpad-url);
      @include background-position-size(center, cover);
      @include width-height-same(32px);

      &-wrapper {
        @include flex(flex-start, center, row);
        gap: 5px;
      }

      &-title {
        @include font-semi(18px);
      }

      &-body {
        @include font();
        white-space: pre-line;
        color: $light-purple;

        @media (max-width: $max-mobile-width-sm) {
          padding-bottom: 22px;
        }
      }
    }
  }

  &-lists {
    @include padding-two-var(30px);

    &-header {
      @include flex(space-between, center, row);
    }

    &-title {
      @include flex(flex-start, center, row);
      gap: 10px;

      &-icon {
        @include icon-image('polygon', false, $marketplace-url);
        @include background-position-size(center, contain);
        @include width-height-same(25px);
      }
  
      &-text {
        @include font-semi(18px);
      }
    }

    &-image {
      width: 100%;
      border-radius: $border-radius-mobile;
      cursor: pointer;
    }

    &-item {
      @include margin-one-var(0 !important);
      padding-top: 30px;
      width: 100% !important;
      gap: 20px;

      &-hide {
        display: none !important;
      }

      &-box {
        @include padding-one-var(0 !important);
        width: calc(100% / 2 - 10px) !important;
        max-width: none !important;
        flex-basis: auto !important;
        -webkit-flex-basis: auto !important;
        -moz-flex-basis: auto !important;

        @media (min-width: $min-width-grid-item-sm) {
          width: calc(100% / 3 - 13.5px) !important;
        }

        @media (min-width: $min-width-grid-item-md) {
          width: calc(100% / 4 - 15px) !important;
        }

        @media (min-width: $min-desktop-width-xl) {
          width: calc(100% / 6 - 17px) !important;
        }
      }
      
      &-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: fit-content;

        @media (max-width: $max-mobile-width-sm) {
          gap: 5px;
        }
      }

      &-details {
        @include flex(flex-start, flex-start, column);
        gap: 5px;
      }

      &-wrapper {
        @include flex(flex-start, center, row);
        gap: 5px;

        @media (max-width: $max-mobile-width-sm) {
          @include flex(flex-start, center, row);
        }
      }

      &-details {
        width: 100%;

        @media (max-width: $max-mobile-width-sm) {
          flex-direction: column;
          gap: 5px;
        }
      }

      &-name {
        @include font(14px);
        color: $light-purple;

        @media (max-width: $max-mobile-width-sm) {
          @include font(14px);
          margin-top: 5px;
        }
      }

      &-list {
        @include font(14px);
        @include flex(center, center, row);
        margin-top: 20px;
        height: 122px;
        background-color: $dark-blue-7;
        border-radius: 8px;
        text-align: center;

        @media (max-width: $max-mobile-width-sm) {
          margin-top: 0px;
        }
      }

      &-title {
        @include font-semi(14px);
        padding: 0;
      }

      &-price {
        @include font-semi(14px);
        white-space: pre;

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(12px);
        }
      }

      &-mgc {
        @include icon-image('mgo-coin', true, $icons-url);
        @include background-position-size(center, contain);
        @include width-height-same(14px);
      }
    }

    &-filter {
      @include icon-image('filter', false, $marketplace-url);
      @include background-position-size(center, contain);
      @include width-height-same(23px);
      cursor: pointer;
      display: none;
    }

    &-search {

      &-wrapper {
        @include width-height(100%, 40px);
        max-width: 474px;
        position: relative;
        padding: 0;
        display: none;
      }

      &-icon {
        @include icon-image('search', false, $marketplace-url);
        @include background-position-size(center, contain);
        @include width-height-same(16px);
        @include position-float-top-left(50%, 16px);
        transform: translateY(-50%);
      }

      &-input {
        @include padding(11px, 0px, 11px, 42px);
        @include font(14px);
        width: 100%;
        color: $white;
        background-color: $dark-blue-7;
        border-radius: 30px;
        outline: none;
        border: none;
        display: none;
      }
    }
  }
}