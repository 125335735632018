@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$gamepass-url: '../../../assets/gamepass/';

.active-game-passes {
  @include padding-one-var(20px);
  margin-top: 30px;
  background-color: $purple-7;
  border-radius: $border-radius-mobile;

  @media (max-width: $max-mobile-width-xs) {
    @include padding-two-var(20px, 10px);
  }

  &-footer {
    @include flex(center, center, row);
    @include font-bold(14px);
    @include margin(20px, -20px, -20px, -20px);
    @include padding-one-var(20px);
    background-color: $white;
    color: $purple-1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-decoration: none;

    &:hover {
      color: $purple-1;
    }

    @media (max-width: $max-mobile-width-xs) {
      @include font-bold(12px);
      @include margin(20px, -10px, -20px, -10px);
    }

    &-text {
      text-transform: uppercase;
    }

    &-arrow {
      @include width-height-same(27px !important);
      transform: translate(-2px, -0.5px);

      @media (max-width: $max-mobile-width-xs) {
        @include width-height-same(22px !important);
      }
    }
  }

  &-content {
    @include flex(flex-start, center, row);
    gap: 15px;

    @media (max-width: $max-mobile-width-sm) {
      @include flex(flex-start, center, column);
    }
  }

  &-cards {
    @include flex(space-between, flex-start, row);
    gap: 15px;
    width: 100%;

    &-compact {
      @include flex(flex-start, flex-start, row);

      @media (max-width: $max-mobile-width-sm) {
        @include flex(flex-start, flex-start, column);
      }
    }
  }

  &-view-all {
    @include link($light-purple, $purple-6);
    @include font-medium(14px);
    vertical-align: top;
  }

  &-header {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  &-title {
    @include font-semi(18px);
    @include margin-one-var();

    &-timer {
      @include font-light(12px);
      @include margin-one-var();
      @include padding-one-var();
      color: $light-purple;
    }
  }
}