@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.nft-collection {
  @include grid();

  &-item {
    &-name {
      margin-bottom: 5px;
    }

    &-price {
      @include font-bold();
      @include padding-two-var(5px);
      @include margin-two-var(0, auto);
      width: 120px;
      background-color: $dark-blue-1;
      border-radius: $button-border-radius;
    }

    &-link {
      &:hover {
        .nft-collection-item {
          &-name {
            color: $purple-6;
          }

          &-price {
            @include pink-button();
            @include padding-two-var(5px);
          }
        }
      }
    }
  }
}