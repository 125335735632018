@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';

.blur-modal {
  @include margin(20px, 20px, 40px, 20px);
  color: $white;
  background: $blur-bg;
  box-shadow: $blur-modal-shadow;
  backdrop-filter: blur(7.5px);
  text-align: center;
  position: relative;
  border-radius: $border-radius-desktop;
  max-width: 400px;

  @media (min-width: $min-mobile-width-sm) {
    max-width: 532px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: $border-radius-desktop; 
    padding: 1px; 
    background:linear-gradient(45deg,$purple-1, $pink-2); 
    mask: linear-gradient($white 0 0) content-box, linear-gradient($white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude; 
  }

  &-container {
    overflow: hidden;
    padding-left: 0 !important;

    @media (max-width: $min-mobile-width-sm) {
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
    
    &.fade .modal-dialog {
      transform: translate(20vw, 0);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }

    &-exit {
      &.fade .modal-dialog {
        transform: translate(-20vw, 0);
      }

      &.show .modal-dialog {
        transform: translate(0, 0);
      }
    }
  }

  &-close {
    @include position-float-top-right(-10px, -10px);
    @include icon-image('close', false, $icons-url);
    @include width-height-same(28px);
    @include background-position-size(center, contain);
    cursor: pointer;
  }

  &-button {
    @include purple-button();
    @include margin(14px);
    width: 100%;
    cursor: pointer;

    &-disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &-bg {
    background: $desktop-toast-box-shadow-2;
  }

  &-dialog {
    @include margin-one-var();
    min-height: 100%;
    justify-content: center;

    @media (max-width: $max-mobile-width-sm) {
      align-items: flex-end;
    }

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-symbol {
    @include margin-one-var();
  }

  .modal {
    &-header {
      @include font-semi(18px);
      @include padding(20px, 60px, 0, 60px);
      border-bottom: none;
      justify-content: center;
      position: relative;

      @media (max-width: $max-mobile-width-xs) {
        @include padding(20px, 30px, 0, 30px);
        @include font-semi(16px);
      }
    }

    &-body {
      @include padding(16px, 20px, 20px, 20px);
      @include flex(center, center, column);
      @include font(14px);
    }

    &-footer {
      @include padding(0, 20px, 20px, 20px);
      border: none;
    }
  }
}