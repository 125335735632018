@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';


$ticket-url: '../../assets/ticket/';

.ticket {
  
  &-redeem {
    @include flex(center, center, column);
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 0px;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    overflow-x: hidden;
    background-color: $purple-4;

    @media (max-width: $max-mobile-width-sm){
      height: auto;
    }
  }

  &-claim {
    @include flex(center, center, column);
    height: 100%;

    &-wrapper {
      @include padding-two-var(30px);
      height: 100%;
    }

    &-back {
      position: absolute;
      top: 20px;
      left: 10px;
      cursor: pointer;

      @media (max-width: $max-mobile-width-sm){
        top: 20px;
        left: 10px;
      }
    }

    &-container {
      @media (min-width: $min-tablet-width){
        @include flex(center, center, column);
      }
    }

    &-body {
      @include padding-two-var(20px, 20px);
      @include flex(center, center, column);
      width: 100%;
      max-width: 1360px;
      gap: 30px;

      @media (max-width: $max-mobile-width-sm){
        gap: 0px;
      }

      @media (max-height: 799px){
        padding-bottom: 0px;
      }
    }

    &-title {
      @include font-bold(40px);
      @include padding-two-var(0px, 20px);
      text-align: center;

      @media (max-width: $max-mobile-width-sm){
        @include font-bold(16px);
        margin-top: 50px;
      }
    }

    &-header > h1 {
      @include font-bold(30px);
    }

    &-header > h5 {
      @include font-bold(15px);
    }

    &-contents {
      @include font-semi(14px);
      white-space: pre-line;
    }

    &-contents > ol > li > ul {
     list-style-type: lower-alpha;
    }

    &-contents > ol > ul {
      list-style-type: lower-alpha;
     }

    &-image {
      @include width-height-same(300px);

      @media (max-width: $max-mobile-width-sm){
        @include width-height-same(200px);
      }

      &.gudi {
        border-radius: 12px;
      }
    }

    &-footer {
      display: grid;
      grid-template-columns: 500px 500px;

      @media (max-width: $max-mobile-width-sm){
        grid-template-columns: 1fr;
        width: 100%;
      }

      &-wrapper {
        @include flex(center, center, column);
        @include width-height-same(100%);
        gap: 15px;

        @media (max-width: $max-mobile-width-sm){
          @include padding-two-var(20px);
          width: 100%;
        }

        &.first{
          border-right: 1px solid $white;

          @media (max-width: $max-mobile-width-sm){
            border-right: none;
          }
        }

        &.second {
          @media (max-width: $max-mobile-width-sm){
            border-top: 1px solid $white;
          }
        }
      }
    }

    &-warning {
      @include font-medium(16px);
      color: $white;
      max-width: 400px;
      text-align: center;
    }

    &-desc {
      @include font(16px);
    }

    &-name {
      @include font-bold(16px);
      text-align: center;
    }

    &-button {
      @include purple-button();
      width: 100%;
      max-width: 350px;
    }
  }
}