@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';
$spin-url: '../../../assets/spin/';
$prize-url: '../../../assets/spin/prizes/';
$prizes-url: '../../../assets/spin/sm-prizes/';

.popup {
  @include icon-image('prize-reveal-bg', false, $spin-url);
  @include width-height(100%, 440px);
  @include background-position-size(center, cover);
  @include margin-two-var(auto, 10px);
  max-width: 755px;
  border-radius: 12px;
  color: $white;
  text-align: center;
  position: relative;

  @media (max-width: $max-mobile-width-sm) {
    @include width-height(100%, 340px);
  }

  &-text {
    @include font-bold(24px);
  }

  &-image {
    @include icon-image('metmet-sad', false, $prize-url);
    @include width-height(289px, 220px);
    @include background-position-size(center, cover);

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(190px);
    }
  }

  &-close {
    @include icon-image('close', false, $icons-url);
    @include width-height-same(35px);
    @include background-position-size(center, cover);
    @include position-float-top-right(-15px, -15px);
    @include padding-one-var(2px);
    z-index: 2;
    cursor: pointer;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(30px);
      top: -10px;
      right: -10px;
    }

    &-icon {
      pointer-events: none;
      z-index: 1;
    }
  }
  
  &-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    overflow: visible;

    &.fade .modal-dialog {
      transform: scale(0.2);
    }

    &.show .modal-dialog {
      transform: scale(1);
    }
  }

  &-dialog {
    @include flex(center, center, row);
    max-width: 100%;
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  .modal {
    top: 50%;
    transform: translateY(-50%);

    &-header {
      @include font-semi(18px);
      @include padding-one-var();
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(16px);
      }

      .btn-close {
        &-white {
          filter: none;
        }
      }
    }

    &-body {
      @include font(16px);
      @include flex(center, center, column);
      border: $purple-border;
      border-radius: 12px;
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      justify-content: center;
      border-top: none;
    }
  }
}