@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$ticket-url: '../../assets/ticket/';

.ticket {
  @include icon-image('music-fest-bg-large', false, $ticket-url);
  @include background-position-size(center, cover);
  @include flex(center, center, column);
  width: 100vw;
  overflow: hidden;

  &-container {
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 0px;
    width: 100vw;
    height: 100%;
    overflow: overlay;
  }

  &-wrapper {
    @include flex(flex-end, center, column);
  }

  &-inner {
    @include flex(space-between, center, column);
    @include width-height-same(100%);
    @include padding(20px);
    position: relative;
    max-width: 1360px;

    &-back {
      position: absolute;
      top: 20px;
      left: 10px;
      cursor: pointer;

      @media (max-width: $max-mobile-width-xs) {
        left: 10px;
        top: 20px;
      }
    }
  }

  &-image {
    @include icon-image('guitar', false, $ticket-url);
    @include width-height(100%, 100%);
    @include background-position-size(center, contain);

    &-container {
      width: 400px;
      height: 320px;

      @media (max-width: $max-mobile-width-sm) {
        width: 300px;
        height: 220px;
      }
    }
  }


  &-body {
    @include flex(center, flex-start, column);
    @include padding(0px, 20px, 20px, 20px);
    max-width: 1028px;

    @media (max-height: 800px){
      @include padding(20px, 20px, 0px, 20px);
    }

    &-container {
      padding-bottom: 230px;
    }

    &-text {
      @include font-bold(50px);
      @include padding-two-var(0px, 20px);
      margin-bottom: 20px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-bold(20px);
      }
    }

    &-desc {
      @include font(16px);

      @media (max-width: $max-mobile-width-sm) {
        @include font(13px);
      }

      @media (max-height: 800px){
        @include font(13px);
      }
    }
  }

  &-footer {
    @include icon-image('music-fest-footer', false, $ticket-url);
    @include background-position-size(center, cover);
    @include flex(center, center, column);
    @include padding-two-var(20px, 20px);
    text-align: center;
    gap: 15px;
    width: 100%;
    position: fixed;
    bottom: 0;

    &-container {
      @include flex(center, center, column);
      @include padding-two-var(30px, 40px);
      gap: 15px;
      background-color: $white;
      color: $blue-1;
      border-radius: 12px;

      @media (max-width: $max-mobile-width-sm) {
        padding: 0px;
        background-color: transparent;
        color: $white;
      }
    }

    &-text {
      @include font(14px);
 
      &:first-child {
        @include font-medium(16px);
      }

      &-desc {
        @include font-medium(12px);
      }
    }

    &-button {
      @include purple-button(14px);
      background-image: none;
      background-color: $blue-1;
      width: 100%;
      max-width: 400px;
      color: $white;

      @media (max-width: $max-mobile-width-sm) {
        background-color: $white;
        color: $blue-1;

        &:hover {
          color: $blue-1;
        }
      }
    }
  }
}