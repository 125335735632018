@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.spend-result {
  &-content {
    @include padding-two-var(25px, 20px);
    background-color: $dark-purple-1-translucent;
    text-align: center;
    margin-top: 10vh;
  }

  &-status {
    @include font-semi(36px);
  }

  &-message {
    @include font(18px);
  }

  &-continue {
    @include purple-button(16px);
    @include padding-two-var(15px);
    margin-top: 40px;
    width: 100%;
    max-width: 350px;
  }
}