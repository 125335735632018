@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.carousel {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $max-mobile-width-sm) {
    @include margin(0px, -20px, -25px, -20px);
  }

  &-wrapper {
    position: relative;
  }

  &-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin: 0;
    height: 100%;
    float: none;
    flex: 0 0 100%;
    cursor: pointer;
  }

  &-arrow {
    @include padding-one-var();
    @include width-height-same(30px);
    display: block;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: $black;
    background-color: $white;
  
    &-prev {
      @include position-float-top-left(calc(50% - 70px), -5px);
    }
  
    &-next {
      @include position-float-top-right(calc(50% - 70px), -5px);
    }
  }
}

.inner {
  transition: transform 0.3s;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.indicators {
  @include padding-two-var(0, 30px);
  display: flex;
  justify-content: center;

  @media (max-width: $max-mobile-width-sm) {
    @include padding-two-var(0, 20px);
  }
}
  
.indicator {
  @include padding-one-var();
  @include margin-two-var(10px, 5px);
  width: 50px;
  background-color: transparent;
  outline: none;
  border: none;

  @media (max-width: $max-mobile-width-sm) {
    width: 25px;
  }

  &-button {
    @include margin-one-var();
    @include width-height(100%, 5px);
    background-color: $white;

    @media (max-width: $max-mobile-width-sm) {
      margin-top: 5px;
    }

    &-active {
      background-color: $pink-1;
    }
  }
}

.home-carousel {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;

  @media (max-width: $max-mobile-width-sm) {
    margin-top: -10px;
  }

  &-indicators {
    @include padding-two-var(0, 20px);
    margin-top: -20px;
    display: flex;
    justify-content: center;
  
    @media (max-width: $max-mobile-width-sm) {
      margin: 0;
      @include padding-two-var(0, 20px);
    }
  }

  &-indicator {
    @include padding-one-var();
    @include margin(5px, 5px, 0px, 5px);
    width: 40px;
    outline: none;
    border: none;

    @media (max-width: $max-mobile-width-sm) {
      width: 25px;
    }

    &-button {
      @include width-height(100%, 5px);
      background-color: $white;
      opacity: 50%;

      &-active {
        background-color: $white;
        opacity: 100%;
      }
    }
  }
}

