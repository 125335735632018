@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.beta-application {
  @include background-position-size();
  padding-bottom: 30px;

  &-wrapper {
    @include margin-two-var(0, -30px);
    width: calc(100% + 60px);
    max-width: none;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-header {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-content {
    @include flex(flex-start, center, column);
    @include margin-two-var(0, auto);
    @include padding-two-var(0, 30px);
    @include font-light(14px);
    width: 100%;
    gap: 20px;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
    }
  }

  &-banner {
    @include margin-one-var();
    width: 100%;
    max-width: 800px;
    border-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-xs) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
      border-radius: 0;
    }
  }

  &-title {
    @include font-semi(18px);
    margin-bottom: 10px;
    text-align: center;
    line-height: normal;
  }

  &-form {
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: 400px;

    &-group {
      margin-top: 20px;
      background-color: transparent;
      display: block;
    }

    &-label {
      @extend %form-label;
      @include font(12px);
      color: $white;
      
      span {
        color: $pink-1;
        margin-left: 5px;
      }
    }

    &-error {
      @include font-semi(12px);
      color: $pink-1;
      margin-left: 5px;
      margin-top: 5px;

      &-message {
        @include font(12px);
        color: $white;
        margin-top: 20px;
      }
    }

    &-input {
      @include padding-two-var(15px, 20px);
      margin-top: 10px;
      width: 100%;
      background-color: $dark-purple-7;
      border: none;
      border-radius: 5px;

      input[type="text"] {
        @include font(14px);
        @include padding-one-var(0);
        width: 100%;
        border: none;
        color: $white;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .img-container {
        @include width-height(100%, 150px);
        background-color: $black;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-error {
        outline: $pink-border;
      }
    }

    &-text {
      @include padding-two-var(10px, 20px);
      border: none;
      border-radius: $button-border-radius;
      background-color: $dark-blue-5;
    }

    &-textarea {
      margin-top: 10px;

      textarea {
        @include padding-two-var(15px, 20px);
        @include font(14px);
        width: 100%;
        color: $white;
        background-color: $dark-purple-7;
        border: none;
        border-radius: 5px;

        &:focus {
          outline: none;
        }
      }

      &-error {
        textarea {
          outline: $pink-border;
        }
      }
    }

    &-checkbox {
      @include flex(flex-start, center, row);
      @include font(14px);
      gap: 15px;
      margin-bottom: 12px;
      min-height: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }

      // Radio Button
      .radio {
        @include width-height-same(20px);
        @include margin-two-var(0, 2.5px);
        position: relative;
        background-color: transparent;
        border-radius: 50%;
        border: $pink-border-thick;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.radio {
        background-color: $dark-purple-1;
      }

      & input:checked~.radio {
        background-color: $dark-purple-1-translucent;

        &:after {
          display: block;
        }
      }

      & .radio::after {
        @include width-height-same(10px);
        @include position-float-top-left(3px, 3px);
        border-radius: 50%;
        background: $pink-1;
      }

      .checkbox {
        @include width-height-same(25px);
        position: relative;
        background-color: $dark-purple-1-translucent;
        border-radius: 5px;
        border: $light-purple-border-thick;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.checkbox {
        background-color: $grey-2;
      }

      & input:checked~.checkbox {
        border: $pink-border-thick;
        background-color: $pink-1;

        &:after {
          display: block;
        }
      }

      & .checkbox::after {
        @include width-height(8px, 14px);
        left: 6px;
        top: 2px;
        border: $white-border-checkbox;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &-caption {
        width: auto;
        max-width: calc(100% - 40px);
      }

      &-text {
        @include padding-two-var(15px !important, 20px !important);
        background-color: $light-purple-translucent !important;
        border-radius: $button-border-radius;
      }
    }

    &-prefix {
      @extend %mobile-number-prefix;
    }

    &-submit {
      @include purple-button(12px);
      margin-top: 20px;
      width: 100%;
      max-width: 400px;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-code {
    @include flex(center, center, column);
    @include font(14px);
    color: $white;
    text-align: center;
    margin-top: 20px;

    a {
      @include font-semi(14px);
      @include link($pink-1, $pink-1);
    }
  }
}