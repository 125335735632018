@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.reset-pin {
  height: calc(100vh - 126px);
  height: calc((var(--vh, 1vh) * 100) - 126px);

  &-inner {
    height: 100%;
  }

  &-section {
    @include padding-one-var();
    border-top: none;
    margin-bottom: 20px;
    
    &-header {
      z-index: 2;
    }
  }

  &-form {
    @include margin(20px, auto, 0, auto);
    width: 100%;
    max-width: 400px;
  }

  &-row {
    width: 100%;
    margin-bottom: 20px;
  }

  &-label {
    @extend %form-label;
  }

  &-group {
    @include margin(10px);
    @extend %form-input;

    &-error {
      outline: $pink-border;
    }
  }

  &-input {
    @include font(16px);
    @include padding-one-var();
    width: calc(100% - 34px);
    color: $white;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &-hint {
    @include font(12px);
    @include margin(10px);
  }

  &-error {
    color: $pink-1;
  }

  &-password-reveal {
    @include padding-one-var();
    margin-left: 10px;
    color: $white;
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
  }

  &-submit {
    @include position-float-bottom-left(0, 0);
    width: 100%;
    background-color: $dark-purple-1;
    z-index: 2;

    &-btn {
      @include purple-button(16px);
      @include margin-two-var(0, auto);
      @include padding-two-var(15px);
      width: 100%;
      max-width: 400px;
      display: block;
    }
  }
}