@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.beta-checkbox {
  @include font-light(12px);
  color: $white;
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  max-width: 483px;
  margin-top: 20px;

  input {
    @include width-height-same(0);
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &-label {
    display: inline-block;
    margin-left: 30px;

    a {
      @include font-bold(12px);
      @include padding-one-var();
      @include link($pink-1, $pink-1);
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .checkbox {
    @include width-height-same(20px);
    @include position-float-top-left(0, 0);
    background-color: $dark-purple-2;
    border: $white-border-checkbox;
    border-radius: 5px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkbox {
    background-color: $grey-2;
  }

  & input:checked ~ .checkbox {
    border: $pink-border-checkbox;
    background-color: $pink-1;

    &:after {
      display: block;
    }
  }

  & .checkbox::after {
    @include width-height(4px, 9px);
    left: 5px;
    top: 2px;
    border: $white-border-thick;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}