@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$tutorial-url: '../../../assets/tutorial/';

.tutorial-box {
  @include position-float-bottom-left(-222px, 0px);
  width: 362px;
  z-index: 5;

  @media (max-width: $max-mobile-width-sm) {
    @include position-float-top-left(114px, 0px);
    bottom: auto;
    width: 100%;
    padding-left: 20px;
  }

  &-small {
    bottom: -202px;

    @media (max-width: $max-mobile-width-sm) {
      max-width: 310px;
      padding-left: 0;
    }
  }

  &-right {
    @include position-float-bottom-left(-202px, auto !important);
    right: 0;

    @media (max-width: $max-mobile-width-sm) {
      bottom: -207px;
    }
  }

  &-footer {
    @include position-float-bottom-left(60px, 0px);
    width: 362px;
    z-index: 100;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(0px, 20px, 0px, 30px);
      @include position-bottom-left(fixed, 68px, 50% !important);
      width: 100%;
      transform: translateX(-50%);
    }
  }

  &-content {
    @include width-height-same(100%);

    &-details {
      @include flex(space-between, flex-start, column);
      @include padding-one-var(20px);
      height: 100%;
      margin-left: 70px;

      &-gap {
        margin-left: 84px;
      }

      &-right {
        margin-left: 0;
        margin-right: 46px;
      }

      &-step {
        @include font-light();
        color: $purple-1
      }

      &-title {
        @include font-semi(18px);
        text-align: left;
        color: $purple-1
      }

      &-desc {
        @include font-light(14px);
        text-align: left;
        color: $grey-3;
      }

      &-button {
        @include flex(flex-start, center, row);
        width: 100%;
        cursor: pointer;
        margin-top: 12px;
        color: $grey-3;

        &-translate {
          @include flex(space-between, center, row);
          transform: translateX(-8px);
        }

        &-text {
          @include font-semi(14px);

          &-translate {
            transform: translateX(16px);
          }
        }

        &-arrow {
          margin-bottom: 1px;
        }
      }
    }
  }

  &-step {
    &-image {
      @include position-float-top-left(46%, -46px);
      width: 100%;
      max-width: 152px;
      transform: translateY(-50%);

      &-right {
        @include position-float-top-right(60%, -66px);
        left: auto;
      }
    }
  }
  
  &-close {
    @include position-float-top-right(10px, 10px);
    cursor: pointer;
    color: $grey-3;
  }

  &-container {
    @include width-height-same(100%);
    background-color: $white;
    box-shadow: $tutorial-shadow;
    z-index: 100;
    border-radius: 4px;
    position: relative;
  }
}