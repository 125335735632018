@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$profile-url: '../../assets/profile/';

.edit-profile {
  padding-bottom: 120px;

  &-section {
    @include padding-one-var();
    border-top: none;
    margin-bottom: 20px;
    transition: 0.3s ease-in-out;

    &-hide {
      opacity: 0;
    }

    &-show {
      opacity: 1;
    }

    &-header {
      z-index: 2;
    }
  }

  &-back {
    @include width-height(100%, 50px);
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $dark-purple-1;
    z-index: 4;
    @include keyframes(backMotion){
      from {
        transform: translateY(-45px);
      }
      to {
        transform: translateY(-35px);  
      }
    };
    animation: backMotion 0.3s forwards;

    &-icon {
      color: $white;
      transform: translateY(15px);
    }
  }

  &-header {
    text-align: center;
  }

  &-cover {
    @include width-height(100vw, 110px);
    @include background-image('cover-photo', $profile-url, '.png', false);
    @include background-position-size();
    max-width: 1366px;
    margin-left: -30px;
    position: absolute;
    top: -30px;

    @media (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
      margin-left: -30px;
    }

    @media (max-width: $max-mobile-width-sm) {
      margin-left: -20px;
      top: -20px;
    }
  }

  &-pic {
    @include margin-two-var(0, auto);
    @include icon-image('profile-pic', false, $profile-url);
    @include width-height-same(80px);
    @include background-position-size();
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &-btn {
      @include padding-one-var(0);
      @include font-medium(14px);
      @include link($light-purple, $light-purple);
      border: none;
      background-color: transparent;
      margin-top: 10px;
    }
  }

  &-fields {
    @include margin(0, auto, 20px, auto);
    width: 100%;
    max-width: 400px;

    &:last-child {
      margin-bottom: 5px;
    }

    &-caption {
      @include font-semi(16px);
    }
  }

  &-form {
    @include padding(20px);

    &:first-child {
      @include padding(0);
    }

    &-label {
      @extend %form-label;
    }

    &-caption {
      color: $purple-1;
    }

    &-input {
      @include margin(10px);
      @extend %form-input;

      &:focus {
        outline: none;
      }

      &-error {
        outline: $pink-border;
      }
      
    }
    
    &-dropdown {
      @include margin(10px);

      &-toggle {
        @extend %form-input;
        text-align: left;

        &::after {
          display: block;
          margin-left: 0;
          position: absolute;
          top: 20px;
          right: 20px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      &-menu {
        @include padding-one-var();
        width: 100%;
        color: $white;
        background-color: $dark-purple-2;
        border: none;
        border-radius: 10px;
        z-index: 1;
      }

      &-list {
        max-height: 180px;
        overflow: auto;
        border-bottom-left-radius: $border-radius-mobile;
        border-bottom-right-radius: $border-radius-mobile;
      }

      &-search {
        width: 100%;
        color: $white;
        @include padding-two-var(10px, 20px);
        background-color: transparent;
        border: none;
        outline: none;
        @include font(16px);
        border-bottom: $dark-blue-border;

        &:focus {
          outline: none;
        }
  
        &::placeholder {
          color: $white;
          opacity: 1;
        }
        
        &:-ms-input-placeholder {
          color: $white;
        }
        
        &::-ms-input-placeholder {
          color: $white;
        }
      }

      &-item {
        @include padding-two-var(10px, 20px);
        @include font(16px);
        color: $white;
        border-bottom: $dark-blue-border;

        &:focus, &:hover {
          color: $white;
          background-color: $pink-1;
        }

        &.active {
          color: $pink-1;
          background-color: transparent;

          &:focus, &:hover {
            color: $white;
            background-color: $pink-1;
          }
        }
      }
    }
  }

  &-country-code-menu {
    max-width: 400px;
  }

  &-save-changes {
    @include padding-two-var(20px, 30px);
    position: fixed;
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 60px;
    background-color: $dark-purple-1;

    @media (max-width: $max-tablet-width) {
      @include padding-one-var(20px);
      border: 2px solid transparent;
    }

    &-btn {
      @include purple-button(16px);
      @include margin-two-var(0, auto);
      @include padding-two-var(15px);
      width: 100%;
      max-width: 400px;
      display: block;
    }

    &-error {
      @include font-light(15px);
      color: $pink-1;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  &-popup-menu {
    &-btn {
      @include padding-two-var(15px, 20px);
      @include font-semi(16px);
      width: 100%;
      color: $white;
      background-color: transparent;
      border: none;
      text-align: center;
      cursor: pointer;

      &-warning {
        color: $pink-1;
      }
    }
  }
}