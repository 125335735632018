@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.crypto-payment {
  width: 100%;
  text-align: center;
  position: relative;
  text-align: start;

  @media (max-width: $max-mobile-width-sm) {
    margin-bottom: 80px;
  }

  &-inner {
    height: 100%;
  }

  &-header {
    @include font-bold(17px);
    text-align: center;
    margin-bottom: 10px;
    color: $white;

    &-back {
      @include position-float-top-left(0, 0);
      cursor: pointer;
    }
  }

  &-container {
    @include padding-two-var(20px, 25px);
    @include margin(30px, -30px, 20px, -30px);
    background-color: $dark-purple-7;
    color: $white;

    @media (max-width: $max-mobile-width-sm) {
      @include margin(30px, -20px, 20px, -20px);
    }
  }

  &-row-container {
    @include flex(space-between, center, row);
  }

  &-wrapper {
    color: $white;
  }

  &-input {
    @include font-semi(24px);
    @include padding-two-var(5px, 15px);
    @include margin-two-var(10px);
    color: $white;
    background-color: $light-purple-translucent;
    border-radius: 20px;
    border: none;
    width: 100%;
    outline: none;

    &-error {
      border: $pink-border;
    }
  }

  &-error {
    @include font();
    color: $pink-1;
  }

  &-title {
    @include font-semi(14px);

    &-amount {
      @include font-semi(24px);
    }

    &-currency {
      @include font(14px);
      margin-left: 5px;
    }
  }

  &-conversion {
    &-container {
      @include padding-one-var(15px);
      @include flex(space-between, center, row);
      @include font(14px);
      background-color: $purple-translucent-2;
      border-radius: 5px;
      margin-top: 10px;
    }

    &-title {
      @include font-semi(14px);
    }
  }

  &-button {
    @include purple-button();
    width: 100%;
    background-color: transparent;
    cursor: pointer;

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-wrapper {
      @media (max-width: $max-mobile-width-sm) {
        @include position-bottom-left(fixed, 60px, 0);
        @include padding-two-var(10px, 20px);
        background-color: $dark-purple-1;
        width: 100%;
      }
    }
  }

  &-wallet {
    @include margin-two-var(20px, -30px);
    @include padding-two-var(16px, 30px);
    color: $white;
    pointer-events: none;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(20px, -20px);
    }
  }
}