@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';
$launchpad-url: '../../../assets/launchpad/';
$spin-url: '../../../assets/spin/';
$prizes-url: '../../../assets/spin/sm-prizes/';

.wheel {
  @include icon-image('popup', false, $spin-url);
  @include width-height-same(100%);
  @include background-position-size(center, cover);
  max-width: 755px;
  border-radius: 12px;
  color: $white;
  text-align: center;
  position: relative;
  margin: auto 10px;

  &-sm {
    @include icon-image('prize-reveal-bg', false, $spin-url);
    @include width-height(100%, 500px);
    @include background-position-size(center, cover);
    max-width: 755px;
    border-radius: 12px;
    color: $white;
    text-align: center;
    position: relative;
    margin: auto 10px;

    @media (max-width: $max-mobile-width-xs){
      @include icon-image('prize-reveal-mobile', false, $spin-url);
    }

    &-small {
      @include width-height(100%, 430px);
    }
  }

  &-prize {
    &-image {
      @include width-height-same(100%);
      padding: 20px;
      max-width: 280px;

      @media (max-width: $max-mobile-width-xs){
        max-width: 253px;
      }

      &-banner {
        @include width-height-same(100%);
        padding: 0;
        max-width: 350px;

        @media (max-width: $max-mobile-width-xs){
          max-width: 300px;
        }
      }
    }

    &-wrapper {
      @include flex(center, center, column);
      z-index: 3;
      gap: 15px;

      &-gap {
        gap: 0;
      }
    }

    &-title {
      @include font(24px);
      @include padding-two-var(0, 20px);

      @media (max-width: $max-mobile-width-sm){
        @include font(16px);
      }
    }

    &-text {
      &-prize {
        @include font-medium(20px);
        position: absolute;
        bottom: 20px;
        left: 16px;
      }
    }

    &-bottom {
      @include font-bold(28px);

      @media (max-width: $max-mobile-width-sm){
        @include font-bold(24px);
      }
    }

    &-free {
      @include font-semi(14px);
      margin-top: 5px;
    }
  }

  &-close {
    @include icon-image('close', false, $icons-url);
    @include width-height-same(35px);
    @include background-position-size(center, cover);
    @include position-float-top-right(-15px, -15px);
    z-index: 2;
    cursor: pointer;
    padding: 2px;

    @media (max-width: $max-mobile-width-xs) {
      @include width-height-same(30px);
      top: -10px;
      right: -10px;
    }

    &-icon {
      pointer-events: none;
      z-index: 1;
    }
  }

  &-share {
    &-wrapper {
      @include flex(center, center, row);
      background-color: transparent;
      color: $white;
      gap: 5px;
      border: none;
      outline: none;
      width: fit-content;
    }

    &-image {
      @include icon-image('share-with-friends', false, $launchpad-url);
      @include width-height-same(35px);
      background-size: cover;
    }
  }

  &-text {
    @include font-semi(14px);
    text-transform: uppercase;
  }

  &-button {
    @include white-button();
    @include margin(15px, auto, 20px, auto);
    display: block;
    width: 100%;
    max-width: 300px;
    color: $purple-1;

    &:hover {
      color: $purple-1;
    }
  }

  &-container {
    &.fade .modal-dialog {
      transform: scale(0.2);
    }

    &.show .modal-dialog {
      transform: scale(1);
    }
  }

  &-dialog {
    @include flex(center, center, row);
    max-width: 100%;
  }

  &-bg {
    background-color: $dark-purple-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  .modal {
    &-header {
      @include font-semi(18px);
      padding: 0px;
      border-bottom: none;
      justify-content: center;

      @media (max-width: $max-mobile-width-xs) {
        @include font-semi(16px);
      }

      .btn-close {
        &-white {
          filter: none;
        }
      }
    }

    &-body {
      @include font(16px);
      @include flex(flex-end, center, column);
      border: $purple-border;
      border-radius: 12px;
      padding: 0;
    }

    &-footer {
      @include padding(0, 30px, 20px, 30px);
      justify-content: center;
      border-top: none;
    }
  }
}