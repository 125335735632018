@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$onboarding-url: '../../../assets/onboarding/';

.free-gold {
  &-popup {
    @include width-height-same(100%);
    @include flex(center, center, row);
    @include margin(180px);
    border: none;
    color: $white;
    background-color: transparent;
    border-radius: 16px;
    max-width: 370px;
    max-height: 650px;

    @media (max-width: $max-mobile-width-xs) {
      @include margin(112px);
      max-width: 364px;
    }

    @media (max-width: $max-mobile-width-xxs) {
      @include margin(120px);
      max-width: 348px;
    }

    &-image {
      width: 100%;
      max-width: 334px;
      max-height: 260px;
      position: relative;
      z-index: 100;

      @media (max-width: $max-mobile-width-xxs) {
        max-width: 260px;
      }

      &-coin {
        @include position-float-top-left(-13.2%, 50%);
        width: 100%;
        max-width: 156px;
        transform: translateX(-50%);
      }
    }

    &-background {
      @include position-float-top-left(0, 0);
      @include flex(center, center, row);
      width: 100%;
    }

    &-contents {
      @include flex(center, center, column);
      @include padding-one-var(20px);
      background: $wen-lambo-free-spin-gradient;
      box-shadow: $wen-lambo-mechanics-button-bg;
      border-radius: 20px;
      width: 100%;
      gap: 10px;
      position: relative;

      &-wrapper {
        @include position-float-top-left(0, 0);
        @include flex(center, center, column);
        background: $wen-lambo-free-spin-gradient;
        box-shadow: $wen-lambo-mechanics-button-bg;
        width: 100%;
        z-index: 2;
      }

      &-particles {
        @include position-float-top-left(0, 0);
        @include icon-image('particles', false, $onboarding-url);
        @include width-height-same(100%);
        @include background-position-size(center, cover);
        z-index: 1;
      }

      &-bubbles {
        @include position-float-top-left(0, 0);
        @include icon-image('bubbles', false, $onboarding-url);
        @include width-height-same(100%);
        @include background-position-size(center, cover);
        z-index: 1;
      }
    }

    &-title {
      @include font-semi(24px);
      text-align: center;
      position: relative;
      z-index: 100;
    }

    &-subtitle {
      @include font(14px);
      position: relative;
      z-index: 100;
    }

    &-button {
      @include orange-button();
      width: 100%;
      position: relative;
      z-index: 100;
    }

    &-body {
      @include flex(center, center, column);  
      position: relative;
      gap: 10px;
    }

    &-container {
      overflow: hidden;
  
      &.fade .modal-dialog {
        @include position-float-bottom-left(0, 0);
        transform: translateY(10vh);
      }
  
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
  
    &-bg {
      &.show {
        opacity: 0.6;
      }
    }
  
    &-dialog {
      @include margin-one-var();
      @include width-height(100%, 100vh);
      max-width: none !important;
      justify-content: center;
    }
  
  
    &-body {
      @include flex(center, center, column);
      @include margin-one-var();
      width: 100%;
      text-align: center;
      max-width: 400px;
    }
  }

}