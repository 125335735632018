@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.free-gold {
  @include margin(20px, 20px, 40px, 20px);
  color: $white;
  background: $wen-lambo-free-gold-gradient;
  border-radius: $border-radius-mobile;
  text-align: center;
  border: none;

  &-wrapper {
    @include flex(center, center, column);
    @include font-light(14px);
    gap: 5px;
    width: 100%;

    &-title {
      @include font-semi(24px);
      max-width: 500px;
    }
  }

  &-button {
    width: 100%;
    max-width: 348px;
    background: transparent;
    border: none;
    outline: none;

    &-image {
      @include width-height-same(100%);
      max-width: 186px;
    }
    
    &-wrapper {
      @include flex(center, center, row);
      margin-left: 10px;
      gap: 10px;
    }

    &-coin {
      @include width-height-same(18px);
    }
  }

  &-container {
    overflow: hidden;
    padding-left: 0 !important;
    
    &.fade .modal-dialog {
      transform: translate(0, 10vh);
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &-bg {
    background: $desktop-toast-box-shadow-2;
    
    &.show {
      opacity: 0.9;
    }
  }

  &-dialog {
    @include margin-one-var();
    @include width-height(100vw, calc(var(--vh, 1vh) * 100));
    align-items: flex-end;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  &-dialog {
    @include margin-one-var();
    width: 100vw;

    @media (min-width: $min-mobile-width-sm) {
      max-width: none;
    }
  }

  .modal {
    &-body {
      @include padding(20px, 30px, 15px, 30px);
    }
  }
}