@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

$spend-icon-url: '../assets/spend/';
$transactions-icon-url: '../assets/transactions/';

.payment {
  &-activate {
    @include flex(center, center, row);
    @include padding-two-var(0, 30px);
    @include margin(20px);
    @include width-height-same(100%);
    padding-bottom: 10px;
    background-color: $purple-7;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
    }

    &-checkbox {
      &-floater {
        @include position-float-top-right(14px, 12px);
        @include width-height(16px, 12px);
        background-color: $white;
      }
    }

    &-container {
      @include flex(space-between, center, column);
      @include width-height-same(100%);
      max-width: 400px;
    }

    &-button {
      @include purple-button();
      @include margin-two-var(20px);
      width: 100%;
      cursor: pointer;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &-image {
      max-width: 45px;

      &-disabled {
        filter: grayscale(100%);
      }
    }

    &-card {
      @include flex(flex-start, center, row);
      @include padding(14px, 18px, 14px, 8px);
      @include margin(10px, 0, 10px);
      border-radius: 8px;
      background-color: $dark-purple-7;
      gap: 10px;

      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &-title {
      @include font-semi(18px);
      @include padding(20px, 0, 10px);
    }

    &-name {
      @include font-semi(14px);
    }

    &-quantity {
      @include font-semi(14px);
      width: 30px;
      text-align: center;
    }

    &-icon {
      @include width-height-same(24px);
      color: $light-purple;
      cursor: pointer;
    }

  }
  
  &-purchase {
    @include width-height-same(100%);
    display: flex;
    flex-direction: column;

    &-wrapper {
      @include flex(center, center, column);
      @include padding-two-var(0, 20px);
      width: 100%;
      gap: 10px;
      flex-grow: 1;
    }

    &-success {
      @include width-height-same(120px);
      
      &-text {
        @include font-semi(18px);
      }

      &-id {
        @include font-medium(12px);
        color: $light-purple;
      }

      &-note {
        @include font-medium(12px);
        color: $light-purple;
        text-align: center;
        max-width: 400px;
      }
    }

    &-button {
      @include padding(40px);
      @include flex(flex-end, center, column);
      width: 100%;
      max-width: 400px;
      gap: 10px;

      &-activate {
        @include purple-button();
        width: 100%;
      }

      &-back {
        @include purple-button-border();
        @include padding-two-var(15px);
        color: $white;
        width: 100%;
      }
    }

    &-box {
      @include padding(30px, 30px, 20px, 30px);
      @include margin(10px);
      background-color: $dark-purple-7;
      border-radius: 8px;
      width: 100%;
      max-width: 400px;

      &-bottom {
        @include flex(flex-start, flex-start, column);
        @include padding(20px);
        border-top: 1px solid $dark-blue-1;
        gap: 15px;
      }

      &-card {
        max-width: 45px;
      }

      &-text {
        @include font-semi(14px);
        transform: translateX(2px);
      }

      &-icon {
        @include width-height-same(15px);
      }

      &-price {
        @include font(14px);
      }

      &-sub {
        @include flex(space-between, center, row);
        @include font(14px);
        width: 100%;
      }

      &-total {
        @include flex(space-between, center, row);
        @include font-semi(16px);
        width: 100%;
      }
    }
  }

  &-status {
    @include flex(center, center, column);
    @include width-height-same(100%);
    position: relative;

    &-container {
      @include width-height-same(100%);
      display: flex;
      flex-direction: column;
      max-width: 400px;
    }

    &-details {
      @include flex(center, center, column);
      flex-grow: 1;
      gap: 10px;
    }

    &-content {
      @include padding(30px);
      height: calc(var(--vh, 1vh) * 100 - 126px);

      @media (max-width: $max-mobile-width-sm) {
        &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      }

      &-topup {
        @include padding(0, 20px, 20px, 20px);
      }

      &-inner {
        @include width-height-same(100%);
        max-width: 100%;
      }

      &-section {
        @include padding();
        @include flex(center, center, column);
        @include width-height-same(100%);
        border: none;

        &-header {
          width: 100%;
          max-width: $max-layout-width;

          & > .section-back {
            @include position-float-top-left(20px, 30px);

            @media (max-width: $max-mobile-width-sm) {
              @include position-float-top-left(10px, 20px);
            }
          }
        }
      }
    }

    &-mgc {
      @include icon-image('mgc', false, $transactions-icon-url);
      @include width-height-same(15px);
      @include background-position-size(center, contain);
    }

    &-wrapper {
      @include flex(center, center, column);
      width: 100%;
    }

    &-back {
      @include font(14px);
      color: $pink-1;
      cursor: pointer;
    }

    &-successful {
      @include font-semi(14px);
      text-align: center;
      max-width: 300px;
      gap: 5px;
    }

    &-pending-wrapper {
      @include font-semi(16px);
      @include flex(center, center, row);
    }

    &-text {
      @include font-bold(18px);
    }

    &-description {
      @include font(14px);
      @include padding-two-var(0, 20px);
      width: 100%;
      text-align: center;
      color: $grey-4;
      max-width: 400px;

      @media (max-width: $max-mobile-width-sm) {
        @include font(12px);
      }

      &-completed {
        color: $white;
      }
    }

    &-button {
      @include purple-button();
      width: 100%;
      max-width: 400px;
    }

    &-icons {
      @include width-height-same(160px);
      border: none;
    }
  }
}