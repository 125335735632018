@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.back-header {
  @include padding-one-var();
  @include position-top-left(fixed, 18px, 24px);
  z-index: 10;
  border: none;
  color: $white;
  background-color: transparent;
  cursor: pointer;
  width: 24px;

  @media (max-width: $max-mobile-width-sm) {
    left: 18px;
  }

  &-icon {
    @include link($white, $white);
  }
}