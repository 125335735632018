@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$logo-url: '../../assets/logo/';

@mixin pin-page() {
  @include flex-page(space-between);
  @include width-height(100vw, 100vh);
  @include padding-one-var(20px);
  height: calc(var(--vh, 1vh) * 100);

  &-content {
    width: 100%;

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-header {
    &-back {
      position: absolute;
      top: 20px;
      left: 25px;
      color: $white;
    }

    &-title {
      @include font-semi(18px);
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

.pin {
  &-container {
    @include width-height(100%, 100vh);
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  &-pages {
    display: flex;
    width: 300%;
    box-sizing: border-box;
  }

  &-keyboard {
    @include flex-page(center);
    @include width-height(100vw, 100vh);
    @include padding-one-var(20px);
    height: calc(var(--vh, 1vh) * 100);

    &-site-logo {
      @include icon-image('logo', true, $logo-url);
      @include width-height(175px, 34px);
      @include margin-two-var(0, auto);
      @include background-position-size(center, 175px);
      display: block;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 100%;
    }

    &-menu {
      @include margin(0, auto, 15px, auto);
      width: 315px;
      display: table;

      &-username {
        @include font-semi(14px);
        display: table-cell;
        text-align: left;
      }
  
      &-switch-user {
        display: table-cell;
        text-align: right;
  
        &-link {
          @include link($light-purple, $light-purple);
          @include font-semi(14px);
          border: none;
          outline: none;
          background-color: transparent;
        }
      }
    }

    &-forgot-btn {
      @include margin-two-var(15px);
      @include padding-one-var();
      @include font-semi(14px);
      color: $light-purple;
      background-color: transparent;
      border: none;
    }
  }

  &-recovery {
    color: $white;
    background-color: $dark-purple-2;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
  
    &-container {
      &.fade .modal-dialog {
        transform: translate(0, 10vh);
      }
  
      &.show .modal-dialog {
        transform: translate(0, 0);
      }
    }
  
    &-bg {
      background-color: $dark-purple-2;
      
      &.show {
        opacity: 0.9;
      }
    }
  
    &-dialog {
      @include margin-one-var();
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      align-items: flex-end;
  
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
  
    &-dialog {
      @include margin-one-var();
      width: 100vw;
  
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
  
    &-symbol {
      @include margin-one-var();
    }
  
    &-icon {
      vertical-align: middle !important;
      margin-top: 8px;
  
      &.check {
        margin-top: 9px;
      }
    }

    &-cancel-button {
      @include margin-two-var(0, auto);
      @include padding-one-var(10px);
      @include font-bold();
      @include link($white, $white);
      display: block;
      background-color: transparent;
      text-transform: uppercase;
      text-align: center;
      border: none;
      border-radius: $button-border-radius;
    }
  
    &-send-code-button {
      @include purple-button();
      @include margin(0, auto, 10px, auto);
      display: block;
      width: 100%;
      max-width: 300px;
    }
  
    .modal {
      &-header {
        @include font-semi(18px);
        @include padding(20px, 30px, 0, 30px);
        border-bottom: none;
        justify-content: center;
  
        @media (max-width: $max-mobile-width-xs) {
          @include font-semi(16px);
        }
      }
  
      &-body {
        @include padding-two-var(20px, 30px);
        @include font(16px);
      }
  
      &-footer {
        @include padding(0, 30px, 20px, 30px);
        flex-direction: column;
        justify-content: center;
        border-top: none;
      }
    }
  }

  &-authentication {
    @include pin-page();

    &-message {
      @include margin-two-var(30px);
      @include padding-one-var(20px);
      text-align: center;
      background-color: $profile-info-bg;
      border-radius: 5px;

      &-title {
        @include font-semi(18px);
      }

      &-description {
        @include font(16px);
        margin-bottom: 0;
      }
    }

    &-otp {
      @include margin-two-var(0, auto);
      width: 100%;
      max-width: 400px;
    }
  }

  &-account-recovery {
    @include pin-page();
  }
}