@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.snackbar {
  margin-top: 60px;

  @media (max-width: $max-mobile-width-sm) {
    margin-top: 70px;
  }

  &-top {
    margin-top: 0;
  }

  &-gamepass {
    @media (max-width: $max-mobile-width-sm) {
      margin-top: 0;
    }
  } 

  &-wrapper {
    @include flex(space-between, center, row);
    @include padding-one-var(20px);
    @include margin-two-var(0px, 10px);
    background-color: $purple-7;
    border-radius: 8px;
    width: 400px;

    @media (max-width: $max-mobile-width-sm) {
      width: 100%;
    }
  }

  &-text {
    @include font(14px);
  }

  &-button {
    @include font-bold();
    color: $pink-1;
    text-transform: uppercase;
    cursor: pointer;
  }

  &-check {
    @include width-height-same(30px);
  }
}