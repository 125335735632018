@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';

.dropdown-with-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .dropdown-container {
    width: 100%;
    padding: 0;
  }

  button {
    @include padding-one-var(10px);
    border: none;
    border-radius: 25px;
    width: 100%;
    position: relative;
    background-color: $dark-purple-1;
    color: $white;
    text-align: left;

    &.expanded {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      // &::after {
      //   border-bottom: 2px solid $white;
      //   border-right: 2px solid $white;
      //   transform: rotate(-135deg);
      // }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-button-icon {
    float: right;
  }

  ul {
    &.options {
      @include padding-two-var(4px);
      background-color: $dark-purple-1;
      display: none;
      list-style: none;
      margin-top: -4px;

      li {
        @include padding-two-var(6px, 10px);

        &:active,
        &:focus,
        &:hover,
        &[aria-selected="true"] {
          background: $pink-1;
          cursor: pointer;
        }
      }
    }

    &.show {
      display: block;
    }
  }

  .pm-logo {
    @include width-height-same(25px);
    object-fit: cover;
    margin-right: 20px;
  }
}