@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.tabs {
  @include margin(0, 0, 20px);
  text-align: center;

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.tab {
  @include padding(0, 20px, 10px, 20px);
  @include font-semi(14px);
  @include link($white, $pink-1);
  background-color: transparent;
  border: none;

  &-active {
    @include padding(0, 20px, 7px, 20px);
    border-bottom: 3px solid $pink-1;
  }
}