@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.chat-messages {
  @include margin-two-var(0, -30px);

  @media (max-width: $max-mobile-width-sm) {
    @include margin-two-var(0, -20px);
  }

  &-section {
    @include padding-one-var();
    border-top: none;
  }

  &-empty {
    @include padding-two-var(20px, 30px);
    @include font(14px);
    display: block;
    border-top: $dark-blue-border;
    text-align: center;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(20px, 20px);
    }
  }
}

.chat-message {
  @include padding-two-var(15px, 30px);
  display: block;
  border-top: $dark-blue-border;

  &:last-child {
    border-bottom: $dark-blue-border;
  }

  @media (max-width: $max-mobile-width-sm) {
    @include padding-two-var(15px, 20px);
  }

  &-link {
    @include link($white, $white);
    width: 100%;
    display: inline-block;

    &-read {
      width: calc(100% - 22px);
    }
  }

  &-read {
    @include width-height(12px, 40px);
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 10px;

    &-button {
      @include width-height-same(12px);
      border-radius: 50%;
      background-color: $pink-1;
      display: inline-block;
      position: absolute;
      top: 2px;
      right: 0;
      cursor: pointer;
    }
  }

  &-profile-pic {
    @include width-height-same(55px);
    @include background-position-size(center, 55px);
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  &-details {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 70px);
  }

  &-sender {
    position: relative;

    &-name {
      @include font-medium(14px);
      @extend %text-ellipsis;
      color: $white;
      display: inline-block;
      width: auto;
      max-width: calc(100% - 95px);
      line-height: 18px;
    }
  }

  &-bullet {
    @extend %chat-grey-text;
    @include margin-two-var(0, 5px);
  }

  &-date {
    @extend %chat-grey-text;
    @extend %text-ellipsis;
    display: inline-block;
    width: auto;
    line-height: 18px;
  }

  &-latest-message {
    @include font(14px);
    @extend %text-ellipsis;
    width: auto;
    max-width: 100%;

    &-read {
      @include font-semi(14px);
    }
  }
}