@import '../../baseStyles/colors.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$nft-details-url: '../../assets/launchpad/';

.collectibles {
  border: none;
  overflow-x: hidden;

  &-view {
    &-back {
      cursor: pointer;
      position: absolute;
      top: 3px;
      left: -5px;
    }

    &-title {
      @include font-medium(20px);
      width: 100%;
      text-align: center;
    }

    &-header {
      @include padding(0px);
      width: 100%;
      display: flex;
      position: relative;
    }
  }

  &-header {
    width: 100;
    text-align: center;
    padding-bottom: 20px;

    &-title {
      @include font-medium(18px);
    }

    &-back {
      @include position-float-top-left(3px, -5px);
      cursor: pointer;

      @media (min-width: $max-desktop-width-lg) {
        @include position-float-top-left(0, 0);
      }
    }
  }

  &-container {
    border-bottom: 2px solid $dark-blue-7;
  }

  &-wrapper {
    @include padding-two-var(20px, 10px);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    width: 100%;

    @media (max-width: $max-desktop-width-lg) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: $max-desktop-width-sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: $max-mobile-width-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $max-mobile-width-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $max-mobile-width-xxs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-title {
    @include font-bold(16px);
  }

  &-image {
    object-fit: cover;
    width: 100%;
    border-radius: 6px;

    &-wrapper {
      @include padding-two-var(10px, 10px);
      border: $purple-border-thick;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        box-shadow: $glow;
      }
    }
  }

  &-name {
    @include font-bold(14px);
    @include padding-two-var(10px);
    text-align: center;
  }
}