@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.referral-floating {
  &-container {
    width: fit-content;
    cursor: pointer;
    z-index: 1;
  }

  &-image {
    @include width-height-same(100px);

    @media (max-width: $max-mobile-width-xs) {
      @include width-height-same(64px);
    }
  }

  &-close {
    @include width-height-same(29px);
    @include position-float-top-right(0, 0);

    @media (max-width: $max-mobile-width-xs) {
      @include width-height-same(20px);
      @include position-float-top-right(0, -4px);
    }
  }
} 