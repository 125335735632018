@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$credits-url: '../../../assets/credits/';
$gamepass-url: '../../../assets/gamepass/';
$icons-url: '../../../assets/icons/';

.game-pass-inventory-coins {
  @include flex(center, center, row);
  padding-top: 20px;
  width: 100%;
  
  &-wallet {
    width: 50%;
    padding-top: 0;
  
    @media (max-width: $max-tablet-width) {
      width: 100%;
    }
  }

  &-inner {
    @include padding-two-var(10px, 20px);
    @include flex(space-between, center, row);
    @include width-height-same(100%);
    @include background-image('gold-coins-desktop', $credits-url, '.jpg', false);
    @include background-position-size(right center, cover);
    border-radius: $border-radius-mobile;
    height: 78px;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(10px, 20px);
      height: 70px;
    }
  }

  &-stacked {
    @include flex(flex-start, flex-start, column);
  }

  &-cell {
    @include flex(center, center, row);
  }

  &-title {
    @include font(14px);
    text-align: left;
  }

  &-gold {
    @include font-semi(24px);
    text-align: right;
    position: relative;

    &-icon {
      @include width-height-same(22px);
      @include icon-image('coin', true, $gamepass-url);
      @include background-position-size();
      margin-right: 5px;
      position: relative;
    }
  }

  &-stars {
    @include width-height(410px, 93px);
    max-width: 420px;
    position: absolute;      
    margin-left: -20px;
    border-radius: 8px;

    @media (max-width: $max-mobile-width-xs) {
      @include width-height(85vw, 60px);
      max-width: 100%;
    }
  }

  &-info {
    @include width-height-same(14px);
    @include margin-one-var(5px);
    @include icon-image('info', true, $icons-url);
    @include background-position-size();
    position: relative;
  }

  &-button {
    @include white-button();
    @include link($purple-1, $purple-1);
    @include padding-two-var(10px);
    width: 100%;

    &-wrapper {
      width: 100%;
      max-width: 130px;
    }
  }
}