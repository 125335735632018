@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.tutorials {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
  height: calc(100vh - 262px);
  height: calc((var(--vh, 1vh) * 100) - 262px);

  @media (max-width: $max-mobile-width-sm) {
    height: calc(100vh - 252px);
    height: calc((var(--vh, 1vh) * 100) - 252px);
  }

  &-page {
    background: none;
  }

  &-indicators {
    text-align: center;

    &-button {
      @include margin-one-var(5px);
      @include width-height-same(12px);
      background-color: $white;
      outline: none;
      border: none;
      border-radius: $button-border-radius;

      &.active {
        background-color: $pink-1;
      }
    }
  }

  &-buttons {
    @include margin(50px, 20px, 20px, 20px);
    height: 21px;
    position: relative;
  }
}

.tutorial {
  text-align: center;
  flex: 0 0 100%;
  transition: 0.3s ease-in-out;

  &-animation {
    height: 250px;

    @media (min-height: $min-tablet-width) and (max-height: $max-tablet-width) {
      height: 400px;
    }

    @media (min-height: $min-desktop-width-sm) and (max-height: $max-desktop-width-lg) {
      height: 600px;
    }

    @media (min-height: $min-desktop-width-xl) {
      height: 800px;
    }
  }

  &-text {
    @include padding-two-var(0, 10px);
    margin-top: 20px;

    h1 {
      @include font-semi(18px);
      margin-bottom: 15px;
    }

    p {
      @include font(14px);
      color: $white;
      margin-bottom: 0;
    }
  }

  &-skip,
  &-go {
    @include link($white, $white);
    @include font-bold(14px);
    text-transform: uppercase;
    position: absolute;
  }

  &-next,
  &-prev {
    @include padding-one-var();
    @include font-bold(14px);
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    color: $white;
    position: absolute;
  }

  &-next {
    right: -20px;
  }

  &-prev {
    left: -20px;
  }

  &-skip {
    left: -10px;
  }

  &-go {
    right: 0px;
  }

  &-arrow {
    margin-top: -2px;
  }
}
