@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';

.not-found {
  text-align: center;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h1 {
      @include font-semi(36px);
      margin-bottom: 20px;
    }
  
    p {
      @include font(16px);
      @include margin-one-var();
    }
  }
}