@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.compact-active-game-pass {
  @include flex(flex-start, center, row);
  @include padding-one-var(15px);
  width: 100%;
  border-radius: $border-radius-mobile;
  background-color: $white-translucent;

  @media (max-width: $max-mobile-width-sm) {
    @include padding-one-var();
    border-radius: 0;
    background-color: transparent;
  }

  &-inner {
    @include flex(center, center, row);
    width: 100%;
    gap: 15px;
  }

  &-tickets {
    @include flex(center, center, row);
    height: 12.5vw;
    max-height: 150px;
    position: relative;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(50px, auto);
    }
  }

  &-ticket {
    @include width-height(auto, 100%);

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(100%, auto);
    }
  }

  &-timer {
    @include flex(center, flex-start, column);
    text-align: left;
    width: 100%;
    max-width: 300px;

    @media (max-width: $max-mobile-width-sm) {
      @include flex(center, flex-start, column);
      width: calc(100% - 65px);
      max-width: none;
    }

    &-title {
      @include font-light(14px);
      color: $light-purple;

      @media (max-width: $max-mobile-width-sm) {
        @include font-light(12px);
      }
    }

    &-duration {
      @include font-semi(28px);

      @media (max-width: $max-tablet-width) {
        @include font-semi(20px);
      }
    }

    &-expiry-date {
      @include font-light(14px);
      @include margin-one-var();
      @include padding-one-var();

      @media (max-width: $max-tablet-width) {
        @include font-light(12px);
        max-width: 100%;
      }
    
      b {
        @include font-semi(12px);
      }
    }
  }
}