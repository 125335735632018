@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';

.wenlambo-walkthrough {
  transform: translateY(-20px);
  position: fixed;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  z-index: 10;

  &-dynamic {
    width: 100% !important;
    left: 0 !important;
  }

  &-solo {
    margin-left: -20px;
  }

  &-block {
    @include position-top-left(fixed, 0 , 0);
    @include width-height(100vw, 100vh);
  }

  &-box {
    width: 100%;
    max-width: 400px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    top: -210px;

    &-container {
      @include padding(40px, 20px, 20px, 20px);
      @include margin-two-var(0, 15px);
      background-color: $white;
      border-radius: 4px;
      box-shadow: $tutorial-shadow;
      position: relative;

      &-arrow {
        &::after {
          content: '';
          @include position-float-bottom-left(-14px, 50%);
          @include width-height-same(0);
          @include margin-two-var(0, auto);
          transform: translateX(-50%);
          border-top: 15px solid $white;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
        }
      }
    }

    &-previous {
      transform: translateX(-7px);

      &-hide {
        transform: translateX(0);
      }
    }

    &-next {
      transform: translateX(7px);
    }

    &-buttons {
      @include font-semi(14px);
      @include flex(center, center, row);
      color: $purple-7;
      cursor: pointer;
    }

    &-solo {
      top: -190px;
      left: 0;
      transform: translateX(0);

      @media (max-width: $max-mobile-width-sm) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-hide {
      display: none;
    }

    &-image {
      @include position-float-top-left(-60px, 50%);
      transform: translateX(-50%);
      width: 100%;
      max-width: 155px;
      z-index: 10;
    }

    &-text {
      @include font-semi(16px);
      color: $grey-3;
      margin-bottom: 3px;
    }

    &-description {
      @include font-light(14px);
      color: $grey-3;
    }
  }
}