@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.thumbnail-name__count {
  color: $pink-1;
}

.guilds-list {
  @include margin-two-var(0, -10px);
  overflow-x: auto;
  white-space: nowrap;

  &-scroll {
    .slick-prev, .slick-next {
      &:before {
        margin-top: 0;
      }
    }
  }

  &-item {
    @include responsive-width(150px, 125px);
    @extend %gallery;
    position: relative;

    &-link {
      @include link($white, $white);

      &:hover {
        .guild-thumbnail {
          &-name {
            color: $purple-6;
          }
        }
      }
    }
  }
}

.guild-thumbnail {
  @include background-position-size();
  @include margin-two-var(0, 10px);
  border-radius: $border-radius-mobile;
  width: calc(100% - 20px);
  padding-top: calc(100% + 60px);
  position: relative;

  &-name {
    @include font-medium(16px);
    @include padding-one-var(10px);
    background-color: transparent;
    background-image: $transparent-gradient-vertical;
    text-align: left;
    width: 100%;
    white-space: normal;
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-bottom-left-radius: $border-radius-mobile;
    border-bottom-right-radius: $border-radius-mobile;
    line-height: 1.3;
  }
}

.news {
  margin-bottom: -30px;

  &-article {
    padding-bottom: 30px;
  }

  &-thumbnail {
    @include background-position-size();
    display: block;
    width: 100%;
    padding-top: 60%;
    border-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-xs) {
      padding-top: 50%;
    }
  }

  &-details {
    @media (max-width: $max-mobile-width-xs) {
      padding-top: 15px;
    }
  }

  &-title {
    @include font-semi(16px);
    @include link($white, $white);
    display: block;
  }

  &-description {
    @include font();
    @include margin-two-var(10px);
  }

  &-read-more {
    @include font-bold();
    @include link($pink-1, $pink-1);
    text-transform: uppercase;
  }
}