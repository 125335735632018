@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.card-payment {
  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-description {
    @include padding(10px, 0, 20px);
    @include font-semi(16px);
  }

  &-container {
    @include flex(center, center, column);
    width: 100%;
  }

  &-wrapper {
    width: 100%;
    max-width: 400px;
  }

  &-form {
    &-title {
      @include font-semi();
      color: $light-purple;

      span {
        color: $pink-1;
      }
    }

    &-input {
      @include padding-two-var(12px, 18px);
      @include font-light(14px);
      color: $white;
      border: none;
      outline: none;
      background-color: $dark-purple-7;
      border-radius: 22px;
      width: 100%;

      &:-webkit-autofill { 
        -webkit-background-clip: text;
      }

      &:-webkit-autofill{
        -webkit-text-fill-color: $white !important;
      }

      &-required {
        border: $pink-border !important;
      }

      &-wrapper {
        @include flex(center, center, row);
        @include margin(5px, 0, 15px);
        background-color: $dark-purple-7;
        border-radius: 22px;
        border: 1px solid transparent;
      }

      &-phone {
        @include padding-two-var(12px, 10px);
      }

      &-country {
        @include padding-two-var(0, 12px);
        @include font-light(14px);
        border-right: $white-border;
        padding-right: 8px;
      }
    }
  }

  &-button {
    @include purple-button();
    margin-top: 20px;
    width: 100%;
  }

  &-filter {
    &__control {
      @include padding-two-var(6px, 10px);
      @include margin(5px, 0, 15px);
      background-color: $dark-purple-7 !important;
      border-radius: 22px !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &__menu {
      background-color: $dark-purple-7 !important;
      color: $white !important;
    }

    &__input-container {
      color: $white !important;
    }

    &__menu-notice {
      color: $white !important;
    }

    &__option {
      @include font-bold(14px !important);

      &--is-focused {
        background-color: $purple-1 !important;
      }

      &--is-selected {
        background-color: $purple-1 !important;
      }
    }

    &__indicator-separator {
      display: none !important;
    }

    &__single-value {
      @include font-bold(14px !important);
      color: $white !important;
    }
  }
}