@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$referral-url: '../../../assets/referral/';

.invitation-popup {
  @include margin-one-var();
  @include padding-one-var();
  @include width-height(100%, 520px);
  @include background-image('referred-user', $referral-url, '.png', false);
  @include background-position-size(0 30px, 100%);
  max-width: 340px;
  color: $white;
  border-radius: 8px;
  border: none;
  background-color: $purple-7;

  &-container {
    &.fade .modal-dialog {
      @include padding-two-var(0px, 20px);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-dialog {
    @include flex(center, center, row);
  }

  &-bg {
    background-color: $black;

    &.show {
      opacity: 0.65;
      height: 101vh;
    }
  }

  &-header {
    @include font-medium(12px);
    @include padding-one-var();
    color: $grey-6;
    border-bottom: none;
    cursor: pointer;
  }

  &-close {
    color: $purple-1;
    font-size: 20px !important;

    &-container {
      @include flex(center, center, row);
      @include width-height-same(29px);
      @include padding-one-var(10px);
      @include margin(-10px, -10px, 0px, 0px);
      background-color: $white;
      border: none;
      border-radius: 25px;
      position: absolute;
      right: 0;
    }
  }

  .modal {
    &-body {
      @include padding-one-var();
      @include margin-one-var();
      @include flex(flex-start, center, column);
      border-radius: 8px;
    }
  }

  &-content {
    @include flex(flex-end, center, column);
    @include padding-one-var(20px);
    @include width-height-same(100%);
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    gap: 20px;
  }

  &-image {
    @include margin-one-var();
    @include padding-one-var();
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &-texts {
    @include flex(center, center, column);
    gap: 15px;
  }

  &-title {
    @include font-semi(24px);
    text-align: center;
    width: 100%;
    margin-bottom: 0;
  }

  &-description {
    @include font-light(14px);
    text-align: center;
    margin-bottom: 0;
  }

  &-circle {
    @include width-height-same(10px);
    border-radius: 10px;
    background: $dark-grey-translucent;
    margin-right: 5px;

    &-active {
      @include width-height-same(10px);
      border-radius: 10px;
      background-color: $white;
      margin-right: 5px;
    }
  }

  &-button {
    @include purple-button-reverse(12px);
    width: 100%;
  }
}