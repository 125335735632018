html {
  /* For scrollbar on Firefox browser */
  scrollbar-color: #792DE5 transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #110a24;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Safari/Chrome/Microsoft-Edge browser etc. */
::-webkit-scrollbar {
  width: 0.7vw;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(121, 45, 229, 0.5);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #792DE5;
}

@media (min-width: 576px) and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 1vw;
  }
}

@media (max-width: 575px) {
  ::-webkit-scrollbar {
    width: 1.5vw;
  }
}