@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$icons-url: '../../../assets/icons/';
$nft-details-url: '../../../assets/launchpad/';
$marketplace-url: '../../../assets/marketplace/';
$spend-url: '../../../assets/spend/';

.collectibles {
  &-section {
    padding: 0 !important;
    border: none !important;

    &-modal {
      @include padding-one-var(20px);
      display: flex;
      flex-direction: column;
      border-top: none;

      @media (max-width: $max-mobile-width-sm) {
        height: 100%;
      }

      &-buy {
        @include flex(center, center, column);
        @include padding(0px, 0px, 40px, 0px);
        border-bottom: $grey-line-border;
        gap: 10px;

        @media (max-width: $max-mobile-width-sm) {
          padding: 0;
          border: none;
          flex-grow: 1;
        }

        &-success {
          @include icon-image('payment-success', false, $spend-url);
          @include width-height-same(152px);
          @include background-position-size(center, contain);

          @media (max-width: $max-mobile-width-sm) {
            @include width-height-same(120px);
          }
        }

        &-title {
          @include font-semi(24px);

          @media (max-width: $max-mobile-width-sm) {
            @include font-semi(16px);
          }
        }

        &-subtitle {
          @include font-semi(16px);
          color: $light-purple;

          @media (max-width: $max-mobile-width-sm) {
            @include font-semi(12px);
          }
        }
      }

      &-mobile {
        @include flex(center, center, row);
        @include padding-one-var(20px !important);
      }

      &-success {
        @include flex(center, center, row);
        margin-bottom: 10px;

        @media (max-width: $max-mobile-width-sm) {
          margin-bottom: 0;
        }

        &-wrapper {
          @include flex(center, center, row);
          gap: 10px;
        }

        &-icon {
          @include icon-image('check', false, $marketplace-url);
          @include width-height-same(20px);
          @include background-position-size(center, contain);
        }

        &-text {
          @include font-semi(20px);

          @media (max-width: $max-mobile-width-sm) {
            @include font-medium(12px);
          }
        }
      }

      &-button {
        @include purple-button(14px);
        width: 100%;

        @media (max-width: $max-mobile-width-sm) {
          @include purple-button(12px);
          @include padding-two-var(12px, 20px);
        }

        &-disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &-mgc {
          @include icon-image('mgo-coin', true, $icons-url);
          @include width-height-same(15px);
          @include background-position-size(center, contain);
        }

        &-text {
          @include flex(center, center, row);
          flex-wrap: wrap;
        }

        &-second {
          @include dark-grey-button(14px);
          width: 100%;

          @media (max-width: $max-mobile-width-sm) {
            @include dark-grey-button(12px);
          }

          &-buy {
            background: linear-gradient($dark-purple-1, $dark-purple-1) padding-box,
              $purple-button-gradient border-box;
            border-radius: 50em;
            border: 1.5px solid transparent;
          }
        }

        &-wrapper {
          @include padding-two-var(24px);
          @include flex(center, center, column);
          gap: 15px;

          &-hide {
            display: none;
          }
        }
      }

      &-container {
        margin-top: 10px;

        &-hide {
          display: none;
        }
      }

      &-details { 
        @include font(14px);

        @media (max-width: $max-mobile-width-sm) {
          @include font(12px);
        }

        &-container {
          flex-grow: 1;
        }

        &-cancel {
          @include padding(20px);
          @include font(16px);

          @media (max-width: $max-mobile-width-sm) {
            @include font(14px);
            text-align: left;
          }

          &-hide {
            @include padding(10px);
            opacity: 0;

            @media (min-width: $min-tablet-width) {
              padding: 0;
            }
          }
        }

        &-balance {
          @include flex(space-between, center, row);
          @include padding-one-var(20px);
          background-color: $dark-purple-7;
          border-radius: 8px;
          margin-top: 25px;

          &-text {
            @include font(16px);

            @media (max-width: $max-mobile-width-sm) {
              @include font(14px);
            }
          }

          &-value {
            @include font-semi(16px);

            @media (max-width: $max-mobile-width-sm) {
              @include font-semi(14px);
            }
          }
        }

        &-wrapper {
          @include padding-one-var(30px);
          border-bottom: $grey-line-border;

          @media (max-width: $max-mobile-width-sm) {
            @include padding-two-var(20px);
            border: none;
          }

          &-buy {
            @include flex(center, flex-start, column);
            @include padding-two-var(30px);
            border-bottom: $grey-line-border;
            text-align: left;
            gap: 10px;

            @media (max-width: $max-mobile-width-sm) {
              @include padding-two-var(20px);
              gap: 20px;
            }
          }

          &-column {
            @include flex(center, center, row);
          }

          &-text {
            @include flex(center, center, row);
            @include font(16px);
            margin-bottom: 10px;

            @media (max-width: $max-mobile-width-sm) {
              @include font(14px);
            }

            &-price {
              @include flex(center, center, row);
              @include font(16px);

              @media (max-width: $max-mobile-width-sm) {
                @include font-medium(10px);
                color: $light-purple;
                margin-bottom: 10px;
              }
            }
          }

          &-value {
            @include flex(center, center, row);
            @include font-semi(16px);
            margin-bottom: 10px;

            @media (max-width: $max-mobile-width-sm) {
              @include font-semi(14px);
            }


            &-box {
              @include flex(center, center, row);
              gap: 5px;

              &-mgc {
                @include icon-image('mgo-coin', true, $icons-url);
                @include width-height-same(20px);
                @include background-position-size(center, contain);

                &-text {
                  @media (max-width: $max-mobile-width-sm) {
                    @include font-semi(18px);
                    white-space: pre;
                  }
                }
              }
            }
          }

          &-input {
            @include font-medium(18px);
            @include padding(10px, 100px, 10px, 30px);
            background-color: $dark-blue-7;
            border: none;
            outline: none;
            color: $white;
            border-radius: 22px;
            max-width: 208px;

            @media (max-width: $max-mobile-width-sm) {
              width: 100%;
              max-width: none;
            }

            &-wrapper {
              position: relative;

              @media (max-width: $max-mobile-width-sm) {
                width: 100%;
              }
            }

            &-mgc {
              @include flex(center, center, row);
              gap: 5px;
              position: absolute;
              top: 50%;
              right: 30px;
              transform: translateY(-50%);

              &-icon {
                @include icon-image('mgo-coin', true, $icons-url);
                @include width-height-same(20px);
                @include background-position-size(center, contain);
              }

              &-text {
                @include font(14px);
              }
            }
          }

          &-fees {
            @include padding(30px, 30px, 20px, 30px);
            border-bottom: $grey-line-border;

            @media (max-width: $max-mobile-width-sm) {
              @include padding(20px, 20px, 10px, 20px);
              background-color: $dark-blue-8;
              border-radius: 8px;
              border: none;
            }
          }
        }
      }
      
      &-box {
        @include flex(flex-start, center, row);
        @include padding-one-var(30px);
        background-color: $dark-purple-3;
        border: 1.5px solid $purple-1;
        border-radius: 10px;
        gap: 18px;

        @media (max-width: $max-mobile-width-sm) {
          @include padding-one-var(12px);
          border-radius: 5px;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: left;
      }

      &-title {
        @include font(14px);
        color: $light-purple;

        @media (max-width: $max-mobile-width-sm) {
          @include font(12px);
        }
      }

      &-name {
        @include font-semi(24px);

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(18px);
        }
      }

      &-mgc {
        @include icon-image('mgo-coin', true, $icons-url);
        @include width-height-same(20px);
        @include background-position-size(center, contain);

        @media (max-width: $max-mobile-width-sm) {
          @include width-height-same(14px);
        }

        &-text {
          @include font(18px);

          @media (max-width: $max-mobile-width-sm) {
            @include font-semi(14px);
          }
        }
      }

      &-image {
        width: 100%;
        max-width: 200px;
        border-radius: 10px;

        @media (max-width: $max-mobile-width-sm) {
          max-width: 106px;
        }
      }
    }
  }

  &-details {
    padding-top: 50px;
    height: 100%;

    @media (max-width: $max-mobile-width-sm) {
      padding-top: 40px;
    }

    &-share {
      @include font-bold(12px);
      text-transform: uppercase;

      @media (max-width: $max-mobile-width-sm) {
        @include font(12px);
      }

      &-icon {
        @include icon-image('share', false, $nft-details-url);
        @include width-height-same(15px);
        @include background-position-size(center, contain);
      }

      &-wrapper {
        @include flex(center, center, row);
        gap: 5px;
        cursor: pointer;
      }
    }

    &-withdraw {
      &-icon {
        @include icon-image('withdraw', false, $nft-details-url);
        @include width-height-same(15px);
        @include background-position-size(center, contain);
      }
    }

    &-box {
      @include flex(space-between, center, row);
      @include padding-two-var(30px, 20px);
      @include margin(20px);
      background-color: $dark-purple-7;
      width: 100%;
      border-radius: 8px;

      @media (max-width: $max-mobile-width-sm) {
        @include flex(center, flex-start, column);
        @include padding-one-var(20px);
        gap: 20px;
      }

      &-wrapper {
        @include flex(flex-start, center, row);
        gap: 10px;

        &-hide {
          display: none;
        }
      }

      &-mgc {
        @include icon-image('mgo-coin', true, $icons-url);
        @include width-height-same(25px);
        @include background-position-size(center, contain);
      }

      &-text {
        @include font-semi(18px);
      }

      &-button {
        @include purple-button(14px);
        @include padding-two-var(12px);
        width: 100%;
        max-width: 173px;

        @media (max-width: $max-mobile-width-sm) {
          max-width: none;
        }

        &-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &-sell {
          max-width: 100%;
        }
      }
    }

    &-section {
      @include flex(flex-start, flex-start, column);

      &-header {
        @include flex(center, center, row);
        width: 100%;
        position: relative;

        @media (max-width: $max-mobile-width-sm) {
          @include flex(space-between, center, row);
        }
      }

      &-header {
        @include flex(space-between, flex-start, row);
        width: 100%;
        position: relative;

        @media (max-width: $max-mobile-width-sm) {
          @include flex(space-between, flex-start, row);
          margin-top: 20px;
        }

        &-box {
          @include flex(flex-start, flex-start, column);

          @media (max-width: $max-mobile-width-sm) {
            gap: 5px;
          }
        }
      }
      
      &-indicator {
        @include font-bold();
        @include padding-two-var(4px, 23px);
        border-radius: 33px;
        background-color: $green-2;
      }

      &-name {
        @include font-semi(30px);
        width: 100%;
        text-align: left;

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(16px);
        }
      }

      &-nft {
        @include font(14px);
        color: $light-purple;

        @media (max-width: $max-mobile-width-sm) {
          @include font(12px);
        }
      }

      &-owner {
        @include font(14px);

        @media (max-width: $max-mobile-width-sm) {
          @include font(12px);
        }

        &-text {
          @include font(14px);
          color: $purple-6;

          @media (max-width: $max-mobile-width-sm) {
            @include font(12px);
          }
        }
      }

      &-title {
        @include font-medium(17px);
      }

      &-desc {
        @include font(13px);
        padding-left: 7px;
      }

      &-view {
        @include font-semi(13px);
        color: $pink-1;
        text-transform: uppercase;
        padding-left: 7px;
        
        &-wrapper {
          cursor: pointer;
        }

        &-icon {
          height: 15px !important;
          color: $pink-1;
        }
      }

      &-about {
        @include icon-image('info', false, $nft-details-url);
        @include width-height-same(35px);
        @include background-position-size(center, cover);
      }

      &-properties {
        @include icon-image('traits', false, $nft-details-url);
        @include width-height-same(35px);
        @include background-position-size(center, cover);
      }

      &-content {
        @include flex(center, flex-start, column);
        margin-top: 15px;

        &-hide {
          display: none;
        }
      }

      &-container {
        @include flex(center, flex-start, column);
        @include padding-two-var(20px);
        border-bottom: $grey-line-border;
        width: 100%;

        &-properties {
          @include flex(center, flex-start, column);
          @include padding-two-var(20px);
          border-bottom: $grey-line-border;
          width: 100%;

          &-hide {
            border: none;
          }
        }
      }

      &-stats {
        display: flex;
        gap: 10px;
        width: 100%;
        flex-wrap: wrap;
        padding-top: 20px;

        &-hide {
          display: none;
        }

        &-wrapper {
          @include flex(center, flex-start, column);
          @include padding-two-var(10px, 10px);
          background-color: $dark-blue-7;
          border-radius: 8px;
        }

        &-type {
          @include font-medium(12px);
          color: $grey-6;
        }

        &-value {
          @include font-bold(12px);
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      max-width: 1500px;

      @media (max-width: $max-mobile-width-sm) {
        max-width: 368px;
      }

      &-wrapper {
        @include flex(center, center, row);
      }
    }
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.kebab-dropdown {
  &-button {
    background-color: transparent !important;
    border: none !important; 
    padding-right: 0 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:focus, &:active {
      outline: none !important;
      border: none !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &-item {
    @include font-bold(12px);
    text-transform: uppercase;
    width: fit-content;

    &:active, &:focus, &:hover:active {
      background-color: transparent !important;
    }
  }

  &-wrapper {
    @include flex(flex-start, flex-start, row !important);
    @include padding-two-var(5px);
    cursor: pointer;

    &:active, &:focus {
      background-color: transparent !important;
      color: $black;
    }

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(10px);

      &:active, &:focus {
        background-color: transparent !important;
        color: $white;
      }
    }
  }

  &-share {
    &-icon {
      @include icon-image('share-black', false, $nft-details-url);
      @include width-height-same(15px);
      @include background-position-size(center, contain);
      margin-right: 10px;

      @media (max-width: $max-mobile-width-sm) {
        @include icon-image('share', false, $nft-details-url);
      }
    }
  }

  &-withdraw {
    &-icon {
      @include icon-image('withdraw-black', false, $nft-details-url);
      @include width-height-same(15px);
      @include background-position-size(center, contain);
      margin-right: 10px;

      @media (max-width: $max-mobile-width-sm) {
        @include icon-image('withdraw', false, $nft-details-url);
      }
    }
  }

  &-menu {
    padding: 10px;
  }

  &-toast {
    @include padding-two-var(20px);
    color: $white;
    background-color: $dark-purple-1;
    width: 100%;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 0px 30px $desktop-toast-box-shadow;
      
    @media (max-width: $max-tablet-width) {
      width: 100%;
      max-width: 500px;
    }
        
    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var();
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      background-color: $dark-blue-7;
    }

    &-title {
      @include font-semi(24px);
      margin-bottom: 10px;
    }

    &-text {
      @include padding-one-var();
      @include font-light(16px);
      max-width: 522px;
 
      @media (max-width: $max-tablet-width) {
        max-width: 380px;
      }
      
      @media (max-width: $max-mobile-width-sm) {
        @include flex(center, center, column);
        @include font-light(14px);
        border-top: none;
      }
    }
    
    &-button {
      @include purple-button(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 400px;
      margin-right: 15px;
    
      @media (max-width: $max-tablet-width) {
        width: 100%;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include margin-two-var(10px);
      }

      &-container {
        @include flex(center, center, row);
        width: 100%;
        max-width: 568px;
        border-top: $grey-line-border;
        padding-top: 20px;

        @media (max-width: $max-tablet-width) {
          max-width: 380px;
        }

        @media (max-width: $max-mobile-width-sm) {
          @include flex(center, center, column);
          @include padding-one-var();
          border-top: none;
        }
      }
    }

    &-buy-button {
      @include purple-button-border(12px);
      @include padding-two-var(10px);
      width: 100%;
      max-width: 400px;
    
      @media (max-width: $max-tablet-width) {
        width: 100%;
      }
    }
            
    &-container {
      overflow: hidden;
    
      &.fade .modal-dialog {
        @include position-float-bottom-left(0px, 0px);
        transform: translateY(10vh);
      }
    
      &.show .modal-dialog {
        transform: translateY(0);
      }
    }
    
    &-bg {
      background-color: $black;
    
      &.show {
        opacity: 0.5;
      }
    }
    
    &-header {
      @include font-semi(18px);
      @include flex(center, center, row);
      border-bottom: none;
      cursor: pointer;
    
      &-close {
        @include position-float-top-right(15px, 15px);
        @include width-height-same(1.2em !important);
          color: $white;
      }
    }
    
    &-dialog {
      @include margin-one-var();
      width: 100vw;
    
      @media (min-width: $min-mobile-width-sm) {
        max-width: none;
      }
    }
    
    .modal {
      &-body {
        @include padding-one-var(20px);
        @include flex(flex-start, flex-start, column);
      }
    }
  }
}

