@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.wen-lambo-tutorial {
    @include flex(space-between, center, column);
    @include padding(20px, 20px, 0px, 20px);
    @include width-height-same(100%);
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 20px;

    &-image {
      @include margin-one-var();
      @include padding-one-var();
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &-text-container {
      @include flex(center, center, column);
    }

    &-title {
      @include font-semi(24px);
      text-align: center;
      width: 100%;
    }

    &-description {
      @include font-light(14px);
      text-align: center;
      margin-bottom: 20px;
    }

    &-circle {
        @include width-height-same(10px);
        border-radius: 10px;
        background: $dark-grey-translucent;
        margin-right: 5px;

        &-active {
          @include width-height-same(10px);
          border-radius: 10px;
          background-color: $white;
          margin-right: 5px;
        }
    }

    &-button {
        @include font-semi(14px);
        background-color: transparent;
        color: $white;
        border: none;
    
        &-container {
          @include flex(space-between, center, row);
          width: 100%;
        }
    }

    &-close {
      color: $purple-1;
      font-size: 20px !important;
  
      &-container {
        @include flex(center, center, row);
        @include width-height-same(29px);
        @include padding-one-var(10px);
        @include margin(-10px, -10px, 0px, 0px);
        @include position-float-top-right(0, 0);
        background-color: $white;
        border: none;
        border-radius: 25px;
      }
    }
}

.pop-up-tutorial {
  @include margin-two-var(0px, 10px);
  @include padding-one-var();
  color: $white;
  border-radius: 8px;
  border: none;
  height: 520px;
  background: $wen-lambo-tutorial-gradient;

  @media (max-width: $max-mobile-width-sm) {
    height: auto;
  }

  &-container {
    &.fade .modal-dialog {
      @include padding-two-var(0px, 20px);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-dialog {
    @include flex(center, center, row);
  }
  
  &-bg {
    background-color: $black;

    &.show {
      opacity: 0.65;
      height: 101vh;
    }
  }
  
  &-header {
    @include font-medium(12px);
    @include padding-one-var();
    color: $grey-6;
    border-bottom: none;
    cursor: pointer;
  }

  .modal {
    &-body {
      @include padding-one-var();
      @include margin-one-var();
      @include flex(flex-start, center, column);
      border-radius: 8px;
    }
  }
}

