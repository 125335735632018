@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$referral-url: '../../assets/referral/';

.referral-mechanics {
  padding-bottom: 30px;
  background: $wen-lambo-gradient;

  &-description {
    @include font-light(12px);
    @include padding-two-var(15px);
    max-width: 767px;
    width: 100%;

    &-container {
      @include flex(center, center, row);
      width: 100%;
    }
  }

  &-wrapper {
    @include margin-two-var(0, -30px);
    width: calc(100% + 60px);
    max-width: none;
  
    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
      width: calc(100% + 40px);
    }
  }

  &-section {
    border-top: none;
    padding-top: 0;
  }

  &-header {
    @include margin-two-var(0, auto);
    width: calc(100% - 60px);
    max-width: $max-layout-width;

    @media (max-width: $max-mobile-width-sm) {
      width: calc(100% - 40px);
    }
  }

  &-content {
    @include margin-two-var(0, auto);
    @include padding-two-var(0, 30px);
    @include font-light(14px);
    width: 100%;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
    }
  }

  &-title {
    @include font-semi(16px);
    margin-bottom: 20px;
  }

  &-faqs {
    @include margin(10px, auto, 0, auto);
    @include font(12px);
    width: 100%;
    max-width: $min-tablet-width;

    ol {
      counter-reset: section;
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        @include flex(flex-start, flex-start, row);
        width: 100%;
        max-width: fit-content;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          counter-increment: section;
          content: counter(section, decimal) ".";
          width: 15px;
        }

        div {
          width: calc(100% - 15px);
        }

        ol {
          counter-reset: subsection;
          list-style-type: none;

          li {
            @include margin(5px);

            &::before {
              counter-increment: subsection;
              content: counter(subsection, lower-alpha) ".";
            }
          }
        }

        ul {
          counter-reset: subsection;
          list-style-type: none;
          padding-left: 0;

          li {
            @include margin(2px);
            
            &::before {
              counter-increment: subsection;
              content: counter(subsection, disc);
            }
          }
        }
      }
    }

    ul {
      counter-reset: section;
      list-style-type: none;
      padding-left: 0;

      li {
        @include flex(flex-start, flex-start, row);
        width: 100%;
        max-width: fit-content;

        &::before {
          counter-increment: section;
          content: counter(section, disc);
          width: 15px;
        }

        div {
          width: calc(100% - 15px);
        }
      }
    }
  }
}