@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$support-icon-url: '../../assets/icons/support/';

.support {
  &-container {
    @include padding-one-var();
    border-top: none;
  }

  &-section {
    @include padding-two-var(20px, 30px);
    background-color: $dark-purple-2;
    border: $purple-border;
    border-radius: $border-radius-mobile;
    text-align: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    h1 {
      @include font-semi(20px);
      margin-bottom: 10px;
      line-height: 1.3;
    }

    p {
      @include font();
      margin-bottom: 15px;
    }
  }

  &-link {
    @include margin(0, auto, 15px, auto);
    width: 100%;
    max-width: 400px;

    &:last-child {
      margin-bottom: 0;
    }

    &-discord {
      @include discord-button();
      @include padding-two-var(10px);
      display: block;
    }

    &-telegram {
      @include telegram-button();
      @include padding-two-var(10px);
      display: block;
    }

    &-feedback-form {
      @include purple-button();
      display: block;
    }

    &-icon {
      @include width-height-same(30px);
      @include background-position-size(center, 30px);
      @include margin(-2px, 5px);
      display: inline-block;
      vertical-align: middle;

      &-discord {
        @include icon-image('discord', true, $support-icon-url);
      }

      &-telegram {
        @include icon-image('telegram', true, $support-icon-url);
      }
    }
  }
}