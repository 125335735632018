@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';

.gold-coins-header {
  @include font-bold(12px);
  background-color: $orange-4;
  border-radius: 50px;
  padding-right: 10px;
  height: 30px;
  
  &-icon {
    height: 30px;
    margin-right: 5px;
  }
}